/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSwipeable } from 'react-swipeable';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Popup from '../components/popup';
import variables from '../styles/Variables';
import { mediaMin } from '../styles/MediaQueries';
import { routes } from '../data/routes';
import InstagramIcon from '../components/utils/instagram-icon';

const HomeContainer = styled.div`
  box-sizing: border-box;
  background: url('/images/home/home_bg.jpg') no-repeat center center fixed;
  background-size: cover;
  position: fixed;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  opacity: ${(props) => (props.contentLoaded ? '1' : '0')};
  transition: opacity 0.5s ease-in-out;
  padding: 30% 36px;
  ${mediaMin.tablet`
    padding: 18% 36px;
  `}
  ${mediaMin.tabletLandscape`
    display: block;
    margin: 24px;
    padding: 40px 20px;
    height: calc(100vh - 48px);
    width: calc(100vw - 48px);
  `};
  .fair-housing-notice {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: white;
    z-index: 100;
    opacity: 0.5;
    padding-bottom: 2px;
    border-bottom: 1px solid white;
    &:hover {
      text-decoration: none;
    }
  }
`;

const HomeNavigation = styled.nav`
  display: none;
  ${mediaMin.tabletLandscape`
    z-index: 100;
    position: fixed;
    display: flex;
    flex-direction: column;
    height: 100%;
    .list_1{
      li{
        opacity: ${(props) => (props.navListOneVisible ? '1' : '0')};
        visibility: ${(props) => (props.navListOneVisible ? 'visible' : 'hidden')};
          &:nth-child(1){
            transition-delay: 0;
          }
          &:nth-child(2){
            transition-delay: .2s;
          }
          &:nth-child(3){
            transition-delay: .4s;
          }
          &:nth-child(4){
            transition-delay: .6s;
          }
          &:nth-child(5){
            transition-delay: .8s;
          }
      }
      }
    .list_2{
      li{
        opacity: ${(props) => (props.navListTwoVisible ? '1' : '0')};
        visibility: ${(props) => (props.navListTwoVisible ? 'visible' : 'hidden')};
          &:nth-child(1){
            transition-delay: 0;
          }
          &:nth-child(2){
            transition-delay: .2s;
          }
      }
      }
    .list_3{
      li{
        opacity: ${(props) => (props.navListThreeVisible ? '1' : '0')};
        visibility: ${(props) => (props.navListThreeVisible ? 'visible' : 'hidden')};
          &:nth-child(1){
            transition-delay: 0;
          }
          &:nth-child(2){
            transition-delay: .2s;
          }
          &:nth-child(3){
            transition-delay: .4s;
          }
          &:nth-child(4){
            transition-delay: .6s;
          }
        }
      }
    .list_4{
      li{
        opacity: ${(props) => (props.navListFourVisible ? '1' : '0')};
        visibility: ${(props) => (props.navListFourVisible ? 'visible' : 'hidden')};
        }
      }
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      margin-bottom: 16px;
      li {
        transition: .5s opacity ease-in-out, .5s visibility ease-in-out;
        a {
          font-size: 16px;
          line-height: 16px;
          letter-spacing: 2px;
          margin-bottom: 8px;
          color: #fff;
          &:hover {
            text-decoration: none;
            color: ${variables.colors.brightRed};
            svg {
              .path {
                fill: ${variables.colors.brightRed};
              }
            }
          }
        }
      }
    }
`}
`;

const HomeCenter = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 100;
  width: 100%;
  height: fit-content;
  opacity: ${(props) => (props.contentLoaded ? '1' : '0')};
  transition: opacity 1s ease-in-out 0.5s;
  ${mediaMin.tablet`
    width: 50%;
  `}
  ${mediaMin.tabletLandscape`
    margin: 0 auto;
    top: unset;
    padding: 0;
    width: 20%;
  `}
`;

const TextSwitcher = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${(props) => (props.transparency ? '#00000075' : '#00000000')};
  opacity: ${(props) => (props.contentLoaded ? '1' : '0')};
  transition: opacity 1s ease-in-out 1s, background-color 0.5s ease-in-out;
  ${mediaMin.tabletLandscape`
    margin: 24px;
    height: calc(100vh - 48px);
    width: calc(100vw - 48px);
  `}
`;

const TextItem = styled.h2`
  margin: 0;
  position: absolute;
  opacity: ${(props) => (props.active ? '1' : '0')};
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  transform: translate(-50%, -25%);
  transition: 0.5s ease-in-out;
  left: 50%;
  top: 50%;
  color: #fff;
  text-align: center;
  font-family: ${(props) => (props.big ? 'AWConquerorProDidot-Regular' : 'Granjon')};
  font-size: ${(props) => (props.big ? '40px' : '20px')};
  line-height: ${(props) => (props.big ? '40px' : '28px')};
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  ${mediaMin.tabletLandscape`
    font-size: ${(props) => (props.big ? '64px' : '20px')};
    line-height: ${(props) => (props.big ? '64px' : '28px')};
    transform: translate(-50%, -50%);
  `}
`;

const EnterLink = styled.div`
  transform: translate(-50%, 0);
  position: absolute;
  bottom: 22%;
  left: 50%;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out, transform 0.5s ease-in-out 0s;
  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      font-size: 16px;
      letter-spacing: 2px;
      line-height: 16px;
      color: #fff;
      margin-right: 16px;
      ${mediaMin.tabletLandscape`
        margin-right: 24px;
      `}
    }
    &:hover {
      text-decoration: none;
    }
  }
`;

const handleScroll = (e, setTextIndex) => {
  const { deltaY } = e;
  if (deltaY > 0) {
    setTextIndex(1);
  }
  if (deltaY < 0) {
    setTextIndex(0);
  }
};

const generateRoutes = () => {
  const parsedRoutes = [];
  routes.forEach((section, idx) => {
    const tempSection = [];
    section.forEach((el) => {
      tempSection.push(
        <li key={el.text}>
          <Link to={`/${el.path}`}>{el.text}</Link>
        </li>
      );
    });
    parsedRoutes.push(
      <ul key={tempSection} className={`list_${idx + 1}`}>
        {tempSection}
      </ul>
    );
  });
  return parsedRoutes;
};

const Home = () => {
  const [textIndex, setTextIndex] = useState(0);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [navListOneVisible, setNavListOneVisible] = useState(false);
  const [navListTwoVisible, setNavListTwoVisible] = useState(false);
  const [navListThreeVisible, setNavListThreeVisible] = useState(false);
  const [navListFourVisible, setNavListFourVisible] = useState(false);

  const handlers = useSwipeable({
    onSwipedUp: () => setTextIndex(1),
    onSwipedDown: () => setTextIndex(0),
    preventDefaultTouchmoveEvent: true,
    trackMouse: false,
  });

  const revealNavigation = () => {
    setNavListOneVisible(true);
    setTimeout(() => {
      setNavListTwoVisible(true);
    }, 1000);
    setTimeout(() => {
      setNavListThreeVisible(true);
    }, 1600);
    setTimeout(() => {
      setNavListFourVisible(true);
    }, 2600);
  };

  useEffect(() => {
    window.addEventListener('wheel', (e) => {
      handleScroll(e, setTextIndex);
    });
    setContentLoaded(true);
    setTimeout(revealNavigation, 2000);
    return () => {
      window.removeEventListener('wheel', (e) => {
        handleScroll(e, setTextIndex);
      });
    };
  }, []);

  return (
    <>
      <Popup />
      <Helmet>
        <title>NoMad New Luxury Condominiums | Madison House</title>
        <meta
          name="description"
          content="Open up to more Manhattan. These havens in the heart of NoMad bring home higher ceilings,
          corner windows and a fresh perspective."
        />
      </Helmet>
      <HomeContainer contentLoaded={contentLoaded} {...handlers}>
        <HomeNavigation
          navListOneVisible={navListOneVisible}
          navListTwoVisible={navListTwoVisible}
          navListThreeVisible={navListThreeVisible}
          navListFourVisible={navListFourVisible}
        >
          {generateRoutes()}
          <ul className="list_4">
            <li>
              <a
                href="https://www.instagram.com/madisonhousenyc/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon />
              </a>
            </li>
          </ul>
        </HomeNavigation>
        <HomeCenter contentLoaded={contentLoaded}>
          <img src="/images/logos/madison-house-white.svg" alt="Madison House Logo" />
        </HomeCenter>
        <TextSwitcher contentLoaded={contentLoaded} transparency={textIndex === 1}>
          <TextItem big active={textIndex === 0}>
            <Link to="/manhattan-luxury-condominiums">
              EXTRAORDINARY
              <br />
              VIEWS
              <br />
              ARE FOREVER
              <br />
              IN STYLE
            </Link>
          </TextItem>
          <TextItem active={textIndex === 1}>
            <Link to="/manhattan-luxury-condominiums">
              Open up to more Manhattan.
              <br />
              These havens in the heart of NoMad
              <br />
              bring home higher ceilings, corner windows
              <br />
              and a fresh perspective.
            </Link>
          </TextItem>
          <EnterLink active>
            <Link to="/manhattan-luxury-condominiums">
              <span>ENTER</span>
              <img src="/images/logos/nav-arrow-right.svg" alt="enter site" />
            </Link>
          </EnterLink>
        </TextSwitcher>
        <a
          className="fair-housing-notice"
          href="/documents/Fair_Housing_Notice.pdf"
          target="_blank"
        >
          NYS STANDARD OPERATING PROCEDURE & NYS HOUSING DISCRIMINATION DISCLOSURE FORM.
        </a>
      </HomeContainer>
    </>
  );
};

export default Home;
