/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import { MobilePageTitle, HeaderLogo } from '../utils/page-header';
import variables from '../../styles/Variables';
import { mediaMin } from '../../styles/MediaQueries';
import ResponsiveImage from '../utils/responsive-image';
import PageNavigation from '../utils/page-navigation';

const MobileColumn = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 80px;
  align-items: center;
  overflow-x: hidden;
  ${mediaMin.tabletLandscape`
    display: none;
  `}
`;

const MobileStoryColumn = styled.div`
  display: flex !important;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 36px;
  transition: opacity 0.5s ease-in-out;
  margin-bottom: 24px;
  img.quarter-width {
    max-width: 25%;
  }
  img.half-width {
    max-width: 50%;
  }
  img.three-quarter-width {
    max-width: 75%;
  }
  img.padding-bottom {
    padding-bottom: 5%;
  }
  img.align-right {
    align-self: flex-end;
  }
  img.align-center {
    align-self: center;
  }
`;

const RedHeading = styled.h3`
  font-family: AWConquerorProDidotL-Regular;
  font-size: 48px;
  letter-spacing: 0.86px;
  line-height: 48px;
  color: ${variables.colors.darkRed};
`;

const Caption = styled.p`
  font-family: 'Granjon';
  font-size: 20px;
  line-height: 28px;
  color: ${variables.colors.darkGray};
  width: 100%;
  padding: ${(props) => (props.padding ? '10%' : '0')};
  ${(props) => (props.paddingLeft ? 'padding-left: 50%;' : '')}
  ${(props) => (props.paddingRight ? 'padding-right: 50%;' : '')}
`;

const MobileStory = () => (
  <MobileColumn>
    <HeaderLogo />
    <MobilePageTitle>NOMAD STORY</MobilePageTitle>
    <Fade>
      <MobileStoryColumn>
        <ResponsiveImage
          srcPath="/images/nomad_story/story_1"
          imgAlt="Sketch of Manhattan Skyline"
          imgClass="half-width"
        />
        <RedHeading>
          NOMAD IS THE
          <br />
          MIDDLE OF WHERE
          <br />
          MANHATTAN
          <br />
          MATTERS
        </RedHeading>
      </MobileStoryColumn>
    </Fade>
    <Fade>
      <MobileStoryColumn>
        <ResponsiveImage
          srcPath="/images/nomad_story/story_2"
          imgAlt="Sketch of Manhattan Skyline"
        />
      </MobileStoryColumn>
    </Fade>
    <Fade>
      <MobileStoryColumn>
        <Caption>
          NoMad is the destination for cool-hunters in search of great food, cutting-edge style, art
          happenings, and the latest design.
        </Caption>
        <ResponsiveImage
          srcPath="/images/nomad_story/story_3"
          imgAlt="Sketch of Manhattan Skyline"
          imgClass=""
        />
      </MobileStoryColumn>
    </Fade>
    <Fade>
      <MobileStoryColumn>
        <ResponsiveImage
          srcPath="/images/nomad_story/story_4"
          imgAlt="Sketch of Manhattan Skyline"
        />
      </MobileStoryColumn>
    </Fade>
    <Fade>
      <MobileStoryColumn>
        <ResponsiveImage
          srcPath="/images/nomad_story/story_5"
          imgAlt="Sketch of Manhattan Skyline"
          imgClass=""
        />
      </MobileStoryColumn>
    </Fade>
    <Fade>
      <MobileStoryColumn>
        <Caption>
          NoMad is graced with some of the most recognizable artifacts of New York design and
          architectural history, from Madison Square Park to the iconic Empire State Building.
        </Caption>
      </MobileStoryColumn>
    </Fade>
    <Fade>
      <MobileStoryColumn>
        <ResponsiveImage
          srcPath="/images/nomad_story/story_6"
          imgAlt="Sketch of Manhattan Skyline"
          imgClass=""
        />
      </MobileStoryColumn>
    </Fade>
    <Fade>
      <MobileStoryColumn>
        <ResponsiveImage
          srcPath="/images/nomad_story/story_7"
          imgAlt="Sketch of Manhattan Skyline"
          imgClass=""
        />
        <Caption>
          The spirit of the Ace Hotel, with its Michelin-starred dining, the ever lively lobby, and
          art installations, now fills the neighborhood.
        </Caption>
      </MobileStoryColumn>
    </Fade>
    <Fade>
      <MobileStoryColumn>
        <RedHeading>
          A BEAUTY BUILT UP
          <br />
          OVER CENTURIES,
          <br />
          RIGHT TO THE NOW
        </RedHeading>
      </MobileStoryColumn>
    </Fade>
    <Fade>
      <MobileStoryColumn>
        <ResponsiveImage
          srcPath="/images/nomad_story/story_9"
          imgAlt="Sketch of Manhattan Skyline"
        />
        <Caption>
          The beauty of NoMad reveals itself around every corner. These architectural gems are now
          home to much of the great dining and social activity.
        </Caption>
      </MobileStoryColumn>
    </Fade>
    <Fade>
      <MobileStoryColumn>
        <ResponsiveImage
          srcPath="/images/nomad_story/story_10"
          imgAlt="Sketch of Manhattan Skyline"
          imgClass=""
        />
        <Caption>
          Not all is bustle: Madison Square Park is six acres of lush greenery for picnics, reading,
          or simply lying out to enjoy the sun and breezes.
        </Caption>
        <ResponsiveImage
          srcPath="/images/nomad_story/story_13"
          imgAlt="Sketch of Manhattan Skyline"
          imgClass="half-width align-right"
        />
      </MobileStoryColumn>
    </Fade>
    <Fade>
      <MobileStoryColumn>
        <ResponsiveImage
          srcPath="/images/nomad_story/story_12"
          imgAlt="Sketch of Manhattan Skyline"
          imgClass=""
        />
      </MobileStoryColumn>
    </Fade>
    <Fade>
      <MobileStoryColumn>
        <ResponsiveImage
          srcPath="/images/nomad_story/story_15"
          imgAlt="Sketch of Manhattan Skyline"
          imgClass="half-width"
        />
        <RedHeading>
          DINING GETS
          <br />
          THE NOMAD
          <br />
          TOUCH
        </RedHeading>
        <Caption>
          A parade of new culinary arrivals adheres to a common mission: Prepare authentic cuisine
          with the best ingredients in a joyful setting.
        </Caption>
      </MobileStoryColumn>
    </Fade>
    <Fade>
      <MobileStoryColumn>
        <ResponsiveImage
          srcPath="/images/nomad_story/story_17"
          imgAlt="Sketch of Manhattan Skyline"
        />
        <ResponsiveImage
          srcPath="/images/nomad_story/story_19"
          imgAlt="Sketch of Manhattan Skyline"
          imgClass=""
        />
      </MobileStoryColumn>
    </Fade>
    <Fade>
      <MobileStoryColumn>
        <Caption>
          Famed restauranteur Stephen Starr produced a British-inspired, Michelin-starred menu for
          the clubby rooms of The Clocktower.
        </Caption>
      </MobileStoryColumn>
    </Fade>
    <Fade>
      <MobileStoryColumn>
        <ResponsiveImage
          srcPath="/images/nomad_story/story_20"
          imgAlt="Sketch of Manhattan Skyline"
          imgClass="half-width align-right"
        />
      </MobileStoryColumn>
    </Fade>
    <Fade>
      <MobileStoryColumn>
        <ResponsiveImage
          srcPath="/images/nomad_story/story_21"
          imgAlt="Sketch of Manhattan Skyline"
          imgClass="three-quarter-width"
        />
      </MobileStoryColumn>
    </Fade>
    <Fade>
      <MobileStoryColumn>
        <ResponsiveImage
          srcPath="/images/nomad_story/story_23"
          imgAlt="Sketch of Manhattan Skyline"
          imgClass="half-width"
        />
        <RedHeading>
          A LIFESTYLE WITH
          <br />
          ACCESS TO THE
          <br />
          BEST DESIGN
        </RedHeading>
      </MobileStoryColumn>
    </Fade>
    <Fade>
      <MobileStoryColumn>
        <Caption>
          The entire experience of daily life gets a makeover, whether it’s a trip to the barbershop
          where the stylists use handcrafted lotions and soaps, or an evening spent at a magic show
          that takes wonder to a new level.
        </Caption>
        <ResponsiveImage
          srcPath="/images/nomad_story/story_26"
          imgAlt="Sketch of Manhattan Skyline"
          imgClass=""
        />
      </MobileStoryColumn>
    </Fade>
    <MobileStoryColumn />
    <Fade>
      <MobileStoryColumn>
        <ResponsiveImage
          srcPath="/images/nomad_story/story_25"
          imgAlt="Sketch of Manhattan Skyline"
          imgClass="half-width align-center"
        />
      </MobileStoryColumn>
    </Fade>
    <Fade>
      <MobileStoryColumn>
        <Caption>
          Rei Kawakubo brought her international department store, Dover Street Market, to NoMad.
        </Caption>
      </MobileStoryColumn>
    </Fade>
    <Fade>
      <MobileStoryColumn>
        <ResponsiveImage
          srcPath="/images/nomad_story/story_27"
          imgAlt="Sketch of Manhattan Skyline"
          imgClass="three-quarter-width"
        />
      </MobileStoryColumn>
    </Fade>
    <Fade>
      <MobileStoryColumn>
        <Caption>
          Poliform and B&B Italia expand the options for selecting highdesign furniture as well as
          smaller grace notes for gifts or self-gifting.
        </Caption>
        <ResponsiveImage
          srcPath="/images/nomad_story/story_28"
          imgAlt="Sketch of Manhattan Skyline"
          imgClass="half-width align-right"
        />
      </MobileStoryColumn>
    </Fade>
    <Fade>
      <MobileStoryColumn>
        <ResponsiveImage
          srcPath="/images/nomad_story/story_29"
          imgAlt="Sketch of Manhattan Skyline"
        />
      </MobileStoryColumn>
    </Fade>
    <Fade>
      <MobileStoryColumn>
        <ResponsiveImage
          srcPath="/images/nomad_story/story_30"
          imgAlt="Sketch of Manhattan Skyline"
          imgClass="three-quarter-width"
        />
      </MobileStoryColumn>
    </Fade>
    <Fade>
      <MobileStoryColumn>
        <ResponsiveImage
          srcPath="/images/nomad_story/story_31"
          imgAlt="Sketch of Manhattan Skyline"
          imgClass=""
        />
        <Caption>
          At Le Labo, the nearly addictive colognes are mixed on premises while you wait.
        </Caption>
      </MobileStoryColumn>
    </Fade>
    <Fade>
      <MobileStoryColumn>
        <ResponsiveImage
          srcPath="/images/nomad_story/story_32"
          imgAlt="Sketch of Manhattan Skyline"
          imgClass=""
        />
        <Caption>
          Long-loved Rizzoli moved to NoMad in 2016 to take over an exquisite turn-of-the century
          space.
        </Caption>
      </MobileStoryColumn>
    </Fade>
    <Fade>
      <MobileStoryColumn>
        <ResponsiveImage
          srcPath="/images/nomad_story/story_34"
          imgAlt="Sketch of Manhattan Skyline"
          imgClass=""
        />
        <Caption>
          Madison Square Park is itself an outdoor museum, with a calendar of artist exhibitions
          specially commissioned for the space.
        </Caption>
      </MobileStoryColumn>
    </Fade>

    <Fade>
      <MobileStoryColumn>
        <ResponsiveImage
          srcPath="/images/nomad_story/story_38"
          imgAlt="Sketch of Manhattan Skyline"
        />
      </MobileStoryColumn>
    </Fade>
    <Fade>
      <MobileStoryColumn>
        <Caption>
          NoMad has so much on offer, it awaits whatever lifestyle one wants to fashion.
        </Caption>
      </MobileStoryColumn>
    </Fade>
    <Fade>
      <MobileStoryColumn>
        <ResponsiveImage
          srcPath="/images/nomad_story/story_39"
          imgAlt="Sketch of Manhattan Skyline"
          imgClass=""
        />
      </MobileStoryColumn>
    </Fade>
    <PageNavigation
      prevPage="AVAILABILITY"
      prevPath="/nomad-new-condominium-availability"
      nextPage="NOMAD GUIDE"
      nextPath="/nomad-neighborhood-guide"
    />
  </MobileColumn>
);

export default MobileStory;
