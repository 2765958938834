import React from 'react';
import MediaQuery from 'react-responsive';
import { Helmet } from 'react-helmet';

import ResidencesDesktop from '../components/residences/residences-desktop';
import ResidencesMobile from '../components/residences/residences-mobile';
import PageNavigation from '../components/utils/page-navigation';
import PhotoViewer from '../components/photo-viewer';
import { residencePhotos, residencePhotosMobile } from '../data/residences';

const Residences = () => (
  <>
    <Helmet>
      <title>NoMad New Condominium Residences | Madison House</title>
      <meta
        name="description"
        content="Madison House lets you float above the city. Interiors by Gachot provide warmth, choice, and the best bones for better living"
      />
    </Helmet>
    <MediaQuery minWidth={1024}>
      <ResidencesDesktop />
      <PhotoViewer photoArray={residencePhotos} />
    </MediaQuery>
    <MediaQuery maxWidth={1023}>
      <ResidencesMobile />
      <PhotoViewer photoArray={residencePhotosMobile} />
    </MediaQuery>
    <PageNavigation
      prevPath="/manhattan-luxury-condominiums"
      prevPage="ARCHITECTURE"
      nextPath="/manhattan-penthouse-condominiums"
      nextPage="PENTHOUSES"
    />
  </>
);

export default Residences;
