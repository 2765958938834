const amenitiesCopy = {
  desktop: {
    heading: 'AMENITIES\nTHAT MAKE\nMADISON HOUSE MORE\nTHAN A HOME',
    subHeading:
      "Gachot brings its experience with international hotel design to Madison House's 30,000 square feet of club style amenities spaces.",
    sectionOneTitle: 'SPA',
    sectionOneText:
      'A sculptural ribbon creates a stylish crest over the 75-foot, multi-lane pool. With diffused lighting, the tiled and brass-accented spa sanctuary also includes a hot tub, cold plunge, sauna and lounge area.',
    sectionTwoTitle: 'ATHLETIC CLUB',
    sectionTwoText:
      'A full 120 feet above street level, the gym and club enjoy an abundance of natural light. Above the double-height gym, with oak floors and walnut-wood paneled walls, find the fun of a children’s playroom, billiards room and golf simulator.',
    sectionThreeTitle: 'THE MADISON CLUB',
    sectionThreeText:
      'The Gachot-designed club, with double-height ceilings, offers a place to enjoy a book, share a cocktail with friends or have business meetings in private. The oak floors, wood-paneled wet bar, and fireplaces bring to mind the elegance of historic Madison Park’s mansion living. Warm carpets, lighting, and paneling over the 12-foot doors deliver a high-design experience. A 14-seat private dining room with a show kitchen and separate catering kitchen complete the space.',
    sectionFourTitle: '',
    sectionFourText: '',
    sectionFiveTitle: '',
    sectionFiveText: '',
    sectionSixTitle: '',
    sectionSixText: '',
    sectionSevenTitle: 'CHILDREN’S PLAYROOM',
    sectionSevenText: 'A creative escape conveniently located next to the athletic club with plenty of toys and games to keep your little ones occupied.',
    sectionEightTitle: '',
    sectionEightText: '',
  },
  mobile: {
    heading: 'AMENITIES\nTHAT MAKE\nMADISON HOUSE MORE\nTHAN A HOME',
    subHeading:
      '30,000 square feet of club style amenities boast an expansive athletic club, a lap pool, a golf simulator, and much more.',
    sectionOneTitle: 'SPA',
    sectionOneText: '75-foot lap pool with hot tub, cold plunge, sauna and lounge.',
    sectionTwoTitle: 'ATHLETIC CLUB',
    sectionTwoText: 'Light-filled fitness center, 120’ in the air.',
    sectionThreeTitle: 'THE MADISON CLUB',
    sectionThreeText: 'The Gachot-designed club, with double-height ceilings, offers a place to enjoy a book, share a cocktail with friends or have business meetings in private. The oak floors, wood-paneled wet bar, and fireplaces bring to mind the elegance of historic Madison Park’s mansion living. Warm carpets, lighting, and paneling over the 12-foot doors deliver a high-design experience. A 14-seat private dining room with a show kitchen and separate catering kitchen complete the space.',
    sectionFourTitle: '',
    sectionFourText: '',
    sectionFiveTitle: '',
    sectionFiveText: '',
    sectionSixTitle: '',
    sectionSixText: '',
    sectionSevenTitle: 'CHILDREN’S PLAYROOM',
    sectionSevenText: 'A creative escape conveniently located next to the athletic club with plenty of toys and games to keep your little ones occupied.',
    sectionEightTitle: '',
    sectionEightText: '',
  },
};

const amenitiesPhotos = [
  {
    src: '/images/amenities/amenities_1',
    alt: 'Reception Lounge overlooking the glassed-in garden',
  },
  {
    src: '/images/amenities/amenities_2',
    alt: '75-foot lap pool with hot tub, cold plunge, and sauna.',
  },
  {
    src: '/images/amenities/amenities_2_3'
  },
  {
    src: '/images/amenities/amenities_2_2'
  },
  { src: '/images/amenities/amenities_3', alt: 'Light-filled fitness center, 120’ in the air.' },
  { src: '/images/amenities/amenities_4', alt: 'Double-height club room designed by Gachot' },
  { src: '/images/amenities/amenities_5', alt: '' },
  { src: '/images/amenities/amenities_10', alt: '' },
  { src: '/images/amenities/amenities_5_2', alt: '' },
  { src: '/images/amenities/amenities_6', alt: '' },
  { src: '/images/amenities/amenities_7', alt: '' },
  { src: '/images/amenities/amenities_8', alt: '' },
  { src: '/images/amenities/amenities_9', alt: '' },
  { src: '/images/amenities/amenities_11', alt: '' },
  { src: '/images/amenities/amenities_12', alt: '' }
];

export { amenitiesCopy, amenitiesPhotos };
