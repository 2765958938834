/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-lonely-if */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import * as Scroll from 'react-scroll';

import validateEmail from '../utils/validateEmail';
import AutoComplete from './auto-complete';
import variables from '../../styles/Variables';

const RegisterForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 15vh 10vw 0 45vw;
  box-sizing: border-box;
  opacity: ${(props) => (props.showForm ? '1' : '0')};
  visibility: ${(props) => (props.showForm ? 'visible' : 'hidden')};
  transition-property: opacity, visibility;
  transition-duration: 1.5s, 1.5s;
  transition-timing-function: ease-in-out;
  h5 {
    margin: 0;
    font-size: 1.25em;
    &:focus {
      border-bottom: 1px solid #fff;
      width: max-content;
      box-shadow: none;
      outline: none;
    }
  }
  .input-label {
    display: flex;
    flex-direction: column;
    font-size: 1.25em;
    .react-autosuggest__container {
      input {
        width: 100%;
      }
      .react-autosuggest__suggestions-container--open {
        .react-autosuggest__suggestions-list {
          list-style-type: none;
          padding: 0;
          li {
            padding: 0.5em 0.5em;
            cursor: pointer;
          }
        }
      }
    }
    input {
      height: 80px;
      width: 100%;
      margin-top: 0.5em;
      padding: 1em 0.5em;
      box-sizing: border-box;
      font-size: 1.25em;
      color: ${variables.colors.darkRed};
      background-color: #fff;
      border: 1px solid ${variables.colors.darkRed};
      &:focus {
        border: 4px solid ${variables.colors.darkRed};
        box-shadow: none;
        outline: none;
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
    input[type='number'] {
      -moz-appearance: textfield; /* Firefox */
    }
    input:-webkit-autofill,
    input:-internal-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      background-color: #00000000;
    }
  }
  .radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    background-color: #f1f1f1;
    color: #666;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    padding: 0 !important;
    &:checked {
      background-color: ${variables.colors.darkRed};
    }
  }
  button {
    height: 80px;
    font-size: 1.25em;
    border: none;
    color: #fff;
    background: ${variables.colors.brightRed};
    cursor: pointer;
  }
`;
const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${(props) => (props.active ? '1' : '.25')};
`;

const RadioDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .input-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
    input {
      margin: 0 0 0 2em;
      height: 19px;
      width: 19px;
    }
  }
`;

const SelectDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SelectLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: ${(props) => (props.selected ? '1em 0.5em' : '1em 0')};
  height: 40px;
  background: transparent;
  font-weight: ${(props) => (props.focused ? 'bold' : '')};
  border: ${(props) => (props.selected ? `1px solid ${variables.colors.darkRed}` : '')};
  cursor: pointer;
  position: relative;
  img {
    height: 40px;
    width: 40px;
    opacity: ${(props) => (props.selected ? '1' : '0')};
  }
  input {
    top: 0;
    left: 0;
    position: absolute;
    visibility: hidden;
    margin: 0;
    height: 100%;
    width: 100%;
  }
`;

const AlertRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: ${(props) => (props.bottom ? '60vh' : '24px')};
  height: 32px;
  justify-content: space-between;
`;

const RequiredFlag = styled.div`
  width: ${(props) => (props.large ? '100%' : '25%')};
  box-sizing: border-box;
  background: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.25em;
  font-weight: medium;
  transition: opacity 0.25s ease-in-out;
  opacity: ${(props) => (props.error ? '1' : '0')};
`;

const PressEnter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.25em;
  font-weight: medium;
  width: 25%;
  transition: opacity 0.25s ease-in-out;
  opacity: ${(props) => (props.complete ? '1' : '0')};
`;

const FormHeading = styled.div`
  h3 {
    font-size: 64px;
    line-height: 64px;
    font-family: 'AWConquerorProDidotL-Regular';
    margin: 0;
    color: ${variables.colors.darkRed};
  }
  span {
    font-family: 'Granjon';
    font-size: 12px;
    line-height: 12px;
    color: ${variables.colors.darkGray};
  }
  margin-bottom: 100px;
`;

export default class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollHeight: null,
      locations: [],
      activeElement: 0,
      selectArrayOnePos: 0,
      selectArrayTwoPos: 0,
      selectArrayThreePos: 0,
    };
    this.refArray = [
      (this.inputRefOne = React.createRef()),
      (this.inputRefTwo = React.createRef()),
      (this.inputRefThree = React.createRef()),
      (this.inputRefFour = React.createRef()),
      (this.inputRefFive = React.createRef()),
      (this.inputRefSix = React.createRef()),
      (this.inputRefSeven = React.createRef()),
      (this.inputRefEight = React.createRef()),
      (this.inputRefNine = React.createRef()),
      (this.inputRefTen = React.createRef()),
      (this.inputRefEleven = React.createRef()),
      (this.inputRefTwelve = React.createRef()),
    ];

    this.selectArrayOne = [
      (this.selectRefOne = React.createRef()),
      (this.selectRefTwo = React.createRef()),
      (this.selectRefThree = React.createRef()),
      (this.selectRefFour = React.createRef()),
      (this.selectRefFive = React.createRef()),
      (this.selectRefSix = React.createRef()),
      (this.selectRefSeven = React.createRef()),
      (this.selectRefEight = React.createRef()),
    ];

    this.selectArrayTwo = [
      (this.selectRefNine = React.createRef()),
      (this.selectRefTen = React.createRef()),
      (this.selectRefEleven = React.createRef()),
      (this.selectRefTwelve = React.createRef()),
      (this.selectRefThirteen = React.createRef()),
    ];

    this.selectArrayThree = [
      (this.selectRefFourteen = React.createRef()),
      (this.selectRefFifteen = React.createRef()),
      (this.selectRefSixteen = React.createRef()),
      (this.selectRefSeventeen = React.createRef()),
      (this.selectRefEighteen = React.createRef()),
      (this.selectRefNineteen = React.createRef()),
      (this.selectRefTwenty = React.createRef()),
    ];
  }

  componentDidMount() {
    this.createLocationsArray();
    window.addEventListener('scroll', this.updateScrollLocation);
    window.addEventListener('keydown', this.handleKeyPress);
    window.addEventListener('resize', () => {
      this.createLocationsArray();
      this.updateScrollLocation();
    });
    this.scrollToItem(0, 0);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateScrollLocation);
    window.removeEventListener('keydown', this.handleKeyPress);
    window.removeEventListener('resize', () => {
      this.createLocationsArray();
      this.updateScrollLocation();
    });
  }

  createLocationsArray = () => {
    const locations = [];
    for (let idx = 0; idx < this.refArray.length; idx += 1) {
      if (this.refArray[idx].current) {
        locations.push({
          top: this.refArray[idx].current.offsetTop,
          bottom: this.refArray[idx].current.offsetTop + this.refArray[idx].current.offsetHeight,
        });
      }
    }
    this.setState({ locations });
  };

  updateScrollLocation = () => {
    const { broker } = this.props;
    const { locations } = this.state;
    const scrollHeight = window.scrollY + window.innerHeight * 0.25;
    let activeElement = 0;
    for (let idx = 0; idx < locations.length; idx += 1) {
      if (scrollHeight > locations[idx].top && scrollHeight <= locations[idx].bottom) {
        activeElement = idx;
      }
    }
    if (!JSON.parse(broker) && activeElement >= 2) {
      activeElement += 1;
    }
    this.setState({
      activeElement,
      scrollHeight,
    });
  };

  traverseSelect = (select, direction) => {
    const newState = this.state;
    if (direction === 'up') {
      if (this.state[select] > 0) {
        newState[select] -= 1;
      }
    } else {
      if (this.state[select] < this.selectArrayOne.length - 1) {
        newState[select] += 1;
      }
    }
    this.setState(newState);
  };

  handleKeyPress = (event) => {
    const { activeElement, selectArrayOnePos, selectArrayTwoPos, selectArrayThreePos } = this.state;
    const { validateRow, howHear, residence, broker, submitForm, priceRange } = this.props;
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        if (
          (this.refArray[activeElement + 1] !== undefined &&
            this.refArray[activeElement + 1].current) ||
          (activeElement === 8 && priceRange === '')
        ) {
          if (activeElement === 6) {
            const syntheticEvent = {
              target: {
                name: 'howHear',
                value: this.selectArrayOne[selectArrayOnePos].current.children[2].value,
              },
            };
            this.updateForm(syntheticEvent);
            this.scrollToItem(activeElement + 1);
          } else if (activeElement === 7) {
            const syntheticEvent = {
              target: {
                name: 'residence',
                value: this.selectArrayTwo[selectArrayTwoPos].current.children[2].value,
              },
            };
            this.updateForm(syntheticEvent);
            this.scrollToItem(activeElement + 1);
          } else if (activeElement === 8) {
            const syntheticEvent = {
              target: {
                name: 'priceRange',
                value: this.selectArrayThree[selectArrayThreePos].current.children[2].value,
              },
            };
            this.updateForm(syntheticEvent);
            this.scrollToItem(activeElement + 1);
          } else {
            if (event.target.type === 'text' || event.target.type === 'tel') {
              if (validateRow(event)) {
                this.scrollToItem(activeElement + 1);
              }
            } else {
              this.scrollToItem(activeElement + 1);
            }
          }
        } else if (
          this.refArray[activeElement + 2] !== undefined &&
          this.refArray[activeElement + 2].current
        ) {
          event.preventDefault();
          if (activeElement === 6) {
            if (selectArrayOnePos === this.selectArrayOne.length - 1) {
              const syntheticEvent = {
                target: {
                  name: 'howHear',
                  value: howHear,
                },
              };
              if (validateRow(syntheticEvent)) {
                this.scrollToItem(activeElement + 2);
              }
            } else {
              this.traverseSelect('selectArrayOnePos', 'down');
            }
          } else if (activeElement === 7) {
            if (selectArrayTwoPos === this.selectArrayTwo.length - 1) {
              const syntheticEvent = {
                target: {
                  name: 'residence',
                  value: residence,
                },
              };
              if (validateRow(syntheticEvent)) {
                this.scrollToItem(activeElement + 2);
              }
            } else {
              this.traverseSelect('selectArrayTwoPos', 'down');
            }
          } else if (activeElement === 8) {
            if (selectArrayThreePos === this.selectArrayThree.length - 1) {
              const syntheticEvent = {
                target: {
                  name: 'priceRange',
                  value: priceRange,
                },
              };
              if (validateRow(syntheticEvent)) {
                this.scrollToItem(activeElement + 2);
              }
            } else {
              this.traverseSelect('selectArrayThreePos', 'down');
            }
          } else {
            if (event.target.type === 'text' || event.target.type === 'tel') {
              if (validateRow(event)) {
                this.scrollToItem(activeElement + 2);
              }
            } else {
              this.scrollToItem(activeElement + 2);
            }
          }
        } else {
          submitForm(event);
        }
        break;
      case 'ArrowDown':
        if (
          (this.refArray[activeElement + 1] !== undefined &&
            this.refArray[activeElement + 1].current) ||
          (JSON.parse(broker) &&
            activeElement === 7 &&
            selectArrayTwoPos < this.selectArrayTwo.length - 1)
        ) {
          event.preventDefault();
          if (activeElement === 6) {
            if (selectArrayOnePos === this.selectArrayOne.length - 1) {
              const syntheticEvent = {
                target: {
                  name: 'howHear',
                  value: howHear,
                },
              };
              if (validateRow(syntheticEvent)) {
                this.scrollToItem(activeElement + 1);
              }
            } else {
              this.traverseSelect('selectArrayOnePos', 'down');
            }
          } else if (activeElement === 7) {
            if (selectArrayTwoPos === this.selectArrayTwo.length - 1) {
              const syntheticEvent = {
                target: {
                  name: 'residence',
                  value: residence,
                },
              };
              if (validateRow(syntheticEvent)) {
                this.scrollToItem(activeElement + 1);
              }
            } else {
              this.traverseSelect('selectArrayTwoPos', 'down');
            }
          } else if (activeElement === 8) {
            if (selectArrayThreePos === this.selectArrayThree.length - 1) {
              const syntheticEvent = {
                target: {
                  name: 'priceRange',
                  value: priceRange,
                },
              };
              if (validateRow(syntheticEvent)) {
                this.scrollToItem(activeElement + 1);
              }
            } else {
              this.traverseSelect('selectArrayThreePos', 'down');
            }
          } else {
            if (event.target.type === 'text' || event.target.type === 'tel') {
              if (validateRow(event)) {
                this.scrollToItem(activeElement + 1);
              }
            } else {
              this.scrollToItem(activeElement + 1);
            }
          }
        } else if (
          this.refArray[activeElement + 2] !== undefined &&
          this.refArray[activeElement + 2].current
        ) {
          event.preventDefault();
          if (activeElement === 6) {
            if (selectArrayOnePos === this.selectArrayOne.length - 1) {
              const syntheticEvent = {
                target: {
                  name: 'howHear',
                  value: howHear,
                },
              };
              if (validateRow(syntheticEvent)) {
                this.scrollToItem(activeElement + 2);
              }
            } else {
              this.traverseSelect('selectArrayOnePos', 'down');
            }
          } else if (activeElement === 7) {
            if (selectArrayTwoPos === this.selectArrayTwo.length - 1) {
              const syntheticEvent = {
                target: {
                  name: 'residence',
                  value: residence,
                },
              };
              if (validateRow(syntheticEvent)) {
                this.scrollToItem(activeElement + 2);
              }
            } else {
              this.traverseSelect('selectArrayTwoPos', 'down');
            }
          } else if (activeElement === 8) {
            if (selectArrayThreePos === this.selectArrayThree.length - 1) {
              const syntheticEvent = {
                target: {
                  name: 'priceRange',
                  value: priceRange,
                },
              };
              if (validateRow(syntheticEvent)) {
                this.scrollToItem(activeElement + 2);
              }
            } else {
              this.traverseSelect('selectArrayThreePos', 'down');
            }
          } else {
            if (event.target.type === 'text' || event.target.type === 'tel') {
              if (validateRow(event)) {
                this.scrollToItem(activeElement + 2);
              }
            } else {
              this.scrollToItem(activeElement + 2);
            }
          }
        } else {
          submitForm(event);
        }
        break;
      case 'ArrowUp':
        if (
          this.refArray[activeElement - 1] !== undefined &&
          this.refArray[activeElement - 1].current
        ) {
          event.preventDefault();
          if (activeElement === 6) {
            if (selectArrayOnePos === 0) {
              this.scrollToItem(activeElement - 1);
            } else {
              this.traverseSelect('selectArrayOnePos', 'up');
            }
          } else if (activeElement === 7) {
            if (selectArrayTwoPos === 0) {
              this.scrollToItem(activeElement - 1);
            } else {
              this.traverseSelect('selectArrayTwoPos', 'up');
            }
          } else if (activeElement === 8) {
            if (selectArrayThreePos === 0) {
              this.scrollToItem(activeElement - 1);
            } else {
              this.traverseSelect('selectArrayThreePos', 'up');
            }
          } else {
            this.scrollToItem(activeElement - 1);
          }
        } else if (
          this.refArray[activeElement - 2] !== undefined &&
          this.refArray[activeElement - 2].current
        ) {
          event.preventDefault();
          if (activeElement === 6) {
            if (selectArrayOnePos === 0) {
              this.scrollToItem(activeElement - 2);
            } else {
              this.traverseSelect('selectArrayOnePos', 'up');
            }
          } else if (activeElement === 7) {
            if (selectArrayTwoPos === 0) {
              this.scrollToItem(activeElement - 2);
            } else {
              this.traverseSelect('selectArrayTwoPos', 'up');
            }
          } else if (activeElement === 8) {
            if (selectArrayThreePos === 0) {
              this.scrollToItem(activeElement - 2);
            } else {
              this.traverseSelect('selectArrayThreePos', 'up');
            }
          } else {
            this.scrollToItem(activeElement - 2);
          }
        }
        break;
      default:
        break;
    }
  };

  scrollToItem = (index, durationMS = 250) => {
    if (this.refArray[index] && this.refArray[index].current) {
      const nextItem = this.refArray[index].current.offsetTop - window.innerHeight / 4 + 1;
      Scroll.animateScroll.scrollTo(nextItem, { duration: durationMS });
      if (this.refArray[index].current.children[0].children[0] !== undefined) {
        if (this.refArray[index].current.firstChild.firstChild.nodeValue === 'BROKERAGE FIRM*') {
          this.refArray[index].current.children[0].children[0].children[0].focus();
        } else {
          this.refArray[index].current.children[0].children[0].focus();
        }
      } else {
        this.refArray[index].current.children[0].focus();
      }
    }
  };

  updateForm = (...args) => {
    const { handleChange } = this.props;
    const { activeElement } = this.state;
    handleChange(args[0]);
    setTimeout(this.createLocationsArray, 25);
    setTimeout(() => {
      if (args[1] !== undefined && activeElement !== args[1]) {
        this.scrollToItem(args[1]);
      }
      if (args[1] !== undefined && activeElement === args[1]) {
        this.scrollToItem(args[1] + 1);
      }
    }, 50);
  };

  render() {
    const {
      broker,
      name,
      address,
      email,
      phone,
      howHear,
      residence,
      represented,
      brokerName,
      brokerFirm,
      priceRange,
      errors,
      submitForm,
      showForm,
      setEmailSubmitted,
      setEmailFormMessageVisable,
    } = this.props;
    const { activeElement, selectArrayOnePos, selectArrayTwoPos, selectArrayThreePos } = this.state;
    return (
      <RegisterForm
        showForm={showForm}
        onSubmit={(e) => {
          submitForm(e);
          setEmailSubmitted(true);
          setEmailFormMessageVisable(false);
        }}
      >
        <FormHeading>
          <h3>REGISTER</h3>
          <span>* required field</span>
        </FormHeading>
        <FormRow ref={this.inputRefOne} active={activeElement === 0}>
          <h5>I AM A...</h5>
          <RadioDiv>
            <input type="hidden" name="ictvid" id="ictvid" value="" />
            <label className="input-label" htmlFor="buyer">
              Buyer
              <input
                name="broker"
                id="buyer"
                type="radio"
                value={false}
                checked={!JSON.parse(broker)}
                className="radio"
                onChange={(...args) => {
                  this.updateForm(args[0], 0);
                }}
              />
            </label>
            <label className="input-label" htmlFor="broker">
              Broker
              <input
                name="broker"
                id="broker"
                type="radio"
                value
                checked={JSON.parse(broker)}
                className="radio"
                onChange={(...args) => {
                  this.updateForm(args[0], 0);
                }}
              />
            </label>
          </RadioDiv>
          <AlertRow>
            <RequiredFlag aria-hidden>REQUIRED</RequiredFlag>
            <PressEnter complete={broker !== '' && activeElement === 0}>Press enter</PressEnter>
          </AlertRow>
        </FormRow>
        <FormRow ref={this.inputRefTwo} active={activeElement === 1}>
          <label className="input-label" htmlFor="name">
            FIRST + LAST NAME*
            <input
              name="name"
              id="name"
              type="text"
              value={name}
              onChange={(...args) => {
                this.updateForm(args[0]);
              }}
              onFocus={() => this.scrollToItem(1)}
            />
          </label>
          <AlertRow>
            <RequiredFlag aria-hidden={!errors.name} error={errors.name}>
              REQUIRED
            </RequiredFlag>
            <PressEnter complete={name !== '' && activeElement === 1}>Press enter</PressEnter>
          </AlertRow>
        </FormRow>
        {JSON.parse(broker) ? (
          <FormRow ref={this.inputRefThree} active={activeElement === 2}>
            <label className="input-label" htmlFor="brokerFirm">
              BROKERAGE FIRM*
              <AutoComplete
                name="brokerFirm"
                id="brokerFirm"
                type="text"
                value={brokerFirm}
                onChangeCallback={(...args) => {
                  this.updateForm(args[0]);
                }}
                onFocus={() => this.scrollToItem(2)}
              />
            </label>
            <AlertRow>
              <RequiredFlag aria-hidden={!errors.brokerFirm} error={errors.brokerFirm}>
                REQUIRED
              </RequiredFlag>
              <PressEnter complete={brokerFirm !== '' && activeElement === 2}>
                Press enter
              </PressEnter>
            </AlertRow>
          </FormRow>
        ) : (
          ''
        )}
        <FormRow ref={this.inputRefFour} active={activeElement === 3}>
          <label className="input-label" htmlFor="address">
            ADDRESS
            <input
              name="address"
              id="address"
              type="text"
              value={address}
              onChange={(...args) => {
                this.updateForm(args[0]);
              }}
              onFocus={() => this.scrollToItem(3)}
            />
          </label>
          <AlertRow>
            <RequiredFlag aria-hidden>REQUIRED</RequiredFlag>
            <PressEnter complete={address !== '' && activeElement === 3}>Press enter</PressEnter>
          </AlertRow>
        </FormRow>
        <FormRow ref={this.inputRefFive} active={activeElement === 4}>
          <label className="input-label" htmlFor="email">
            EMAIL ADDRESS*
            <input
              name="email"
              id="email"
              type="text"
              value={email}
              onChange={(...args) => {
                this.updateForm(args[0]);
              }}
              onFocus={() => this.scrollToItem(4)}
            />
          </label>
          <AlertRow>
            <RequiredFlag aria-hidden={!errors.email} error={errors.email}>
              REQUIRED
            </RequiredFlag>
            <PressEnter complete={validateEmail(email) && activeElement === 4}>
              Press enter
            </PressEnter>
          </AlertRow>
        </FormRow>
        <FormRow ref={this.inputRefSix} active={activeElement === 5}>
          <label className="input-label" htmlFor="phone">
            PHONE NUMBER*
            <input
              name="phone"
              id="phone"
              type="number"
              value={phone}
              onChange={(...args) => {
                this.updateForm(args[0]);
              }}
              onFocus={() => this.scrollToItem(5)}
            />
          </label>
          <AlertRow>
            <RequiredFlag aria-hidden={!errors.phone} error={errors.phone}>
              REQUIRED
            </RequiredFlag>
            <PressEnter complete={phone !== '' && activeElement === 5}>Press enter</PressEnter>
          </AlertRow>
        </FormRow>
        <FormRow ref={this.inputRefSeven} active={activeElement === 6}>
          <h5 tabIndex="-1">HOW DID YOU HEAR ABOUT US?*</h5>
          <SelectDiv>
            <SelectLabel
              ref={this.selectRefOne}
              selected={howHear === 'eBlast'}
              focused={selectArrayOnePos === 0}
              htmlFor="howHear-3"
            >
              <span>E-blast</span>
              <img
                src="/images/logos/check.svg"
                alt="selected"
                aria-hidden={howHear !== 'eBlast'}
              />
              <input
                name="howHear"
                id="howHear-3"
                type="radio"
                value="eBlast"
                onChange={(...args) => {
                  this.updateForm(args[0], 6);
                }}
              />
            </SelectLabel>
            <SelectLabel
              ref={this.selectRefTwo}
              selected={howHear === 'www.Elliman.com'}
              focused={selectArrayOnePos === 1}
              htmlFor="howHear-5"
            >
              <span>Elliman.com</span>
              <img
                src="/images/logos/check.svg"
                alt="selected"
                aria-hidden={howHear !== 'www.Elliman.com'}
              />
              <input
                name="howHear"
                id="howHear-5"
                type="radio"
                value="www.Elliman.com"
                onChange={(...args) => {
                  this.updateForm(args[0], 6);
                }}
              />
            </SelectLabel>
            <SelectLabel
              ref={this.selectRefThree}
              selected={howHear === 'Online Ad'}
              focused={selectArrayOnePos === 2}
              htmlFor="howHear-8"
            >
              <span>Online Ad</span>
              <img
                src="/images/logos/check.svg"
                alt="selected"
                aria-hidden={howHear !== 'Online Ad'}
              />
              <input
                name="howHear"
                id="howHear-8"
                type="radio"
                value="Online Ad"
                onChange={(...args) => {
                  this.updateForm(args[0], 6);
                }}
              />
            </SelectLabel>
            <SelectLabel
              ref={this.selectRefFour}
              selected={howHear === 'Online Search'}
              focused={selectArrayOnePos === 3}
              htmlFor="howHear-9"
            >
              <span>Online Search</span>
              <img
                src="/images/logos/check.svg"
                alt="selected"
                aria-hidden={howHear !== 'Online Search'}
              />
              <input
                name="howHear"
                id="howHear-9"
                type="radio"
                value="Online Search"
                onChange={(...args) => {
                  this.updateForm(args[0], 6);
                }}
              />
            </SelectLabel>
            <SelectLabel
              ref={this.selectRefFive}
              selected={howHear === 'Referral'}
              focused={selectArrayOnePos === 4}
              htmlFor="howHear-10"
            >
              <span>Referral</span>
              <img
                src="/images/logos/check.svg"
                alt="selected"
                aria-hidden={howHear !== 'Referral'}
              />
              <input
                name="howHear"
                id="howHear-10"
                type="radio"
                value="Referral"
                onChange={(...args) => {
                  this.updateForm(args[0], 6);
                }}
              />
            </SelectLabel>
            <SelectLabel
              ref={this.selectRefSix}
              selected={howHear === 'Site Signage'}
              focused={selectArrayOnePos === 5}
              htmlFor="howHear-11"
            >
              <span>Site Signage</span>
              <img
                src="/images/logos/check.svg"
                alt="selected"
                aria-hidden={howHear !== 'Site Signage'}
              />
              <input
                name="howHear"
                id="howHear-11"
                type="radio"
                value="Site Signage"
                onChange={(...args) => {
                  this.updateForm(args[0], 6);
                }}
              />
            </SelectLabel>
            <SelectLabel
              ref={this.selectRefSeven}
              selected={howHear === 'StreetEasy.com'}
              focused={selectArrayOnePos === 6}
              htmlFor="howHear-12"
            >
              <span>StreetEasy</span>
              <img
                src="/images/logos/check.svg"
                alt="selected"
                aria-hidden={howHear !== 'StreetEasy.com'}
              />
              <input
                name="howHear"
                id="howHear-12"
                type="radio"
                value="StreetEasy.com"
                onChange={(...args) => {
                  this.updateForm(args[0], 6);
                }}
              />
            </SelectLabel>
            <SelectLabel
              ref={this.selectRefEight}
              selected={howHear === 'Other'}
              focused={selectArrayOnePos === 7}
              htmlFor="howHear-13"
            >
              <span>Other</span>
              <img src="/images/logos/check.svg" alt="selected" aria-hidden={howHear !== 'Other'} />
              <input
                name="howHear"
                id="howHear-13"
                type="radio"
                value="Other"
                onChange={(...args) => {
                  this.updateForm(args[0], 6);
                }}
              />
            </SelectLabel>
          </SelectDiv>
          <AlertRow>
            <RequiredFlag aria-hidden={!errors.howHear} error={errors.howHear}>
              REQUIRED
            </RequiredFlag>
            <PressEnter complete={howHear !== '' && activeElement === 6}>Press enter</PressEnter>
          </AlertRow>
        </FormRow>
        <FormRow ref={this.inputRefEight} active={activeElement === 7}>
          <h5 tabIndex="-1">DESIRED RESIDENCE*</h5>
          <SelectDiv>
            <SelectLabel
              ref={this.selectRefNine}
              selected={residence === '1 bedroom'}
              focused={selectArrayTwoPos === 0}
              htmlFor="residence-1"
            >
              <span>One Bedroom</span>
              <img
                src="/images/logos/check.svg"
                alt="selected"
                aria-hidden={residence !== '1 bedroom'}
              />
              <input
                name="residence"
                id="residence-1"
                type="radio"
                value="1 bedroom"
                onChange={(...args) => {
                  this.updateForm(args[0], 7);
                }}
              />
            </SelectLabel>
            <SelectLabel
              ref={this.selectRefTen}
              selected={residence === '2 bedroom'}
              focused={selectArrayTwoPos === 1}
              htmlFor="residence-2"
            >
              <span>Two Bedroom</span>
              <img
                src="/images/logos/check.svg"
                alt="selected"
                aria-hidden={residence !== '2 bedroom'}
              />
              <input
                name="residence"
                id="residence-2"
                type="radio"
                value="2 bedroom"
                onChange={(...args) => {
                  this.updateForm(args[0], 7);
                }}
              />
            </SelectLabel>
            <SelectLabel
              ref={this.selectRefEleven}
              selected={residence === '3 bedroom'}
              focused={selectArrayTwoPos === 2}
              htmlFor="residence-3"
            >
              <span>Three Bedroom</span>
              <img
                src="/images/logos/check.svg"
                alt="selected"
                aria-hidden={residence !== '3 bedroom'}
              />
              <input
                name="residence"
                id="residence-3"
                type="radio"
                value="3 bedroom"
                onChange={(...args) => {
                  this.updateForm(args[0], 7);
                }}
              />
            </SelectLabel>
            <SelectLabel
              ref={this.selectRefTwelve}
              selected={residence === '4 bedroom'}
              focused={selectArrayTwoPos === 3}
              htmlFor="residence-4"
            >
              <span>Four Bedroom</span>
              <img
                src="/images/logos/check.svg"
                alt="selected"
                aria-hidden={residence !== '4 bedroom'}
              />
              <input
                name="residence"
                id="residence-4"
                type="radio"
                value="4 bedroom"
                onChange={(...args) => {
                  this.updateForm(args[0], 7);
                }}
              />
            </SelectLabel>
            <SelectLabel
              ref={this.selectRefThirteen}
              selected={residence === 'Penthouse'}
              focused={selectArrayTwoPos === 4}
              htmlFor="residence-5"
            >
              <span>Penthouse</span>
              <img
                src="/images/logos/check.svg"
                alt="selected"
                aria-hidden={howHear !== 'Penthouse'}
              />
              <input
                name="residence"
                id="residence-5"
                type="radio"
                value="Penthouse"
                onChange={(...args) => {
                  this.updateForm(args[0], 7);
                }}
              />
            </SelectLabel>
          </SelectDiv>
          <AlertRow>
            <RequiredFlag aria-hidden={!errors.residence} error={errors.residence}>
              REQUIRED
            </RequiredFlag>
            <PressEnter complete={residence !== '' && activeElement === 7}>Press enter</PressEnter>
          </AlertRow>
        </FormRow>
        <FormRow ref={this.inputRefNine} active={activeElement === 8}>
          <h5 tabIndex="-1">PRICED FROM*</h5>
          <SelectDiv>
            <SelectLabel
              ref={this.selectRefFourteen}
              selected={priceRange === '$1-$3 million'}
              focused={selectArrayThreePos === 0}
              htmlFor="priceRange-1"
            >
              <span>$1-3M</span>
              <img
                src="/images/logos/check.svg"
                alt="selected"
                aria-hidden={priceRange !== '$1-$3 million'}
              />
              <input
                name="priceRange"
                id="priceRange-1"
                type="radio"
                value="$1-$3 million"
                onChange={(...args) => {
                  this.updateForm(args[0], 8);
                }}
              />
            </SelectLabel>
            <SelectLabel
              ref={this.selectRefFifteen}
              selected={priceRange === '$3-$5 million'}
              focused={selectArrayThreePos === 1}
              htmlFor="priceRange-3"
            >
              <span>$3-5M</span>
              <img
                src="/images/logos/check.svg"
                alt="selected"
                aria-hidden={priceRange !== '$3-$5 million'}
              />
              <input
                name="priceRange"
                id="priceRange-3"
                type="radio"
                value="$3-$5 million"
                onChange={(...args) => {
                  this.updateForm(args[0], 8);
                }}
              />
            </SelectLabel>
            <SelectLabel
              ref={this.selectRefSixteen}
              selected={priceRange === '$5-$7 million'}
              focused={selectArrayThreePos === 2}
              htmlFor="priceRange-4"
            >
              <span>$5-7M</span>
              <img
                src="/images/logos/check.svg"
                alt="selected"
                aria-hidden={priceRange !== '$5-$7 million'}
              />
              <input
                name="priceRange"
                id="priceRange-4"
                type="radio"
                value="$5-$7 million"
                onChange={(...args) => {
                  this.updateForm(args[0], 8);
                }}
              />
            </SelectLabel>
            <SelectLabel
              ref={this.selectRefSeventeen}
              selected={priceRange === '$7-$10 million'}
              focused={selectArrayThreePos === 3}
              htmlFor="priceRange-5"
            >
              <span>$7-10 M</span>
              <img
                src="/images/logos/check.svg"
                alt="selected"
                aria-hidden={howHear !== '$7-$10 million'}
              />
              <input
                name="priceRange"
                id="priceRange-5"
                type="radio"
                value="$7-$10 million"
                onChange={(...args) => {
                  this.updateForm(args[0], 8);
                }}
              />
            </SelectLabel>
            <SelectLabel
              ref={this.selectRefEighteen}
              selected={priceRange === '$10-$15 million'}
              focused={selectArrayThreePos === 4}
              htmlFor="priceRange-6"
            >
              <span>$10-15 M</span>
              <img
                src="/images/logos/check.svg"
                alt="selected"
                aria-hidden={howHear !== '$10-$15 million'}
              />
              <input
                name="priceRange"
                id="priceRange-6"
                type="radio"
                value="$10-$15 million"
                onChange={(...args) => {
                  this.updateForm(args[0], 8);
                }}
              />
            </SelectLabel>
            <SelectLabel
              ref={this.selectRefNineteen}
              selected={priceRange === '$15-$20 million'}
              focused={selectArrayThreePos === 5}
              htmlFor="priceRange-7"
            >
              <span>$15-20 M</span>
              <img
                src="/images/logos/check.svg"
                alt="selected"
                aria-hidden={howHear !== '$15-$20 million'}
              />
              <input
                name="priceRange"
                id="priceRange-7"
                type="radio"
                value="$15-$20 million"
                onChange={(...args) => {
                  this.updateForm(args[0], 8);
                }}
              />
            </SelectLabel>
            <SelectLabel
              ref={this.selectRefTwenty}
              selected={priceRange === '$20 million +'}
              focused={selectArrayThreePos === 6}
              htmlFor="priceRange-8"
            >
              <span>$20M+</span>
              <img
                src="/images/logos/check.svg"
                alt="selected"
                aria-hidden={howHear !== '$20 million +'}
              />
              <input
                name="priceRange"
                id="priceRange-8"
                type="radio"
                value="$20 million +"
                onChange={(...args) => {
                  this.updateForm(args[0], 8);
                }}
              />
            </SelectLabel>
          </SelectDiv>
          <AlertRow>
            <RequiredFlag aria-hidden={!errors.priceRange} error={errors.priceRange}>
              REQUIRED
            </RequiredFlag>
            <PressEnter complete={priceRange !== '' && activeElement === 8}>Press enter</PressEnter>
          </AlertRow>
        </FormRow>
        {/* IF BUYER */}
        {JSON.parse(broker) ? (
          ''
        ) : (
          <>
            <FormRow ref={this.inputRefTen} active={activeElement === 9}>
              <h5 tabIndex="-1">ARE YOU CURRENTLY REPRESENTED BY A BROKER?*</h5>
              <RadioDiv>
                <label className="input-label" htmlFor="represented-yes">
                  Yes
                  <input
                    name="represented"
                    id="represented-yes"
                    type="radio"
                    value
                    checked={JSON.parse(represented) === true}
                    className="radio"
                    onChange={(...args) => {
                      this.updateForm(args[0], 9);
                    }}
                  />
                </label>
                <label className="input-label" htmlFor="represented-no">
                  No
                  <input
                    name="represented"
                    id="represented-no"
                    type="radio"
                    value={false}
                    checked={JSON.parse(represented) === false}
                    className="radio"
                    onChange={(...args) => {
                      this.updateForm(args[0], 9);
                    }}
                  />
                </label>
              </RadioDiv>
              <AlertRow>
                <RequiredFlag aria-hidden={!errors.represented} error={errors.represented}>
                  REQUIRED
                </RequiredFlag>
                <PressEnter complete={represented !== null && activeElement === 9}>
                  Press enter
                </PressEnter>
              </AlertRow>
            </FormRow>
            {/* IF REPRESENTED BY BROKER */}
            {JSON.parse(represented) ? (
              <>
                <FormRow ref={this.inputRefEleven} active={activeElement === 10}>
                  <label className="input-label" htmlFor="brokerName">
                    BROKER FIRST + LAST NAME*
                    <input
                      name="brokerName"
                      id="brokerName"
                      type="text"
                      value={brokerName}
                      onChange={(...args) => {
                        this.updateForm(args[0]);
                      }}
                      onFocus={() => this.scrollToItem(10)}
                    />
                  </label>
                  <AlertRow>
                    <RequiredFlag aria-hidden={!errors.brokerName} error={errors.brokerName}>
                      REQUIRED
                    </RequiredFlag>
                    <PressEnter complete={brokerName !== '' && activeElement === 10}>
                      Press enter
                    </PressEnter>
                  </AlertRow>
                </FormRow>
                <FormRow ref={this.inputRefTwelve} active={activeElement === 11}>
                  <label className="input-label" htmlFor="brokerFirm">
                    BROKERAGE FIRM*
                    <AutoComplete
                      name="brokerFirm"
                      id="brokerFirm"
                      type="text"
                      value={brokerFirm}
                      onChangeCallback={(...args) => {
                        this.updateForm(args[0]);
                      }}
                      onFocus={() => this.scrollToItem(11)}
                    />
                  </label>
                  <AlertRow>
                    <RequiredFlag aria-hidden={!errors.brokerFirm} error={errors.brokerFirm}>
                      REQUIRED
                    </RequiredFlag>
                    <PressEnter complete={brokerFirm !== '' && activeElement === 11}>
                      Press enter
                    </PressEnter>
                  </AlertRow>
                </FormRow>
              </>
            ) : (
              ''
            )}
          </>
        )}
        <button type="submit">SUBMIT</button>
        <AlertRow bottom>
          <RequiredFlag
            aria-hidden={!Object.values(errors).some((el) => el)}
            error={Object.values(errors).some((el) => el)}
            large
          >
            *FIELDS ARE REQUIRED
          </RequiredFlag>
        </AlertRow>
      </RegisterForm>
    );
  }
}
