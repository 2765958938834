const floorplanData = {
  // T1
  '16A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitA_Downloadable.pdf',
  },
  '16B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitB_Downloadable.pdf',
  },
  '16C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitC_Downloadable.pdf',
  },
  '16D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-D_16_v2.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitD_16_Downloadable_v2.pdf',
  },
  '16E': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-E.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-E.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitE_Downloadable.pdf',
  },
  '17A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitA_Downloadable.pdf',
  },
  '17B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitB_Downloadable.pdf',
  },
  '17C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitC_Downloadable.pdf',
  },
  '17D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitD_Downloadable.pdf',
  },
  '17E': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-E.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-E.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitE_Downloadable.pdf',
  },
  '18A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitA_Downloadable.pdf',
  },
  '18B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitB_Downloadable.pdf',
  },
  '18C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitC_Downloadable.pdf',
  },
  '18D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitD_Downloadable.pdf',
  },
  '18E': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-E.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-E.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitE_Downloadable.pdf',
  },
  '19A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitA_Downloadable.pdf',
  },
  '19B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitB_Downloadable.pdf',
  },
  '19C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitC_Downloadable.pdf',
  },
  '19D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-D_Den.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitD_Den_Downloadable.pdf',
  },
  '19E': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-E.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-E.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitE_Downloadable.pdf',
  },
  '20A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitA_Downloadable.pdf',
  },
  '20B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitB_Downloadable.pdf',
  },
  '20C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitC_Downloadable.pdf',
  },
  '20D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitD_Downloadable.pdf',
  },
  '20E': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-E.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-E.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitE_Downloadable.pdf',
  },
  '21A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitA_Downloadable.pdf',
  },
  '21B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitB_Downloadable.pdf',
  },
  '21C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitC_Downloadable.pdf',
  },
  '21D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitD_Downloadable.pdf',
  },
  '21E': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-E.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-E.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitE_Downloadable.pdf',
  },
  '22A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitA_Downloadable.pdf',
  },
  '22B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitB_Downloadable.pdf',
  },
  '22C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitC_Downloadable.pdf',
  },
  '22D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitD_Downloadable.pdf',
  },
  '22E': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-E.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-E.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitE_Downloadable.pdf',
  },
  '23A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitA_Downloadable.pdf',
  },
  '23B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitB_Downloadable.pdf',
  },
  '23C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitC_Downloadable.pdf',
  },
  '23D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitD_Downloadable.pdf',
  },
  '23E': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-E.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-E.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitE_Downloadable.pdf',
  },
  '24A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitA_Downloadable.pdf',
  },
  '24B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitB_Downloadable.pdf',
  },
  '24C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitC_Downloadable.pdf',
  },
  '24D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitD_Downloadable.pdf',
  },
  '24E': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-E.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-E.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitE_Downloadable.pdf',
  },
  '25A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitA_Downloadable.pdf',
  },
  '25B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitB_Downloadable.pdf',
  },
  '25C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitC_Downloadable.pdf',
  },
  '25D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitD_Downloadable.pdf',
  },
  '25E': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-E.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-E.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitE_Downloadable.pdf',
  },
  '26A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitA_Downloadable.pdf',
  },
  '26B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitB_Downloadable.pdf',
  },
  '26C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitC_Downloadable.pdf',
  },
  '26D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitD_Downloadable.pdf',
  },
  '26E': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-E.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-E.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitE_Downloadable.pdf',
  },
  '27A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitA_Downloadable.pdf',
  },
  '27B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitB_Downloadable.pdf',
  },
  '27C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitC_Downloadable.pdf',
  },
  '27D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitD_Downloadable.pdf',
  },
  '27E': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-E.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-E.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitE_Downloadable.pdf',
  },
  '28A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitA_Downloadable.pdf',
  },
  '28B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitB_Downloadable.pdf',
  },
  '28C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitC_Downloadable.pdf',
  },
  '28D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitD_Downloadable.pdf',
  },
  '28E': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-E.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-E.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitE_Downloadable.pdf',
  },
  '29A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitA_Downloadable.pdf',
  },
  '29B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitB_Downloadable.pdf',
  },
  '29C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitC_Downloadable.pdf',
  },
  '29D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitD_Downloadable.pdf',
  },
  '29E': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-E.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-E.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitE_Downloadable.pdf',
  },
  '30A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitA_Downloadable.pdf',
  },
  '30B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitB_Downloadable.pdf',
  },
  '30C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitC_Downloadable.pdf',
  },
  '30D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitD_Downloadable.pdf',
  },
  '30E': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-E.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-E.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitE_Downloadable.pdf',
  },
  '31A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitA_Downloadable.pdf',
  },
  '31B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitB_Downloadable.pdf',
  },
  '31C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitC_Downloadable.pdf',
  },
  '31D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitD_Downloadable.pdf',
  },
  '31E': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T1-E.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T1-E.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T1_UnitE_Downloadable.pdf',
  },
  // T2
  '32A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitA_Downloadable.pdf',
  },
  '32B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitB_Downloadable.pdf',
  },
  '32C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitC_Den_Downloadable.pdf',
  },
  '32D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-D_Den.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitD_Den_Downloadable.pdf',
  },
  '32E': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-E.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-E.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitE_Downloadable.pdf',
  },
  '33A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitA_Downloadable.pdf',
  },
  '33B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitB_Downloadable.pdf',
  },
  '33C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitC_Den_Downloadable.pdf',
  },
  '33D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitD_Den_Downloadable.pdf',
  },
  '33E': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-E.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-E.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitE_Downloadable.pdf',
  },
  '34A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitA_Downloadable.pdf',
  },
  '34B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitB_Downloadable.pdf',
  },
  '34C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-C_Den.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitC_Den_Downloadable.pdf',
  },
  '34D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitD_Den_Downloadable.pdf',
  },
  '34E': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-E.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-E.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitE_Downloadable.pdf',
  },
  '35A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitA_Downloadable.pdf',
  },
  '35B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitB_Downloadable.pdf',
  },
  '35C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitC_Den_Downloadable.pdf',
  },
  '35D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitD_Den_Downloadable.pdf',
  },
  '35E': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-E.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-E.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitE_Downloadable.pdf',
  },
  '36A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitA_Downloadable.pdf',
  },
  '36B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitB_Downloadable.pdf',
  },
  '36C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitC_Den_Downloadable.pdf',
  },
  '36D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitD_Den_Downloadable.pdf',
  },
  '36E': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-E.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-E.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitE_Downloadable.pdf',
  },
  '37A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitA_Downloadable.pdf',
  },
  '37B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitB_Downloadable.pdf',
  },
  '37C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitC_Den_Downloadable.pdf',
  },
  '37D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitD_Den_Downloadable.pdf',
  },
  '37E': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-E.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-E.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitE_Downloadable.pdf',
  },
  '38A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitA_Downloadable.pdf',
  },
  '38B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitB_Downloadable.pdf',
  },
  '38C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitC_Den_Downloadable.pdf',
  },
  '38D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitD_Den_Downloadable.pdf',
  },
  '38E': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-E.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-E.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitE_Downloadable.pdf',
  },
  '39A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitA_Downloadable.pdf',
  },
  '39B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitB_Downloadable.pdf',
  },
  '39C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitC_Den_Downloadable.pdf',
  },
  '39D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitD_Den_Downloadable.pdf',
  },
  '39E': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-E.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-E.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitE_Downloadable.pdf',
  },
  '40A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitA_Downloadable.pdf',
  },
  '40B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitB_Downloadable.pdf',
  },
  '40C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitC_Den_Downloadable.pdf',
  },
  '40D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitD_Den_Downloadable.pdf',
  },
  '40E': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-E.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-E.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitE_Downloadable.pdf',
  },
  '41A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitA_Downloadable.pdf',
  },
  '41B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitB_Downloadable.pdf',
  },
  '41C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitC_Den_Downloadable.pdf',
  },
  '41D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitD_Den_Downloadable.pdf',
  },
  '41E': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-E.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-E.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitE_Downloadable.pdf',
  },
  '42A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitA_Downloadable.pdf',
  },
  '42B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitB_Downloadable.pdf',
  },
  '42C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitC_Den_Downloadable.pdf',
  },
  '42D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitD_Den_Downloadable.pdf',
  },
  '42E': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-E.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-E.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitE_Downloadable.pdf',
  },
  '43A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitA_Downloadable.pdf',
  },
  '43B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitB_Downloadable.pdf',
  },
  '43C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitC_Den_Downloadable.pdf',
  },
  '43D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitD_Den_Downloadable.pdf',
  },
  '43E': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-E.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-E.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitE_Downloadable.pdf',
  },
  '44A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitA_Downloadable.pdf',
  },
  '44B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitB_Downloadable.pdf',
  },
  '44C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitC_Den_Downloadable.pdf',
  },
  '44D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitD_Den_Downloadable.pdf',
  },
  '44E': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-E.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-E.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitE_Downloadable.pdf',
  },
  '45A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitA_Downloadable.pdf',
  },
  '45B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitB_Downloadable.pdf',
  },
  '45C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitC_Den_Downloadable.pdf',
  },
  '45D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitD_Den_Downloadable.pdf',
  },
  '45E': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-E.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-E.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitE_Downloadable.pdf',
  },
  '46A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitA_Downloadable.pdf',
  },
  '46B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitB_Downloadable.pdf',
  },
  '46C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitC_Den_Downloadable.pdf',
  },
  '46D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitD_Den_Downloadable.pdf',
  },
  '46E': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T2-E.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T2-E.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T2_UnitE_Downloadable.pdf',
  },
  // T3
  '47A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T3-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T3-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T3_UnitA_Downloadable.pdf',
  },
  '47B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T3-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T3-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T3_UnitB_Downloadable.pdf',
  },
  '47C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T3-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T3-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T3_UnitC_Downloadable.pdf',
  },
  '47D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T3-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T3-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T3_UnitD_Downloadable.pdf',
  },
  '48A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T3-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T3-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T3_UnitA_Downloadable.pdf',
  },
  '48B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T3-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T3-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T3_UnitB_Downloadable.pdf',
  },
  '48C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T3-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T3-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T3_UnitC_Downloadable.pdf',
  },
  '48D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T3-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T3-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T3_UnitD_Downloadable.pdf',
  },
  '49A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T3-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T3-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T3_UnitA_Downloadable.pdf',
  },
  '49B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T3-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T3-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T3_UnitB_Downloadable.pdf',
  },
  '49C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T3-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T3-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T3_UnitC_Downloadable.pdf',
  },
  '49D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T3-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T3-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T3_UnitD_Downloadable.pdf',
  },
  '50A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T3-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T3-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T3_UnitA_Downloadable.pdf',
  },
  '50B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T3-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T3-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T3_UnitB_Downloadable.pdf',
  },
  '50C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T3-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T3-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T3_UnitC_Downloadable.pdf',
  },
  '50D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T3-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T3-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T3_UnitD_Downloadable.pdf',
  },
  '51A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T3-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T3-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T3_UnitA_Downloadable.pdf',
  },
  '51B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T3-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T3-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T3_UnitB_Downloadable.pdf',
  },
  '51C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T3-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T3-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T3_UnitC_Downloadable.pdf',
  },
  '51D': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T3-D.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T3-D.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T3_UnitD_Downloadable.pdf',
  },
  // T4
  '52A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T4-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T4-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T4_UnitA_Downloadable.pdf',
  },
  '52B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T4-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T4-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T4_UnitB_Downloadable.pdf',
  },
  '52C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T4-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T4-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T4_UnitC_Downloadable.pdf',
  },
  '53A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T4-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T4-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T4_UnitA_Downloadable.pdf',
  },
  '53B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T4-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T4-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T4_UnitB_Downloadable.pdf',
  },
  '53C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T4-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T4-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T4_UnitC_Downloadable.pdf',
  },
  '54A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T4-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T4-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T4_UnitA_Downloadable.pdf',
  },
  '54B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T4-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T4-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T4_UnitB_Downloadable.pdf',
  },
  '54C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T4-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T4-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T4_UnitC_Downloadable.pdf',
  },
  '55A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T4-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T4-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T4_UnitA_Downloadable.pdf',
  },
  '55B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T4-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T4-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T4_UnitB_Downloadable.pdf',
  },
  '55C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T4-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T4-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T4_UnitC_Downloadable.pdf',
  },
  '56A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T4-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T4-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T4_UnitA_Downloadable.pdf',
  },
  '56B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T4-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T4-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T4_UnitB_Downloadable.pdf',
  },
  '56C': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T4-C.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T4-C.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T4_UnitC_Downloadable.pdf',
  },
  // T5
  '57A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T5-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T5-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T5_UnitA_Downloadable.pdf',
  },
  '57B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T5-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T5-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T5_UnitB_Downloadable.pdf',
  },
  '58A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T5-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T5-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T5_UnitA_Downloadable.pdf',
  },
  '58B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T5-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T5-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T5_UnitB_Downloadable.pdf',
  },
  '59A': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T5-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T5-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T5_UnitA_Downloadable.pdf',
  },
  '59B': {
    floorplan: '/images/availability/floorplans/MH_FP_web_T5-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_T5-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_T5_UnitB_Downloadable.pdf',
  },
  PH60A: {
    floorplan: '/images/availability/floorplans/MH_FP_web_PH60-A.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_PH60-A.svg',
    download: '/images/availability/downloads/MADISONHOUSE_PH60A_Downloadable.pdf',
  },
  PH60B: {
    floorplan: '/images/availability/floorplans/MH_FP_web_PH60-B.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_PH60-B.svg',
    download: '/images/availability/downloads/MADISONHOUSE_PH60B_Downloadable.pdf',
  },
  PH62: {
    floorplan: '/images/availability/floorplans/MH_FP_web_PH62.svg',
    keyplan: '/images/availability/keyplans/DBOX_MH_keyplan_PH62.svg',
    download: '/images/availability/downloads/MADISONHOUSE_PH62_Downloadable.pdf',
  },
};

export default floorplanData;
