import { createGlobalStyle } from 'styled-components';

const ReactPlayerStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html, body, #root, .App {
    width: 100%;
    height: 100%;
  }
  body, #root {
    margin: 0;
    padding: 0;
  }
  .modal-player-wrapper {
    position: relative;
    padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
  }

  .modal-react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export default ReactPlayerStyles;
