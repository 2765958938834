const variables = {
  colors: {
    darkRed: '#A84337',
    brightRed: '#EE5B5B',
    lightGray: '#F3F4F4',
    darkGray: '#1A1919'
  }
};

export default variables;
