/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import { Helmet } from 'react-helmet';

import Context from '../config/Context';
import { mediaMin } from '../styles/MediaQueries';
import variables from '../styles/Variables';
import { HeaderLogo, MobilePageTitle } from '../components/utils/page-header';
import LoadingSpinner from '../components/utils/loading-spinner';
import FetchError from '../components/utils/fetch-error';
import { truncateSummary, parseDateforScreenReader } from '../components/utils/press_utils';
import PageNavigation from '../components/utils/page-navigation';

const MainColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  min-height: 100vh;
  z-index: 0;
`;

const PressRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 36px;
  margin: 0 0 64px 0;
  ${mediaMin.tabletLandscape`
  margin: 80px 0;
  padding: 0 20%;
  `}
`;

const PressItem = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${variables.colors.lightGray};
  padding: 10%;
  box-sizing: border-box;
  width: 100%;
  margin: 8px 0;
  ${mediaMin.tabletLandscape`
  width: calc(50% - 16px);
  margin: 8px;
  `}
  .press_publication {
    margin: 0 0 32px 0;
    font-size: 24px;
    line-height: 24px;
    font-family: Granjon;
    color: ${variables.colors.darkRed};
    ${mediaMin.tabletLandscape`
    font-size: 32px;
    line-height: 32px;
    `}
  }
  .press_date {
    margin: 0 0 32px 0;
    font-size: 20px;
    line-height: 28px;
    font-family: Granjon;
  }
  .press_summary {
    font-family: Granjon;
    margin: 0 0 32px 0;
    font-size: 16px;
    line-height: 22px;
  }
  .press_link {
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 16px;
    color: ${variables.colors.darkRed};
    &:visited {
      color: ${variables.colors.darkRed};
    }
    &:hover {
      text-decoration: none;
    }
  }
`;

const renderPressData = (pressData) => {
  const pressItems = pressData.map((el) => (
    <PressItem key={el.id}>
      <h5 className="press_publication">{el.publication_name.toUpperCase()}</h5>
      <span className="press_date" aria-label={parseDateforScreenReader(el.publication_date)}>
        {el.publication_date}
      </span>
      <p className="press_summary">{truncateSummary(el.press_summary)}</p>
      <a
        href={el.press_link || el.press_pdf}
        className="press_link"
        target="_blank"
        rel="noopener noreferrer"
      >
        READ MORE
      </a>
    </PressItem>
  ));
  return <PressRow>{pressItems}</PressRow>;
};

const selectPressComponent = (pressData) => {
  switch (pressData) {
    case null:
      return <LoadingSpinner />;
    case 'ERROR':
      return <FetchError page="PRESS" />;
    default:
      return renderPressData(pressData);
  }
};

const Press = () => {
  const context = useContext(Context);

  const { pressData, fetchPressData } = context;

  useEffect(() => {
    if (pressData === null) {
      fetchPressData();
    }
  }, []);

  const renderedPressData = selectPressComponent(pressData);

  return (
    <>
      <Helmet>
        <title>NoMad New Development News | Madison House</title>
      </Helmet>
      <MainColumn>
        <HeaderLogo />
        <MobilePageTitle>PRESS</MobilePageTitle>
        <Fade cascade>{renderedPressData}</Fade>
        <PageNavigation
          prevPage="GALLERY"
          prevPath="/gallery"
          nextPage="CONTACT"
          nextPath="/contact-madison-house"
        />
      </MainColumn>
    </>
  );
};

export default Press;
