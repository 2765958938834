import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import $ from 'jquery';

import Context from '../../config/Context';
import validateEmail from '../../components/utils/validateEmail';
import variables from '../../styles/Variables';
import { mediaMin } from '../../styles/MediaQueries';

const DownloadContainer = styled.div`
  width: 100%;
  max-width: 320px;
  margin: 36px 0;
  ${mediaMin.tabletLandscape`
    margin: 3vh 0 0 0;
  `}
  .row {
    display: flex;
  }
`;

const DownloadButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${(props) => (props.visible ? '1' : '0')};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  transition: opacity 0.5s ease, visibility 0.5s ease;
  .download {
    width: fit-content;
    background-color: ${variables.colors.darkRed};
    color: #fff;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 16px;
    padding: 8px 16px;
    margin: 8px 0 0 0;
    text-align: center;
    &:hover {
      text-decoration: none;
    }
  }
`;

const EmailForm = styled.form`
  display: flex;
  flex-direction: column;
  opacity: ${(props) => (props.visible ? '1' : '0')};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  transition: opacity 0.5s ease, visibility 0.5s ease;
  input {
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 16px;
    display: flex;
    flex-grow: 1;
    height: 32px;
    padding: 0 8px;
    border-radius: 0;
    border: ${(props) =>
      props.error ? `1px solid ${variables.colors.darkRed}` : '1px solid #000'};
  }
  button {
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 16px;
    margin-left: 4px;
    height: 32px;
    background-color: ${variables.colors.darkRed};
    color: #fff;
    border: none;
    padding: 0 16px;
    cursor: pointer;
  }
`;

const P = styled.p`
  opacity: ${(props) => (props.visible ? '1' : '0')};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  transition: opacity 0.5s ease, visibility 0.5s ease;
  font-family: 'Granjon';
  font-size: 17px;
  line-height: 22px;
  margin: 0;
`;

const DownloadButton = ({ currentFloorplans }) => {
  const { download } = currentFloorplans;
  const context = useContext(Context);
  const {
    emailSubmitted,
    setEmailSubmitted,
    emailFormMessageVisable,
    setEmailFormMessageVisable,
  } = context;
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [visible, setVisible] = useState(true);

  const animate = () => {
    setVisible(false);
    setTimeout(() => {
      setEmailSubmitted(true);
    }, 500);
    setTimeout(() => {
      setVisible(true);
    }, 750);
    setTimeout(() => {
      setEmailFormMessageVisable(false);
    }, 5000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    animate();

    if (validateEmail(email)) {
      $.ajax({
        url: 'https://form.api.dbxd.com/post-spark-form/',
        type: 'POST',
        dataType: 'json',
        data: {
          projectname: 'madisonhousenyc',
          contact: {
            email,
          },
        },
        crossDomain: true,
        success: (/* res  textStatus, jqXHR */) => {
          console.log('success - form submitted');
          animate();
        },
        error: (jqXHR, textStatus, errorThrown) => {
          console.log('http request failed', errorThrown);
        },
      });
    } else {
      setEmailError(true);
      console.log('error');
    }
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <DownloadContainer>
      {emailSubmitted ? (
        <DownloadButtonContainer visible={visible}>
          <P visible={emailFormMessageVisable}>Thank you for providing your email!</P>
          <a className="download" href={download} download>
            DOWNLOAD PLAN
          </a>
        </DownloadButtonContainer>
      ) : (
        <EmailForm onSubmit={handleSubmit} visible={visible} error={emailError}>
          <P visible>Please provide your email to download:</P>
          <div className="row">
            <input
              id="email"
              name="email"
              type="email"
              placeholder="EMAIL ADDRESS"
              onChange={handleChange}
            />
            <button type="submit">SUBMIT</button>
          </div>
        </EmailForm>
      )}
    </DownloadContainer>
  );
};

export default DownloadButton;
