/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import {
  SFtoSM,
  parseOrientation,
  numberWithCommas,
  removeLetters,
  parseAdditionalFields
} from '../components/availability/listing-utils';
import LoadingSpinner from '../components/utils/loading-spinner';
import DownloadButton from '../components/listing/DownloadButton';
import Context from '../config/Context';
import variables from '../styles/Variables';
import { mediaMin } from '../styles/MediaQueries';
import floorplanData from '../data/floorplans';

const DesktopListingContainer = styled.div`
  display: none;
  ${mediaMin.tabletLandscape`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    padding: 24px 44px;
  `}
`;

const MobileListingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 120px 36px 0 36px;
  ${mediaMin.tabletLandscape`
    display: none;
  `}
  .residence-column {
    display: flex;
    flex-direction: column;
    color: ${variables.colors.darkRed};
    margin-bottom: 48px;
    .residence-title {
      font-size: 16px;
      letter-spacing: 2px;
      line-height: 16px;
    }
    .residence-number {
      font-family: AWConquerorProDidotL-Regular;
      font-variant-numeric: lining-nums;
      font-size: 64px;
      line-height: 64px;
    }
  }
  .bottom-row {
    display: flex;
    flex-direction: row;
    .fact-column {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 50%;
      .fact-section {
        display: flex;
        flex-direction: column;
        margin: 0 0 3vh 0;
        .fact {
          color: ${variables.colors.darkGray};
          font-size: 12px;
          letter-spacing: 1.5px;
          line-height: 16px;
        }
        .gray {
          opacity: 0.5;
        }
      }
    }
    .keyplan-column {
      width: 50%;
      padding: 5%;
    }
  }
`;

const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 100%;
  img {
    width: fit-content;
  }
  .residence-column {
    display: flex;
    flex-direction: column;
    color: ${variables.colors.darkRed};
    margin: 8vh 0 6vh 0;
    .residence-title {
      font-size: 16px;
      letter-spacing: 2px;
      line-height: 16px;
    }
    .residence-number {
      font-family: AWConquerorProDidotL-Regular;
      font-variant-numeric: lining-nums;
      font-size: 64px;
      line-height: 64px;
    }
  }
  .fact-column {
    display: flex;
    flex-direction: column;
    .fact-section {
      display: flex;
      flex-direction: column;
      margin: 0 0 3vh 0;
      .fact {
        color: ${variables.colors.darkGray};
        font-size: 12px;
        letter-spacing: 1.5px;
        line-height: 16px;
      }
      .gray {
        opacity: 0.5;
      }
    }
  }

  .keyplan {
    height: 25%;
    margin: auto 0 0 0;
  }
`;

const FloorplanColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 48px;
  img {
    max-width: 100%;
  }
  ${mediaMin.tabletLandscape`
    margin-bottom: 0;
    padding: 2.5%;
    width: 75%;
    height: 100%;
  `}
`;

const CloseRow = styled.div`
  position: fixed;
  top: 75px;
  right: 36px;
  ${mediaMin.tabletLandscape`
    top: 24px;
    right: 44px;
  `}
  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover {
      text-decoration: none;
    }
    span {
      font-size: 16px;
      letter-spacing: 2px;
      line-height: 16px;
      color: ${variables.colors.darkRed};
      margin-right: 16px;
    }
  }
`;

const Listing = ({ match }) => {
  const context = useContext(Context);
  const { availabilityData, fetchAvailabilityData } = context;
  const { params } = match;
  const [currentListing, setCurrentListing] = useState(false);
  const [listingExists, setListingExists] = useState(true);
  const [currentFloorplans, setCurrentFloorplans] = useState({});

  useEffect(() => {
    if (availabilityData === null) {
      fetchAvailabilityData();
    }
  }, []);

  useEffect(() => {
    if (availabilityData) {
      setCurrentListing(availabilityData[params.idx]);
      setListingExists(!!availabilityData[params.idx]);
    }
  }, [availabilityData]);

  useEffect(() => {
    if (currentListing) {
      setCurrentFloorplans(floorplanData[currentListing.unit]);
    }
  }, [currentListing]);

  return listingExists ? (
    currentListing ? (
      <>
        <Helmet>
          <title>New Luxury Condominiums in NoMad | Madison House</title>
          <meta
            name="description"
            content="1-4 Bedroom residences, Madison House offers a double-height attended lobby and 30,000 square feet of amenities."
          />
        </Helmet>
        <DesktopListingContainer>
          <CloseRow>
            <Link to="/nomad-new-condominium-availability">
              <span>ALL LISTINGS</span>
              <img src="/images/logos/close.svg" alt="back to availability list" />
            </Link>
          </CloseRow>
          <InfoColumn>
            <Link to="/">
              <img src="/images/logos/madison-house-gray.svg" alt="madison house logo" />
            </Link>
            <div className="residence-column">
              <span className="residence-title">RESIDENCE</span>
              <span className="residence-number">{currentListing.unit}</span>
            </div>
            <div className="fact-column">
              <div className="fact-section">
                <span className="fact">
                  {parseInt(removeLetters(currentListing.bedrooms), 10)}
                  {parseInt(removeLetters(currentListing.bedrooms), 10) > 1
                    ? ' BEDROOMS'
                    : ' BEDROOM'}
                </span>
                <span className="fact">
                  {removeLetters(currentListing.bathrooms)}
                  {removeLetters(currentListing.bathrooms) > 1 ? ' BATHS' : ' BATH'}
                </span>
                <span className="fact">
                  {`${currentListing.area} SF / ${SFtoSM(currentListing.area)} SM`}
                </span>
              </div>
              <div className="fact-section">
                <span className="fact">EXPOSURES:</span>
                <span className="fact">{parseOrientation(currentListing.exposures)}</span>
              </div>
              <div className="fact-section">
                <span className="fact">{`$${numberWithCommas(currentListing.price)}`}</span>
                <span className="fact gray">
                  {`${parseAdditionalFields(
                    currentListing.additional_fields,
                    'Common Charges'
                  )} EST. C.C.`}
                </span>
                <span className="fact gray">
                  {`${parseAdditionalFields(
                    currentListing.additional_fields,
                    'RE Taxes'
                  )} EST R.E. TAXES`}
                </span>
              </div>
              {currentFloorplans && <DownloadButton currentFloorplans={currentFloorplans} />}
            </div>
            {currentFloorplans && (
              <img
                className="keyplan"
                src={currentFloorplans.keyplan}
                alt={`${currentListing.unit_num} Keyplan`}
              />
            )}
          </InfoColumn>
          <FloorplanColumn>
            {currentFloorplans ? (
              <img
                className="floorplan"
                src={currentFloorplans.floorplan}
                alt={`${currentFloorplans.unit_num} Floorplan`}
              />
            ) : (
              <h5>Floorplan Unavailabile</h5>
            )}
          </FloorplanColumn>
        </DesktopListingContainer>
        <MobileListingContainer>
          <CloseRow>
            <Link to="/nomad-new-condominium-availability">
              <img src="/images/logos/close.svg" alt="back to availability list" />
            </Link>
          </CloseRow>
          <div className="residence-column">
            <span className="residence-title">RESIDENCE</span>
            <span className="residence-number">{currentListing.unit}</span>
          </div>
          <FloorplanColumn>
            {currentFloorplans ? (
              <img
                className="floorplan"
                src={currentFloorplans.floorplan}
                alt={`${currentFloorplans.unit_num} Floorplan`}
              />
            ) : (
              <h5>Floorplan Unavailabile</h5>
            )}
          </FloorplanColumn>
          <div className="bottom-row">
            <div className="fact-column">
              <div className="fact-section">
                <span className="fact">
                  {`${parseInt(removeLetters(currentListing.bedrooms), 10)} ${
                    parseInt(removeLetters(currentListing.bedrooms), 10) > 1
                      ? 'BEDROOMS'
                      : 'BEDROOM'
                  }`}
                </span>
                <span className="fact">
                  {`${removeLetters(currentListing.bathrooms)} ${
                    removeLetters(currentListing.bathrooms) > 1 ? 'BATHS' : 'BATH'
                  }`}
                </span>
                <span className="fact">
                  {`${currentListing.area} SF / ${SFtoSM(currentListing.area)} SM`}
                </span>
              </div>
              <div className="fact-section">
                <span className="fact">EXPOSURES:</span>
                <span className="fact">{parseOrientation(currentListing.exposures)}</span>
              </div>
              <div className="fact-section">
                <span className="fact">{`$${numberWithCommas(currentListing.price)}`}</span>
                <span className="fact gray">
                  {`${parseAdditionalFields(
                    currentListing.additional_fields,
                    'Common Charges'
                  )} EST. C.C.`}
                </span>
                <span className="fact gray">
                  {`${parseAdditionalFields(
                    currentListing.additional_fields,
                    'RE Taxes'
                  )} EST R.E. TAXES`}
                </span>
              </div>
            </div>
            <div className="keyplan-column">
              {currentFloorplans && (
                <img
                  className="keyplan"
                  src={currentFloorplans.keyplan}
                  alt={`${currentListing.unit_num} Keyplan`}
                />
              )}
            </div>
          </div>
          {currentFloorplans && <DownloadButton currentFloorplans={currentFloorplans} />}
        </MobileListingContainer>
      </>
    ) : (
      <LoadingSpinner />
    )
  ) : (
    <Redirect to="/nomad-new-condominium-availability" />
  );
};

export default withRouter(Listing);
