const residencesCopy = {
  desktop: {
    heading: 'FROM YOUR\nHOME, THE\nWORLD’S MOST\nICONIC\nNIGHTLIGHT',
    subHeading:
      'Madison House lets you float above the city. Interiors by Gachot provide warmth, choice, and the best bones for better living. Each residence has been optimized for the flow of a perfected daily life.',
    vrSectionTitle: 'Experience The Residences at Madison House in full 360° virtual reality:',
    vrSectionLink: {
      text: 'EXPLORE THE VR',
      url: '/vr-residence-53a/'
    },
    sectionOneTitle: 'LIVING AREAS',
    sectionOneText:
      'Each residence has a private foyer, allowing a gentle entrance to the magnificent living rooms. The 11-foot ceilings emphasize the epic scale of the vista and offer the viewer a greater appreciation that each residence has at least one corner window, if not two or more.',
    sectionTwoText: '',
    sectionThreeTitle: 'KITCHENS',
    sectionThreeText:
      'Open or closed kitchens boast custom cabinetry, Gaggenau and Bosch appliances, as well as a Calacatta Borghini marble island.',
    sectionFourTitle: 'MASTER BATHROOMS',
    sectionFourText:
      "Gachot's design envelops residents in sophistication and warmth, with Bianco Dolomiti marble slabs and vanities clad in rift cut walnut and Calacatta Borghini marble.",
    sectionFiveText: '',
    sectionSixTitle: 'VIEWS',
    sectionSixText:
      'Up and away, the glittering nightscape features the jewel of the Empire State Building.',
    sectionSevenText:
      'Every floor offers its own extraordinary views. Madison House opens up a new angle on Manhattan, a previously unavailable visual of the historic buildings around the park and clear vistas down to the southern tip of the island. Suddenly on view are the intricate stonework and decorative rooftops, all offering an aesthetic marvel in any season.',
    sectionEightText:
      'The view toward the harbor takes in the East and Hudson Rivers, The Clocktower, and the bold lodestar of One World Trade Center.',
  },
  mobile: {
    heading: 'FROM YOUR\nHOME, THE\nWORLD’S MOST\nICONIC\nNIGHTLIGHT',
    subHeading:
      'Madison House lets you float above the city. Interiors by Gachot provide warmth, choice, and the best bones for better living. Each residence has been optimized for the flow of a perfected daily life.',
    vrSectionTitle: 'Experience The Residences at Madison House in full 360° virtual reality:',
    vrSectionLink: {
      text: 'EXPLORE THE VR',
      url: '/vr-residence-53a/'
    },
    sectionOneTitle: 'LIVING AREAS',
    sectionOneText: 'Many residences offer multiple corner exposures.',
    sectionTwoText: '11-foot ceilings create incredible volume and scale.',
    sectionThreeTitle: 'KITCHENS',
    sectionThreeText: 'Custom cabinetry is a sleek frame for Gaggenau appliances.',
    sectionFourTitle: 'MASTER BATHROOMS',
    sectionFourText: 'Bianco Dolomiti meets Calacatta Borghini stone in master baths.',
    sectionFiveText: 'South American Danzer Walnut cabinetry and Dornbracht fixtures.',
    sectionSixTitle: 'VIEWS',
    sectionSixText: 'Enchanting night views of the Empire State Building.',
    sectionSevenText:
      'Every floor offers its own extraordinary views. Madison House opens up a new angle on Manhattan, a previously unavailable visual of the historic buildings around the park and clear vistas down to the southern tip of the island, with extraordinary views throughout the entire height of the building.',
    sectionEightText: 'Lower Manhattan unveiling each day.',
  },
};

const residencePhotos = [
  {
    src: '/images/residences/residences_1',
    alt: 'Detail of residence corner with Empire State Building views',
  },
  { src: '/images/residences/residences_2', alt: 'Living room with multiple corner exposures' },
  { src: '/images/residences/residences_3', alt: 'Living room with Empire State Building views' },
  {
    src: '/images/residences/residences_4',
    alt: 'Kitchen with custom cabinetry and Gaggenau appliances',
  },
  {
    src: '/images/residences/residences_5',
    alt: 'Kitchen with custom cabinetry and Gaggenau appliances',
  },
  { src: '/images/residences/residences_6', alt: 'Master Bathroom designed by Gachot' },
  { src: '/images/residences/residences_7', alt: 'Vanity designed by Gachot' },
  { src: '/images/residences/residences_8', alt: 'View from Madison House looking Northwest' },
  { src: '/images/residences/residences_9', alt: 'View from Madison House looking Southeast' },
  { src: '/images/residences/residences_10', alt: 'View from Madison House looking South' },
];

const residencePhotosMobile = [
  {
    src: '/images/residences/residences_1',
    alt: 'Detail of residence corner with Empire State Building views',
  },
  { src: '/images/residences/residences_2', alt: 'Living room with multiple corner exposures' },
  { src: '/images/residences/residences_3', alt: 'Living room with Empire State Building views' },
  {
    src: '/images/residences/residences_4',
    alt: 'Kitchen with custom cabinetry and Gaggenau appliances',
  },
  { src: '/images/residences/residences_6', alt: 'Master Bathroom designed by Gachot' },
  { src: '/images/residences/residences_7', alt: 'Vanity designed by Gachot' },
  { src: '/images/residences/residences_8', alt: 'View from Madison House looking Northwest' },
  { src: '/images/residences/residences_9', alt: 'View from Madison House looking Southeast' },
  { src: '/images/residences/residences_10', alt: 'View from Madison House looking South' },
];

export { residencesCopy, residencePhotos, residencePhotosMobile };
