import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import variables from '../styles/Variables';
import { mediaMin } from '../styles/MediaQueries';
import { HeaderLogo } from '../components/utils/page-header';

const LegalPage = styled.div`
  display: flex;
  flex-direction: column;
  color: ${variables.colors.darkGray};
  width: 100%;
  align-items: center;
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20vw 20vw 20vw;

  ${mediaMin.tablet`
    padding: 0 20vw 20vw 20vw;
  `}
  ${mediaMin.desktopSmall`
    padding: 0 30vw 20vw 30vw;
  `}
  h3 {
    color: ${variables.colors.darkRed};
    font-size: 1.5em;
    font-weight: medium;
    &:first-child {
      margin-top: 0;
    }
  }
  .fair-housing-link {
    text-decoration: underline;
    color: blue;
    margin-bottom: 1em;
  }
`;

const Legal = () => (
  <>
    <Helmet>
      <title>Contact Madison House | New Development in Nomad</title>
    </Helmet>
    <LegalPage>
      <HeaderLogo />
      <TextSection>
        <h3>IMAGE CREDITS</h3>
        <p>Renderings & view photography by DBOX</p>
        <p>Neighborhood photography (unless otherwise noted) by Chris Colls</p>
        <p>
          Molteni closet photograph courtesy of Molteni Madison Square Park art installation
          photograph by DBOX
        </p>
        <p>Illustrations by Caroline Tomlinson</p>
        <h3>LEGAL DISCLAIMER</h3>
        <p>
          The complete offering terms are in an offering plan available from the Sponsor. File No.
          CD18-0326. The artist representations and interior decorations, finishes, appliances and
          furnishings are provided for illustrative purposes only. Sponsor makes no representations
          or warranties except as may be set forth in the Offering Plan. Sponsor reserves the right
          to substitute materials, appliances, equipment, fixtures and other construction and design
          details specified herein as provided in the Offering Plan. All dimensions are approximate
          and subject to normal construction variances and tolerances. Square footage exceeds the
          usable floor area. Sponsor reserves the right to make changes in accordance with the terms
          of the Offering Plan. Plans and dimensions may contain minor variations from floor to
          floor. Sponsor: 15 East 30 Sponsor LLC having an address at 352 Park Avenue South 15th
          floor, New York, New York 10010. Equal Housing Opportunity.
        </p>
        <a
          href="/documents/Fair_Housing_Notice.pdf"
          target="_blank"
          rel="noreferrer noopener"
          className="fair-housing-link"
        >
          NYS STANDARD OPERATING PROCEDURE & NYS HOUSING DISCRIMINATION DISCLOSURE FORM.
        </a>
        <h3>ACCESSIBILITY STATEMENT</h3>
        <p>
          Madison House is committed to providing a website that is accessible to the widest
          possible audience, regardless of technology or ability. We aim to comply with all
          applicable standards.
          <br aria-hidden />
          <br aria-hidden />
          If you experience any difficulty in accessing any part of this website, please contact us
          by emailing info@madisonhousenyc.com or calling us at 212-686-1530
        </p>
        <h3>MADISON HOUSE PRIVACY POLICY</h3>
        <p>
          Madison House (referred to herein as “Madison House“, “we” or “us”) is committed to
          protecting the privacy of the users of its website. This statement discloses our privacy
          practices. The purpose of this statement is to inform you: What kinds of information we
          collect from users of “web address” and how the information is used by us Whether we
          disclose any user information to third parties; How you can access, update or delete any
          information that we collect about you; and The security procedures in which we use to
          protect your personal information. By using the Website, you’re agreeing to Madison House
          Privacy Policy. We reserve the right to change, modify, add, or remove portions of our
          Privacy Policy at any time. Please check this page periodically for changes. If you have
          any questions about our Privacy Policy, please e-mail us at info@MadisonHouseNYC.com
          <br aria-hidden />
          <br aria-hidden />
          <strong>How We Collect Information</strong>
          <br aria-hidden />
          <br aria-hidden />
          You can visit MadisonHouseNYC.com without revealing any personal information. Our web
          servers collect the Internet Protocol addresses, but not the e-mail addresses, of
          visitors. This information can measure the number of visits, average time spent on the
          site, pages viewed, and other such statistics. In addition, You may be asked for
          information that identifies you, including your name, e-mail address, mailing address, zip
          code, telephone number, fax (collectively, “Personal Information“). You may also elect to
          provide such information to us by sending or responding to e-mails regarding certain
          listings or services offered on our Website.
          <br aria-hidden />
          <br aria-hidden />
          <strong>How We Use and Disclose Your Information</strong>
          <br aria-hidden />
          <br aria-hidden />
          We collect, generate, retain and use your Personal Information for our own internal
          purposes in connection with the facilitation, recording and processing of any requests,
          communications, or interactions you may have with our Website. We also automatically
          collect and store statistics and other information about you and your online activities on
          an aggregated, non-personally identifiable basis and in a manner that may allow us or our
          affiliated or related entities to improve our services to you. In addition, your Personal
          Information may be also be used by us to provide you with information regarding our
          products and services. For example, we may use your e-mail address to send you special
          announcements and notifications of new real estate listings, services or promotions that
          may be of interest to you.
          <br aria-hidden />
          <br aria-hidden />
          <strong>Cookies</strong>
          <br aria-hidden />
          <br aria-hidden />
          Cookies allow websites to recognize you when you return and can keep track of information
          specific to you. Consistent with standard practices in the Internet industry, you may
          occasionally receive cookies from third parties to which you link from our Website. If you
          prefer, you may set your browser to ask for your permission before your receive a cookie.
          We do not control these third party cookies, and they are not subject to this Privacy
          Policy.
          <br aria-hidden />
          <br aria-hidden />
          <strong>Information sharing and Disclosure</strong>
          <br aria-hidden />
          <br aria-hidden />
          When you request information, we need to know your name, e-mail address, and mailing
          address. This allows us to process and fulfill your request. MadisonHouseNYC.com does not
          sell, trade, or rent your personal information to others MadisonHouseNYC.com may provide
          aggregate statistics about our customers, sales, traffic patterns, and related site
          information to reputable third-party vendors, but these statistics will include no
          personally identifying information.
          <br aria-hidden />
          <br aria-hidden />
          <strong>Data Security</strong>
          <br aria-hidden />
          <br aria-hidden />
          Data security is critical to us and thus all Personal Information is held in a secured
          database. While it is impossible to guarantee the complete security of any computer system
          and the data contained therein, our vendors are required to maintain and implement robust
          security policies and procedures that combine with available technologies in accordance
          with prevailing industry standards, all of which are designed to protect the
          confidentiality, integrity, and availability of your Personal Information. To the extent
          we are provided with social security numbers or personal financial information, we comply
          with all applicable regulations regarding the confidentiality and safe disposal of such
          information.
          <br aria-hidden />
          <br aria-hidden />
          <strong>
            Accessing, Updating or Deleting Information Collected About You By Madison House
          </strong>
          <br aria-hidden />
          <br aria-hidden />
          If you wish to access, update, or delete contact information or preferences, please send
          an e-mail to info@MadisonHouseNYC.com Please be aware, however, that we cannot always
          ensure that such corrections or deletions will immediately be made in our database.
        </p>
      </TextSection>
    </LegalPage>
  </>
);

export default Legal;
