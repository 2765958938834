import React from 'react';
import MediaQuery from 'react-responsive';
import { Helmet } from 'react-helmet';

import PageNavigation from '../components/utils/page-navigation';

import PhotoViewer from '../components/photo-viewer';
import { architecturePhotos } from '../data/architecture';
import ArchitectureMobile from '../components/architecture/architecture-mobile';
import ArchitectureDesktop from '../components/architecture/architecture-desktop';

const Architecture = () => (
  <>
    <Helmet>
      <title>Manhattan Luxury Condominiums | Madison House NoMad</title>
      <meta
        name="description"
        content="Designed by Handel Architects in the heart of NoMad, residences start 150 feet up and feature corner exposures and compelling views."
      />
    </Helmet>
    <MediaQuery minWidth={1024}>
      <ArchitectureDesktop />
    </MediaQuery>
    <MediaQuery maxWidth={1023}>
      <ArchitectureMobile />
    </MediaQuery>
    <PhotoViewer photoArray={architecturePhotos} />
    <PageNavigation nextPath="/nomad-new-condominiums" nextPage="RESIDENCES & VIEWS" />
  </>
);

export default Architecture;
