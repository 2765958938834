import styled from 'styled-components';
import variables from '../../styles/Variables';

export const ButtonLink = styled.a`
  display: inline-block;
  margin: 8px 0 0 0;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 16px;
  height: 32px;
  background-color: ${variables.colors.darkRed};
  color: #ffffff;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  text-decoration: none;
  font-family: Apercu;
  font-weight: normal;
  transition: all 300ms ease;

  &:visited, &:hover {
    color: #ffffff;
    text-decoration: none;
  }
`;
