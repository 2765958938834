import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactModal from 'react-modal';
import ReactPlayer from 'react-player';

import EmailSubmission from './EmailSubmission';

import variables from '../../styles/Variables';
import { mediaMin } from '../../styles/MediaQueries';

const modalStyles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, .4)',
    zIndex: 10,
  },
  content: {
    background: '#fff',
    WebkitOverflowScrolling: 'touch',
    outline: 'none',
    zIndex: 10,
  },
};

const CloseModal = styled.button`
  height: 40px;
  width: 40px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 300ms ease;
  ${mediaMin.tabletLandscape`
    &:hover {
      background-color: rgba(255, 255, 255, 0.5);
    }
  `}
  img {
    height: 20px;
    width: 20px;
  }
`;

const PopupTextContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2em;
`;

const TitleText = styled.h3`
  font-size: 22px;
  line-height: 22px;
  text-align: center;
  width: 100%;
  font-family: AWConquerorProDidot-Regular;
  color: ${variables.colors.darkRed};
  text-transform: uppercase;
  margin: 0 0 1em 0;
  opacity: ${(props) => (props.thankYouVisible ? 0 : 1)};
  visibility: ${(props) => (props.thankYouVisible ? 'hidden' : 'visible')};
  transition: opacity 400ms ease;
  ${mediaMin.tablet`
    width: 75%;
    font-size: 24px;
    line-height: 24px;
  `}
`;

const BodyText = styled.p`
  font-size: 15px;
  line-height: 21px;
  width: 100%;
  text-align: center;
  font-family: Granjon;
  opacity: ${(props) => (props.thankYouVisible ? 0 : 1)};
  visibility: ${(props) => (props.thankYouVisible ? 'hidden' : 'visible')};
  display: none;
  transition: opacity 400ms ease;
  ${mediaMin.tabletLandscape`
    width: 75%;
    font-size: 18px;
    line-height: 18px;
  `}
`;

const setCookie = (name, value, days) => {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
};

const getCookie = (name) => {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

ReactModal.setAppElement('#root');

const PopUp = () => {
  const [open, setOpen] = useState(true);
  const [thankYouVisible, setThankYouVisible] = useState(false);

  const closeModal = () => {
    setOpen(false);
    setCookie('madison-house', true, 30);
  };

  const openModal = () => {
    setOpen(true);
  };

  useEffect(() => {
    const cookie = getCookie('madison-house');
    if (!cookie) openModal();
  }, []);

  return (
    <ReactModal
      closeTimeoutMS={500}
      isOpen={open}
      style={modalStyles}
      onRequestClose={closeModal}
      parentSelector={() => document.body}
      className="modal"
    >
      <div className="modal-player-wrapper">
        <CloseModal onClick={closeModal}>
          <img src="/images/logos/close.svg" alt="Close pupup" />
        </CloseModal>
        <ReactPlayer
          className="modal-react-player"
          url="https://vimeo.com/736239959"
          controls
          width="100%"
          height="100%"
        />
      </div>
      <PopupTextContainer>
        <TitleText thankYouVisible={thankYouVisible}>
          The Madison House sales gallery is now open for private appointments
        </TitleText>
        <BodyText thankYouVisible={thankYouVisible}>
          Enter your email to schedule an appointment or virtual tour:
        </BodyText>
        <EmailSubmission
          thankYouVisible={thankYouVisible}
          setThankYouVisible={setThankYouVisible}
          closeModal={closeModal}
        />
      </PopupTextContainer>
    </ReactModal>
  );
};

export default PopUp;
