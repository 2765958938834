import React, { useContext } from 'react';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';
import Fade from 'react-reveal/Fade';

import Context from '../../config/Context';
import ResponsiveImage from '../utils/responsive-image';
import { HeaderLogo, MobilePageTitle } from '../utils/page-header';
import { galleryPhotosMobile } from '../../data/gallery';
import PhotoViewer from '../photo-viewer';

const GalleryColumn = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  padding: 0 32px;
  margin-bottom: 64px;
  .header-logo-link {
    display: flex;
    justify-content: center;
  }
`;

const GalleryRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 32px;
  .react-reveal {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    img {
      height: 100%;
    }
  }
`;

const GalleryMobile = () => {
  const context = useContext(Context);
  const { togglePhotoViewerState } = context;

  const generateRows = () => galleryPhotosMobile.map((image, idx) => (image.mobileIgnore ? null : (
    <GalleryRow key={image.src}>
      <LazyLoad>
        <Fade>
          <ResponsiveImage
            srcPath={image.src}
            imgAlt={image.alt}
            width={image.width}
            margin={image.margin}
            onClickProp={() => togglePhotoViewerState(idx)}
          />
        </Fade>
      </LazyLoad>
    </GalleryRow>
  )));

  return (
    <>
      <GalleryColumn>
        <HeaderLogo />
        <MobilePageTitle>GALLERY</MobilePageTitle>
        {generateRows()}
      </GalleryColumn>
      <PhotoViewer photoArray={galleryPhotosMobile} />
    </>
  );
};

export default GalleryMobile;
