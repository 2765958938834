import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import $ from 'jquery';
import Context from '../../config/Context';

import validateEmail from '../../components/utils/validateEmail';
import variables from '../../styles/Variables';
import { mediaMin } from '../../styles/MediaQueries';

const EmailForm = styled.form`
  display: flex;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  flex-direction: column;
  width: 100%;
  ${mediaMin.tablet`
    width: auto;
    flex-direction: row;
  `}
  input {
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 16px;
    display: flex;
    flex-grow: 1;
    height: 32px;
    padding: 0 8px;
    border-radius: 0;
    border: ${(props) =>
      props.error ? `1px solid ${variables.colors.darkRed}` : `1px solid grey`};
    transition: all 300ms ease;
    opacity: ${(props) => (props.thankYouVisible ? 0 : 1)};
    visibility: ${(props) => (props.thankYouVisible ? 'hidden' : 'visible')};

    &::placeholder {
      text-align: center;
    }
    ${mediaMin.tablet`
      margin: 0 .5em;
      &::placeholder {
        text-align: left;
      }
    `}
  }
  button {
    margin: 1em 0;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 16px;
    height: 32px;
    background-color: ${variables.colors.darkRed};
    color: #fff;
    border: none;
    padding: 0 16px;
    cursor: pointer;
    transition: all 300ms ease;
    opacity: ${(props) => (props.thankYouVisible ? 0 : 1)};
    visibility: ${(props) => (props.thankYouVisible ? 'hidden' : 'visible')};
    ${mediaMin.tablet`
      margin: 0 .5em;
    `}
  }
`;

const ThankYou = styled.p`
  position: absolute;
  transition: all 300ms ease;
  opacity: ${(props) => (props.thankYouVisible ? 1 : 0)};
  visibility: ${(props) => (props.thankYouVisible ? 'visible' : 'hidden')};
`;

const EmailSubmission = ({ closeModal, thankYouVisible, setThankYouVisible }) => {
  const context = useContext(Context);
  const { setEmailSubmitted } = context;

  const [inputs, setInputs] = useState({
    email: '',
    first_name: '',
    last_name: '',
  });

  const [emailError, setEmailError] = useState(false);

  const animate = () => {
    setThankYouVisible(true);
    setTimeout(() => {
      setEmailSubmitted(true);
      closeModal();
    }, 2000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, first_name, last_name } = inputs;
    if (validateEmail(email)) {
      $.ajax({
        url: 'https://form.api.dbxd.com/post-spark-form/',
        type: 'POST',
        dataType: 'json',
        data: {
          projectname: 'madisonhousenyc',
          contact: {
            email,
            first_name,
            last_name,
          },
        },
        crossDomain: true,
        success: (/* res  textStatus, jqXHR */) => {
          animate();
          console.log('success - form submitted');
        },
        error: (jqXHR, textStatus, errorThrown) => {
          setEmailError(true);
          console.log('http request failed', errorThrown);
        },
      });
    } else {
      setEmailError(true);
      console.log('error');
    }
  };

  const handleChange = (event) => {
    event.persist();
    setInputs((inputs) => ({ ...inputs, [event.target.name]: event.target.value }));
  };

  return (
    <>
      <EmailForm onSubmit={handleSubmit} error={emailError} thankYouVisible={thankYouVisible}>
        <input
          id="first_name"
          name="first_name"
          type="text"
          placeholder="FIRST NAME"
          onChange={handleChange}
        />
        <input
          id="last_name"
          name="last_name"
          type="last_name"
          placeholder="LAST NAME"
          onChange={handleChange}
        />
        <input
          id="email"
          name="email"
          type="email"
          placeholder="EMAIL ADDRESS"
          onChange={handleChange}
        />
        <button type="submit">SUBMIT</button>
      </EmailForm>
      <ThankYou thankYouVisible={thankYouVisible}>Thank you for providing your email!</ThankYou>
    </>
  );
};

export default EmailSubmission;
