import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import { Helmet } from 'react-helmet';

import variables from '../styles/Variables';
import { mediaMin } from '../styles/MediaQueries';
import { HeaderLogo, MobilePageTitle } from '../components/utils/page-header';
import PageNavigation from '../components/utils/page-navigation';
import { teamInfo } from '../data/team';

const TeamContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${variables.colors.lightGray};
  box-sizing: border-box;
  padding-bottom: 64px;
  ${mediaMin.tabletLandscape`
   padding-bottom: 80px;
  `}
`;

const TeamHeader = styled.h2`
  font-family: AWConquerorProDidotL-Regular;
  text-align: center;
  color: ${variables.colors.darkRed};
  font-size: 48px;
  line-height: 48px;
  letter-spacing: 0.86px;
  margin: 0px 0 80px 0;
  ${mediaMin.tabletLandscape`
  margin: 120px 0 200px 0;
  font-size: 64px;
  line-height: 64px;
  letter-spacing: unset;
  `}
`;

const ContentRow = styled.div`
  display: flex;
  width: 100%;
  margin: 0 0 56px 0;
  padding: 0 36px;
  flex-direction: column;
  ${mediaMin.tabletLandscape`
  flex-direction: row;
  padding: 0 20%;
  margin: 0 0 80px 0;
  `}
`;

const TitleCol = styled.div`
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 16px;
  color: ${variables.colors.darkRed};
  width: 100%;
  margin-bottom: 16px;
  ${mediaMin.tabletLandscape`
  margin-bottom: 0;
  white-space: pre-line;
  width: 25%;
  `}
`;

const BodyCol = styled.div`
  font-family: Granjon;
  color: ${variables.colors.darkGray};
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  ${mediaMin.tabletLandscape`
    transform: translateY(-4px);
    font-size: 20px;
    line-height: 28px;
    white-space: pre-line;
    width: 75%;
  `}
`;

const generateTeamRows = () => teamInfo.map((el) => (
  <Fade bottom cascade>
    <ContentRow>
      <TitleCol>{el.title}</TitleCol>
      <BodyCol>{el.body}</BodyCol>
    </ContentRow>
  </Fade>
));

const Team = () => (
  <>
    <Helmet>
      <title>Madison House Development Team | NoMad Real Estate</title>
    </Helmet>
    <TeamContainer>
      <HeaderLogo />
      <MobilePageTitle>TEAM</MobilePageTitle>
      <Fade bottom cascade distance="5px">
        <TeamHeader>
          A TEAM
          <br />
          COMMITTED
          <br />
          TO
          <br />
          EXCELLENCE
        </TeamHeader>
      </Fade>
      {generateTeamRows()}
      <PageNavigation
        prevPage="NOMAD GUIDE"
        prevPath="/nomad-neighborhood-guide"
        nextPage="GALLERY"
        nextPath="/gallery"
      />
    </TeamContainer>
  </>
);

export default Team;
