/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';

import variables from '../../styles/Variables';

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15vh 10vw 0 45vw;
  height: 100vh;
  justify-content: start;
  opacity: ${(props) => (props.showSuccess ? '1' : '0')};
  visibility: ${(props) => (props.showSuccess ? 'visible' : 'hidden')};
  transition-property: opacity, visibility;
  transition-duration: 1.5s, 1.5s;
  transition-timing-function: ease-in-out;
  h3 {
    margin-top: 0;
    font-size: 64px;
    line-height: 64px;
    font-family: 'AWConquerorProDidotL-Regular';
    font-weight: light;
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
  p {
    margin-top: 0;
    font-size: 20px;
    line-height: 28px;
    font-family: 'Granjon';
    color: ${variables.colors.darkGray};
  }
`;

export default class Success extends React.Component {
  constructor(props) {
    super(props);
    this.focusRef = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      this.focusRef.current.focus();
    }, 2000);
  }

  render() {
    const { showSuccess } = this.props;
    return (
      <SuccessContainer showSuccess={showSuccess}>
        <h3 tabIndex="-1" ref={this.focusRef}>
          THANK YOU FOR
          <br />
          YOUR INTEREST IN
          <br />
          MADISON HOUSE.
        </h3>
        <p>The sales team will be in touch soon.</p>
      </SuccessContainer>
    );
  }
}
