/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import { Helmet } from 'react-helmet';

import { nomadGuideData, nomadGuideSVGKeys } from '../data/nomad-guide';
import variables from '../styles/Variables';
import { mediaMin } from '../styles/MediaQueries';
import PageNavigation from '../components/utils/page-navigation';
import { HeaderLogo, MobilePageTitle } from '../components/utils/page-header';
import ResponsiveImage from '../components/utils/responsive-image';

const NomadGuideContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const GuideRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 36px;
  ${mediaMin.tabletLandscape`
  padding: 0 18%;

`}
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 80px;
  ${mediaMin.tabletLandscape`
  margin-bottom: 0;
  width: 50%;
  `}
`;

const RightColumn = styled.div`
  display: none;
  ${mediaMin.tabletLandscape`
  display: flex;
  flex-direction: column;
  width: 50%;
  `}
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  ${mediaMin.tabletLandscape`
  margin-bottom: 160px;
  `}
  img.mobile-image {
    ${mediaMin.tabletLandscape`
      display: none;
    `}
  }
`;

const SectionTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  h3 {
    white-space: pre-line;
    font-family: AWConquerorProDidotL-Regular;
    margin: 0;
    font-size: 35px;
    line-height: 35px;
    ${mediaMin.tabletLandscape`
    font-size: 48px;
    line-height: 48px;
`}
  }
  .title {
    color: ${variables.colors.darkRed};
  }
  .subtitle {
    color: ${variables.colors.darkGray};
  }
`;

const SectionBody = styled.div`
  display: flex;
  flex-direction: column;
  .section-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    .name {
      font-size: 14px;
      letter-spacing: 1.5px;
      line-height: 20px;
      color: ${variables.colors.darkRed};
    }
    .address {
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 1px;
      line-height: 12px;
      color: ${variables.colors.darkGray};
    }
  }
`;

const MapContainer = styled.div`
  display: none;
  ${mediaMin.tabletLandscape`
position: fixed;
display: flex;
height: 100vh;
width: 50%;
right: 0;
top: 0;
bottom: 0;
box-sizing: border-box;
`}
`;

const PhotoSwitcher = styled.div`
  display: none;
  ${mediaMin.tabletLandscape`
    display: block;
  `}
  position: relative;
  width: 100%;
  height: 100%;
  img {
    position: absolute;
  }
  .object-container {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: opacity 0.5s ease-in-out;
    object {
      position: absolute;
      max-width: 100%;
      max-height: 100%;
      top: 0;
      left: 0;
      svg {
        object-fit: cover;
      }
    }
    .responsive-image {
      position: absolute;
      z-index: -1;
      max-width: 100%;
      max-height: 100%;
      height: 100%;
      object-fit: cover;
      top: 0;
      left: 0;
    }
  }
  .one {
    opacity: ${(props) => (props.photoIndex <= 1 ? '1' : '0')};
  }
  .two {
    opacity: ${(props) => (props.photoIndex > 1 && props.photoIndex <= 2 ? '1' : '0')};
  }
  .three {
    opacity: ${(props) => (props.photoIndex > 2 && props.photoIndex <= 3 ? '1' : '0')};
  }
  .four {
    opacity: ${(props) => (props.photoIndex > 3 && props.photoIndex <= 4 ? '1' : '0')};
  }
  .five {
    opacity: ${(props) => (props.photoIndex > 4 && props.photoIndex <= 5 ? '1' : '0')};
  }
`;

const Guide = () => {
  const [scrollProgress, setScrollProgress] = useState(0);
  const [mapOne, setMapOne] = useState(null);
  const [mapTwo, setMapTwo] = useState(null);
  const [mapThree, setMapThree] = useState(null);
  const [mapFour, setMapFour] = useState(null);
  const [mapFive, setMapFive] = useState(null);
  const [mapOneHover, setMapOneHover] = useState('');
  const [mapTwoHover, setMapTwoHover] = useState('');
  const [mapThreeHover, setMapThreeHover] = useState('');
  const [mapFourHover, setMapFourHover] = useState('');
  const [mapFiveHover, setMapFiveHover] = useState('');

  const setUpSVGTransitions = () => {
    // sets up animations for building svg
    let svgObject = document.getElementById('map-one');
    let svgObjectDocument = svgObject.contentDocument;
    setMapOne(svgObjectDocument);
    svgObject = document.getElementById('map-two');
    svgObjectDocument = svgObject.contentDocument;
    setMapTwo(svgObjectDocument);
    svgObject = document.getElementById('map-three');
    svgObjectDocument = svgObject.contentDocument;
    setMapThree(svgObjectDocument);
    svgObject = document.getElementById('map-four');
    svgObjectDocument = svgObject.contentDocument;
    setMapFour(svgObjectDocument);
    svgObject = document.getElementById('map-five');
    svgObjectDocument = svgObject.contentDocument;
    setMapFive(svgObjectDocument);
  };

  const updateScrollProgress = () => {
    const { documentElement } = document;
    const scrollPx = documentElement.scrollTop;
    const winHeightPx = documentElement.scrollHeight - documentElement.clientHeight;
    const scrolled = (scrollPx / winHeightPx) * 100;
    setScrollProgress(scrolled);
  };

  const handleMouseOver = (name, mapIdx) => {
    if (mapIdx === 0) {
      setMapOneHover(name);
    }
    if (mapIdx === 1) {
      setMapTwoHover(name);
    }
    if (mapIdx === 2) {
      setMapThreeHover(name);
    }
    if (mapIdx === 3) {
      setMapFourHover(name);
    }
    if (mapIdx === 4) {
      setMapFiveHover(name);
    }
  };

  const createBody = (bodyArray, mapIdx) => (
    bodyArray.map(
      (el) => (
        <Fade>
          <div
            className="section-item"
            onMouseEnter={() => handleMouseOver(el.name, mapIdx)}
            onMouseLeave={() => handleMouseOver('', mapIdx)}
            key={el.address}
          >
            <span className="name">{el.name}</span>
            <span className="address">{el.address}</span>
          </div>
        </Fade>
      ),
    )
  );

  const createSections = () => (
    nomadGuideData.map(
      (section, sectionIdx) => (
        <Section key={section.sectionTitle}>
          <Fade>
            <ResponsiveImage
              srcPath={section.mobileImage.src}
              imgAlt={section.mobileImage.alt}
              imgClass="mobile-image"
            />
          </Fade>
          <Fade>
            <SectionTitle>
              <h3 className="title">{section.sectionTitle}</h3>
              <h3 className="subtitle">{section.sectionSubTitle}</h3>
            </SectionTitle>
          </Fade>
          <SectionBody>
            {createBody(section.sectionBody, sectionIdx)}
          </SectionBody>
        </Section>
      ),
    )
  );

  useEffect(() => {
    window.addEventListener('scroll', updateScrollProgress);
    setTimeout(setUpSVGTransitions, 1000);
    return () => {
      window.removeEventListener('scroll', updateScrollProgress);
    };
  }, []);

  useEffect(() => {
    const { dining } = nomadGuideSVGKeys;
    if (mapOne) {
      if (mapOneHover !== '') {
        const label = mapOne.getElementById(dining[mapOneHover]);
        label.style.fill = variables.colors.darkRed;
      } else {
        Object.values(dining).forEach((el) => {
          const label = mapOne.getElementById(el);
          label.style.fill = '#000';
        });
      }
    }
  }, [mapOneHover]);

  useEffect(() => {
    const { landmarks } = nomadGuideSVGKeys;
    if (mapTwo) {
      if (
        mapTwoHover !== ''
        && mapTwoHover !== 'GRAMERCY PARK'
        && mapTwoHover !== 'MADISON SQUARE PARK'
        && mapTwoHover !== 'UNION SQUARE PARK'
      ) {
        const label = mapTwo.getElementById(landmarks[mapTwoHover]);
        label.style.fill = variables.colors.darkRed;
      } else {
        Object.values(landmarks).forEach((el) => {
          const label = mapTwo.getElementById(el);
          label.style.fill = '#000';
        });
      }
    }
  }, [mapTwoHover]);

  useEffect(() => {
    const { shopping } = nomadGuideSVGKeys;
    if (mapThree) {
      if (mapThreeHover !== '') {
        const label = mapThree.getElementById(shopping[mapThreeHover]);
        label.style.fill = variables.colors.darkRed;
      } else {
        Object.values(shopping).forEach((el) => {
          const label = mapThree.getElementById(el);
          label.style.fill = '#000';
        });
      }
    }
  }, [mapThreeHover]);

  useEffect(() => {
    const { culture } = nomadGuideSVGKeys;
    if (mapFour) {
      if (mapFourHover !== '') {
        const label = mapFour.getElementById(culture[mapFourHover]);
        label.style.fill = variables.colors.darkRed;
      } else {
        Object.values(culture).forEach((el) => {
          const label = mapFour.getElementById(el);
          label.style.fill = '#000';
        });
      }
    }
  }, [mapFourHover]);

  useEffect(() => {
    const { lifestyle } = nomadGuideSVGKeys;
    if (mapFive) {
      if (mapFiveHover !== '') {
        const label = mapFive.getElementById(lifestyle[mapFiveHover]);
        label.style.fill = variables.colors.darkRed;
      } else {
        Object.values(lifestyle).forEach((el) => {
          const label = mapFive.getElementById(el);
          label.style.fill = '#000';
        });
      }
    }
  }, [mapFiveHover]);

  return (
    <>
      <Helmet>
        <title>Explore NoMad Neighborhood Culture | Madison House</title>
        <meta
          name="description"
          content="Explore NoMad Dining, Restaurants and Nightlife. Landmarks, history and architecture, as well as shopping, entertainment and the arts."
        />
      </Helmet>
      <NomadGuideContainer>
        <HeaderLogo />
        <MobilePageTitle>NOMAD GUIDE</MobilePageTitle>
        <MapContainer>
          <PhotoSwitcher photoIndex={parseFloat(scrollProgress / 20).toFixed(2)}>
            <div className="one object-container">
              <object
                id="map-one"
                data="/images/nomad_guide/MH_map_1_dining.svg"
                type="image/svg+xml"
              >
                Dining Map
              </object>
            </div>
            <div className="two object-container">
              <object
                id="map-two"
                data="/images/nomad_guide/MH_map_2_landmarks.svg"
                type="image/svg+xml"
              >
                LandMarks Map
              </object>
            </div>
            <div className="three object-container">
              <object
                id="map-three"
                data="/images/nomad_guide/MH_map_3_shopping.svg"
                type="image/svg+xml"
              >
                Shopping Map
              </object>
            </div>
            <div className="four object-container">
              <object
                id="map-four"
                data="/images/nomad_guide/MH_map_4_culture.svg"
                type="image/svg+xml"
              >
                Culture Map
              </object>
            </div>
            <div className="five object-container">
              <object
                id="map-five"
                data="/images/nomad_guide/MH_map_5_lifestyle.svg"
                type="image/svg+xml"
              >
                Lifestyle Map
              </object>
            </div>
          </PhotoSwitcher>
        </MapContainer>
        <GuideRow>
          <LeftColumn>{createSections()}</LeftColumn>
          <RightColumn />
        </GuideRow>
        <PageNavigation
          prevPage="NOMAD STORY"
          prevPath="/nomad-story"
          nextPage="TEAM"
          nextPath="/nomad-real-estate-development"
        />
      </NomadGuideContainer>
    </>
  );
};

export default Guide;
