const routes = [
  [
    { path: 'manhattan-luxury-condominiums', text: 'ARCHITECTURE' },
    { path: 'nomad-new-condominiums', text: 'RESIDENCES & VIEWS' },
    { path: 'manhattan-penthouse-condominiums', text: 'PENTHOUSES' },
    { path: 'nomad-new-condominium-amenities', text: 'AMENITIES' },
    { path: 'nomad-new-condominium-availability', text: 'AVAILABILITY' },
    { path: 'nomad-new-condominium-services', text: 'SERVICES' },
  ],
  [
    { path: 'nomad-story', text: 'NOMAD STORY' },
    { path: 'nomad-neighborhood-guide', text: 'NOMAD GUIDE' },
  ],
  [
    { path: 'nomad-real-estate-development', text: 'TEAM' },
    { path: 'gallery', text: 'GALLERY' },
    { path: 'nomad-real-estate-news', text: 'PRESS' },
    { path: 'contact-madison-house', text: 'CONTACT' },
  ],
];

const navTitles = {
  '/manhattan-luxury-condominiums': 'ARCHITECTURE',
  '/nomad-new-condominiums': 'RESIDENCES & VIEWS',
  '/manhattan-penthouse-condominiums': 'PENTHOUSES',
  '/nomad-new-condominium-amenities': 'AMENITIES',
  '/nomad-new-condominium-availability': 'AVAILABILITY',
  '/nomad-new-condominium-services': 'SERVICES',
  '/nomad-story': 'NOMAD STORY',
  '/nomad-neighborhood-guide': 'NOMAD GUIDE',
  '/nomad-real-estate-development': 'TEAM',
  '/gallery': 'GALLERY',
  '/nomad-real-estate-news': 'PRESS',
  '/contact-madison-house': 'CONTACT',
  '/legal': 'LEGAL',
};

export { routes, navTitles };
