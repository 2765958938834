import React, { useContext } from 'react';
import Fade from 'react-reveal/Fade';

import Context from '../../config/Context';
import {
  MobileCol,
  MainRow,
  PaddingColumn,
  RightColumn,
  ContentRow,
  TextColumn,
  ImageColumn,
} from '../utils/desktop-columns';
import {
  MobileHeaderCol,
  MobilePageTitle,
  HeaderLogo,
  GrayBackground,
  HeroImage,
  MobileImageColumn,
} from '../utils/page-header';
import ResponsiveImage from '../utils/responsive-image';
import { residencesCopy } from '../../data/residences';
import { ButtonLink } from '../utils/button-link';

const ResidencesMobile = () => {
  const context = useContext(Context);
  const { togglePhotoViewerState } = context;
  return (
    <MobileCol>
      <MobileHeaderCol>
        <HeaderLogo />
        <MobilePageTitle>RESIDENCES & VIEWS</MobilePageTitle>
        <MobileImageColumn>
          <HeroImage>
            <ResponsiveImage
              onClickProp={() => {
                togglePhotoViewerState(0);
              }}
              srcPath="/images/residences/residences_1"
            />
          </HeroImage>
          <h2>{residencesCopy.mobile.heading}</h2>
        </MobileImageColumn>
        <GrayBackground />
      </MobileHeaderCol>
      <MainRow>
        <PaddingColumn />
        <RightColumn>
          <Fade>
            <ContentRow>
              <TextColumn>
                <p className="sub-heading">{residencesCopy.mobile.subHeading}</p>
                <p>
                  {residencesCopy.mobile.vrSectionTitle}<br />
                  <ButtonLink href={residencesCopy.mobile.vrSectionLink.url} target="_blank">
                    {residencesCopy.mobile.vrSectionLink.text}
                  </ButtonLink>
                </p>
              </TextColumn>
            </ContentRow>
          </Fade>
          <Fade>
            <ContentRow>
              <TextColumn>
                <h3>{residencesCopy.mobile.sectionOneTitle}</h3>
              </TextColumn>
              <ImageColumn>
                <ResponsiveImage
                  onClickProp={() => {
                    togglePhotoViewerState(1);
                  }}
                  srcPath="/images/residences/residences_2"
                />
              </ImageColumn>
              <TextColumn>
                <p>{residencesCopy.mobile.sectionOneText}</p>
              </TextColumn>
            </ContentRow>
          </Fade>
          <Fade>
            <ContentRow>
              <ImageColumn>
                <ResponsiveImage
                  onClickProp={() => {
                    togglePhotoViewerState(2);
                  }}
                  srcPath="/images/residences/residences_3"
                />
              </ImageColumn>
              <TextColumn>
                <p>{residencesCopy.mobile.sectionTwoText}</p>
              </TextColumn>
            </ContentRow>
          </Fade>
          <Fade>
            <ContentRow>
              <TextColumn>
                <h3>{residencesCopy.mobile.sectionThreeTitle}</h3>
              </TextColumn>
              <ImageColumn>
                <ResponsiveImage
                  onClickProp={() => {
                    togglePhotoViewerState(3);
                  }}
                  srcPath="/images/residences/residences_4"
                />
              </ImageColumn>
              <TextColumn>
                <p>{residencesCopy.mobile.sectionThreeText}</p>
              </TextColumn>
            </ContentRow>
          </Fade>
          <Fade>
            <ContentRow>
              <TextColumn>
                <h3>{residencesCopy.mobile.sectionFourTitle}</h3>
              </TextColumn>
              <ImageColumn portrait>
                <ResponsiveImage
                  onClickProp={() => {
                    togglePhotoViewerState(4);
                  }}
                  srcPath="/images/residences/residences_6"
                />
              </ImageColumn>
              <TextColumn>
                <p>{residencesCopy.mobile.sectionFourText}</p>
              </TextColumn>
            </ContentRow>
          </Fade>
          <Fade>
            <ContentRow>
              <ImageColumn portrait>
                <ResponsiveImage
                  onClickProp={() => {
                    togglePhotoViewerState(5);
                  }}
                  srcPath="/images/residences/residences_7"
                />
              </ImageColumn>
              <TextColumn>
                <p>{residencesCopy.mobile.sectionFiveText}</p>
              </TextColumn>
            </ContentRow>
          </Fade>
          <Fade>
            <ContentRow>
              <TextColumn>
                <h3>{residencesCopy.mobile.sectionSixTitle}</h3>
              </TextColumn>
              <ImageColumn>
                <ResponsiveImage
                  onClickProp={() => {
                    togglePhotoViewerState(6);
                  }}
                  srcPath="/images/residences/residences_8"
                />
              </ImageColumn>
              <TextColumn>
                <p>{residencesCopy.mobile.sectionSixText}</p>
              </TextColumn>
            </ContentRow>
          </Fade>
          <Fade>
            <ContentRow>
              <ImageColumn>
                <ResponsiveImage
                  onClickProp={() => {
                    togglePhotoViewerState(7);
                  }}
                  srcPath="/images/residences/residences_9"
                />
              </ImageColumn>
              <TextColumn>
                <p>{residencesCopy.mobile.sectionSevenText}</p>
              </TextColumn>
            </ContentRow>
          </Fade>
          <Fade>
            <ContentRow>
              <ImageColumn>
                <ResponsiveImage
                  onClickProp={() => {
                    togglePhotoViewerState(8);
                  }}
                  srcPath="/images/residences/residences_10"
                />
              </ImageColumn>
              <TextColumn>
                <p>{residencesCopy.mobile.sectionEightText}</p>
              </TextColumn>
            </ContentRow>
          </Fade>
        </RightColumn>
      </MainRow>
    </MobileCol>
  );
};

export default ResidencesMobile;
