/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import { mediaMin } from '../styles/MediaQueries';
import DesktopStory from '../components/nomad-story/nomad-story-desktop';
import MobileStory from '../components/nomad-story/nomad-story-mobile';
import Popup from '../components/popup';

const StoryContainer = styled.div`
  height: 100%;
  max-height: 100vh;
  margin-bottom: 160px;
  ${mediaMin.tabletLandscape`
    margin: 0;
  `}
  .swiper-container {
    display: none;
    ${mediaMin.tabletLandscape`
    display: block;
    height: 100%;
    max-height: 100vh;
    `}
    .swiper-wrapper {
      height: 100%;
      max-height: 100vh;
      .swiper-slide {
        height: 100%;
        max-height: 100vh;
        padding-top: 120px;
      }
      .full-height {
        padding-top: 0;
      }
    }
  }
`;

const Story = () => (
  <>
    <Helmet>
      <title>NoMad is the Middle of Where Manhattan Matters | Madison House</title>
      <meta
        name="description"
        content="NoMad is graces with some of the most recognizable artifacts of New York design and Architectural History. "
      />
    </Helmet>
    <StoryContainer>
      <DesktopStory />
      <MobileStory />
    </StoryContainer>
    <Popup />
  </>
);

export default Story;
