/* eslint-disable comma-dangle */
/* eslint-disable arrow-parens */
/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import styled from 'styled-components';
import { NavLink, Link } from 'react-router-dom';

import Context from '../../config/Context';
import { routes } from '../../data/routes';
import variables from '../../styles/Variables';
import { mediaMin } from '../../styles/MediaQueries';

const MobileLinksContainer = styled.nav`
  background-color: ${variables.colors.darkRed};
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  padding: 30% 36px;
  ${mediaMin.tablet`
    padding: 18% 36px;
  `}
  display: block;
  opacity: ${props => (props.open ? '1' : '0')};
  visibility: ${props => (props.open ? 'visible' : 'hidden')};
  transition: all 0.5s ease-in-out;
  overflow-y: scroll;
  a {
    display: flex;
    margin-bottom: 64px;
    justify-content: center;
    img {
      width: 100%;
    }
  }
  a.logo-link {
    img {
      width: 100%;
      ${mediaMin.tablet`
        width: 50%;
      `}
    }
  }
  ul {
    margin: 0 0 16px 0;
    padding: 0;
    list-style-type: none;
    &:last-child {
      margin: 0;
    }
    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      a {
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 2px;
        line-height: 16px;
        margin-bottom: 16px;
        color: #fff;
        &:hover {
          text-decoration: none;
          color: ${variables.colors.brightRed};
          svg {
            .path {
              fill: ${variables.colors.darkRed};
            }
          }
        }
      }
      a.active {
        color: ${variables.colors.brightRed};
      }
    }
  }
  ${mediaMin.tabletLandscape`
display: none;

`}
`;

const MobileIconRow = styled.div`
  ul {
    display: flex;
    flex-direction: row;
    li {
      margin-left: 24px;
    }
  }
`;

const MobileIcons = () => (
  <MobileIconRow>
    <ul>
      <li>
        <a className="InfinityNumber clickable" href="tel:2126861530">
          <img src="/images/logos/mobile-call.svg" alt="call madison house" />
        </a>
      </li>
      <li>
        <a href="mailto:info@madisonhousenyc.com">
          <img src="/images/logos/mobile-mail.svg" alt="email madison house" />
        </a>
      </li>
      <li>
        <a
          href="https://www.instagram.com/madisonhousenyc/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/logos/mobile-instagram.svg" alt="madison house instagram" />
        </a>
      </li>
    </ul>
  </MobileIconRow>
);

const generateRoutes = context => {
  const parsedRoutes = [];
  routes.forEach(section => {
    const tempSection = [];
    section.forEach(el => {
      tempSection.push(
        <li key={el.text}>
          <NavLink
            onClick={() => {
              context.closeOverlays();
            }}
            activeClassName="active"
            to={`/${el.path}`}
          >
            {el.text}
          </NavLink>
          {el.path === 'contact-madison-house' ? <MobileIcons /> : ''}
        </li>
      );
    });
    parsedRoutes.push(<ul key={tempSection}>{tempSection}</ul>);
  });
  return parsedRoutes;
};

const MobileLinks = ({ pathname }) => {
  const context = useContext(Context);
  const { navigationState } = context;

  return (
    <MobileLinksContainer
      white={pathname === '/manhattan-penthouse-condominiums'}
      open={navigationState}
    >
      <Link
        className="logo-link"
        to="/"
        onClick={() => {
          context.closeOverlays();
        }}
      >
        <img src="/images/logos/madison-house-white.svg" alt="Madison House Logo" />
      </Link>
      {generateRoutes(context)}
    </MobileLinksContainer>
  );
};

export default MobileLinks;
