/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import styled from 'styled-components';

import Context from '../../config/Context';
import variables from '../../styles/Variables';
import { mediaMin } from '../../styles/MediaQueries';

const ShareDrawerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 5px 10px 0px #000
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  transition: transform 0.5s ease-in-out;
  height: 170px;
  transform: ${(props) => (props.active ? 'translateY(0)' : 'translateY(170px)')};
  flex-direction: column;
  ${mediaMin.tabletLandscape`
  height: 120px;
  transform: ${(props) => (props.active ? 'translateY(0)' : 'translateY(120px)')};
  flex-direction: row;
  `}
`;

const DrawerMessage = styled.div`
  color: #1a1919;
  font-family: 'Granjon';
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 16px 0;
  ${mediaMin.tabletLandscape`
  margin: 0 8px;
  `}
`;

const ActiveListings = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 36px;
  jusfify-content: space-between;
  align-items: center;
  margin: 0 0 16px 0;
  ${mediaMin.tabletLandscape`
  width: unset;
  margin: 0;
  `}
`;

const SelectedListing = styled.div`
  height: 44px;
  width: 100%;
  margin: 0 4px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  background-color: ${variables.colors.lightGray};
  color: ${variables.colors.darkRed};
  font-size: 24px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: AWConquerorProDidot-Regular;
  font-variant-numeric: lining-nums;
  ${mediaMin.tabletLandscape`
  margin: 0 8px;
  height: 72px;
  width: 128px;
  font-size: 32px;
  line-height: 64px;
`}
`;

const Unselected = styled.div`
  border: 1px dashed #000;
  margin: 0 4px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  height: 44px;
  width: 100%;
  ${mediaMin.tabletLandscape`
    margin: 0 8px;
    height: 72px;
    width: 128px;
  `}
`;

const SubmitButton = styled.button`
  height: 40px;
  width: 240px;
  margin: 0 8px;
  color: #fff;
  background-color: ${variables.colors.darkRed};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const CloseButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  position: absolute;
  top: 0;
  right: 0;
  margin: 15px;
  height: 24px;
  width: 24px;
  ${mediaMin.tabletLandscape`
    display: none;
  `}
`;

const ShareDrawer = ({ closeAndResetShareDrawer }) => {
  const context = useContext(Context);
  const { shareDrawerActive, sharedListings, toggleShareFormActive } = context;
  let sharedListingsArray = Array.from(sharedListings);
  sharedListingsArray = sharedListingsArray.map((listing) => (
    <SelectedListing key={listing}>{listing}</SelectedListing>
  ));
  while (sharedListingsArray.length < 4) {
    sharedListingsArray.push(<Unselected key={Math.random() * 100} />);
  }
  return (
    <ShareDrawerContainer active={shareDrawerActive}>
      <DrawerMessage>Select up to 4 floor plans to share</DrawerMessage>
      <ActiveListings>{sharedListingsArray}</ActiveListings>
      <SubmitButton
        onClick={(e) => {
          e.preventDefault();
          if (sharedListings.size > 0) {
            toggleShareFormActive();
          }
        }}
      >
        SHARE
      </SubmitButton>
      <CloseButton onClick={closeAndResetShareDrawer}>
        <img src="/images/logos/close.svg" alt="close share drawer" />
      </CloseButton>
    </ShareDrawerContainer>
  );
};

export default ShareDrawer;
