const teamInfo = [
  {
    title: 'JD CARLISLE \n DEVELOPMENT',
    body:
      'JD Carlisle Development is a real estate development firm with over 70 years of experience creating properties in New York, including luxury residential developments such as 160 Madison Avenue and The Beatrice.',
  },
  {
    title: 'FOSUN INTERNATIONAL \n LIMITED',
    body:
      'JD Carlisle is joined on this project with Fosun International Limited, a family-focused multi-national company based in Shanghai. With its roots in China, Fosun aims to create ecosystems in health, happiness and wealth, providing high-quality products and services for families around the world.',
  },
  {
    title: 'HANDEL ARCHITECTS',
    body:
      'Handel Architects was founded in 1994 with a commitment to making cities better places to live. With over 180 architects and designers in New York, San Francisco, Boston, and Hong Kong, Handel’s work encompasses projects such as the National September 11 Memorial, urban infrastructure and master planning projects, high density mixed-use developments, multi-family projects across all income bands, corporate headquarters, and buildings for non-profit, institutional and educational clients around the world.',
  },
  {
    title: 'GACHOT STUDIOS',
    body:
      'Gachot was founded by John and Christine Gachot to deliver design service in a collaborative environment. The team brings together diverse skills in real estate development, art direction and interior design to deliver thoughtful, project-specific products. Melding timeless craft with modern technology, Gachot aims to create work with a refined aesthetic and radical sense of place. Simplicity, elegance and acute attention to detail are the guiding values. Every project is treated as a unique expression.',
  },
  {
    title: 'DOUGLAS ELLIMAN \n DEVELOPMENT \n MARKETING',
    body:
      'Douglas Elliman Development Marketing, a division of Douglas Elliman Real Estate, offers unmatched expertise in sales, leasing, and marketing for new developments throughout New York City, Long Island, The Hamptons, Westchester, New Jersey, South Florida, California, and Massachusetts, as well as throughout the United States and internationally. The firm ranks amongst New York City’s most prominent sales and marketing firms with over 100 in-house development professionals and an over $87 billion global new development portfolio. Our hybrid platform of matching experienced new development experts with our skilled brokerage professionals provides unparalleled expertise and real time market intelligence to our clients.',
  },
  {
    title: 'LIVUNLTD',
    body:
      'LIVunLtd is a global luxury brand specializing in providing hospitality and amenity services to the most prestigious properties on the market. From best-in-class group fitness and personal training, to thoughtfully curated social activities and events, to a vast array of hotel-inspired concierge services, everything we do is intentionally designed to provide residents with everything they need to access and enjoy the lifestyle they seek to live.',
  },
  {
    title: 'DBOX',
    body:
      'DBOX builds brands and develops immersive marketing campaigns for the fields of property development, architecture and the arts. Since being founded in 1996, DBOX has received numerous awards for their work which has been exhibited at some of the world’s most respected art institutions.',
  },
];

export { teamInfo };
