/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 36px;
  min-height: 100vh;
  justify-content: flex-start;
  opacity: ${(props) => (props.showSuccess ? '1' : '0')};
  visibility: ${(props) => (props.showSuccess ? 'visible' : 'hidden')};
  transition: all 0.5s ease-in-out;
  h3 {
    margin-top: 20%;
    font-size: 48px;
    line-height: 48px;
    letter-spacing: 0.86px;
    font-family: AWConquerorProDidotL-Regular;
    font-weight: light;
  }
  p {
    margin-top: 0;
    margin-bottom: 48px;
    font-family: Granjon;
    color: #000;
    font-size: 20px;
    line-height: 28px;
  }
  .section {
    opacity: ${(props) => (props.navActive ? '0' : '1')};
    display: flex;
    flex-direction: column;
    margin-bottom: 15%;
    text-align: start;
    width: 100%;
    transition: opacity 0.5s ease-in-out;
    &:last-child {
      margin-bottom: 0;
    }
    span,
    a {
      width: 100%;
      font-size: 16px;
      letter-spacing: 2px;
      line-height: 16px;
      margin-bottom: 22px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const MobileSuccess = (props) => {
  const { showSuccess } = props;
  return (
    <SuccessContainer showSuccess={showSuccess}>
      <h3>THANK YOU FOR YOUR INTEREST IN MADISON HOUSE.</h3>
      <p>The sales team will be in touch soon.</p>
      <div className="section">
        <a href="mailto:info@madisonhousenyc.com">
          <span>INFO@MADISONHOUSENYC.COM</span>
        </a>
        <a href="tel:2126861530">
          <span>212.686.1530</span>
        </a>
      </div>
      <div className="section">
        <span>SALES GALLERY</span>
        <span>15 E 30TH STREET</span>
        <span>NEW YORK, NEW YORK, 10016</span>
      </div>
      <div className="section">
        <a
          href="https://www.instagram.com/madisonhousenyc/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>INSTAGRAM</span>
        </a>
      </div>
      <div className="section">
        <Link to="/legal">
          <span>LEGAL</span>
        </Link>
      </div>
    </SuccessContainer>
  );
};

export default MobileSuccess;
