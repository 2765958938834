const servicesCopy = {
  desktop: {
    heading: 'SERVICES\nAT THE\nMADISON\nCLUB',
    subHeading: null,
    sectionOneTitle: 'WELLNESS SERVICES',
    sectionOneText:
      'If you\'re seeking an expert personal trainer, a nutrition counselor, a five-star message therapist, yoga, pilates, or boot camp, life at Madison House brings access to an array of services to help you live your best, no matter where you are on your journey to wellness.',
    sectionTwoTitle: 'TRAVEL ITINERARIES',
    sectionTwoText:
      'To help you fully experience your travel destination, your concierge will leverage a global network of contacts and resources to provide you with a unique and bespoke itinerary of activities, tours, and more.',
    sectionThreeTitle: 'MEMORABLE GIFTS',
    sectionThreeText:
      'Whether you\'re going to a dinner party, celebrating a special occasion or impressing a client, your concierge will assist in finding a thoughtful gift that will make the perfect statement.',
    sectionFourTitle: 'BEAUTY AND HEALTH',
    sectionFourText:
      'Pampering comes in many forms, at either the best salons, in the privacy of your own home or in the Madison Club designed by Gachot Studios. Your concierge will coordinate appointments for every type of service, from beauty and grooming to acupuncture and meditation.',
    sectionFiveTitle: 'LIFESTYLE SERVICES',
    sectionFiveText:
      'From simple conveniences such as refrigerated storage, door-to-door package delivery and bike storage to the luxury of a personal chef or a professional curator for  your art collection, a truly refined lifestyle awaits you.',
    sectionSixTitle: 'TRANSPORTATION SERVICES',
    sectionSixText:
      'No need for the ride share apps, your concierge will book a private car and driver to get you where you need to go in style, no matter where in the world and no matter what the weather does. ',
    sectionSevenTitle: 'EFFORTLESS LIVING',
    sectionSevenText:
      'It begins with your pre-arrival, during which every detail of your move is handled with care and personalize attention. Your concierge will arrange everything from booking movers, to transporting and installing your art collection. Housekeeping, pet services, salon and spa appointments, and everything else you need are in the capable hands of your concierge.',
    sectionEightTitle: 'RESERVATIONS',
    sectionEightText:
      'Wherever you are in the world, your concierge will be your steward, recommending and reserving your place at the most desirable, sought-after restaurants and nightlife venues. Whether you seek a table, a private dining suite or a spot alongside the chef.',
  },
  mobile: {
    heading: 'SERVICES\nAT THE\nMADISON\nCLUB',
    subHeading: null,
    sectionOneTitle: 'WELLNESS SERVICES',
    sectionOneText:
      'If you\'re seeking an expert personal trainer, a nutrition counselor, a five-star message therapist, yoga, pilates, or boot camp, life at Madison House brings access to an array of services to help you live your best, no matter where you are on your journey to wellness.',
    sectionTwoTitle: 'TRAVEL ITINERARIES',
    sectionTwoText:
      'To help you fully experience your travel destination, your concierge will leverage a global network of contacts and resources to provide you with a unique and bespoke itinerary of activities, tours, and more.',
    sectionThreeTitle: 'MEMORABLE GIFTS',
    sectionThreeText:
      'Whether you\'re going to a dinner party, celebrating a special occasion or impressing a client, your concierge will assist in finding a thoughtful gift that will make the perfect statement.',
    sectionFourTitle: 'BEAUTY AND HEALTH',
    sectionFourText:
      'Pampering comes in many forms, at either the best salons, in the privacy of your own home or in the Madison Club designed by Gachot Studios. Your concierge will coordinate appointments for every type of service, from beauty and grooming to acupuncture and meditation.',
    sectionFiveTitle: 'LIFESTYLE SERVICES',
    sectionFiveText:
      'From simple conveniences such as refrigerated storage, door-to-door package delivery and bike storage to the luxury of a personal chef or a professional curator for  your art collection, a truly refined lifestyle awaits you.',
    sectionSixTitle: 'TRANSPORTATION SERVICES',
    sectionSixText:
      'No need for the ride share apps, your concierge will book a private car and driver to get you where you need to go in style, no matter where in the world and no matter what the weather does. ',
    sectionSevenTitle: 'EFFORTLESS LIVING',
    sectionSevenText:
      'It begins with your pre-arrival, during which every detail of your move is handled with care and personalize attention. Your concierge will arrange everything from booking movers, to transporting and installing your art collection. Housekeeping, pet services, salon and spa appointments, and everything else you need are in the capable hands of your concierge.',
    sectionEightTitle: 'RESERVATIONS',
    sectionEightText:
      'Wherever you are in the world, your concierge will be your steward, recommending and reserving your place at the most desirable, sought-after restaurants and nightlife venues. Whether you seek a table, a private dining suite or a spot alongside the chef.',
  },
};

const servicesPhotos = [
  { src: '/images/services/services_1', alt: '' },
  { src: '/images/services/services_2', alt: '' },
  { src: '/images/services/services_3', alt: '' },
  { src: '/images/services/services_4', alt: '' },
  { src: '/images/services/services_5', alt: '' },
  { src: '/images/services/services_6', alt: '' },
  { src: '/images/services/services_7', alt: '' },
  { src: '/images/services/services_8', alt: '' },
  { src: '/images/services/services_9', alt: '' },
];

export { servicesCopy, servicesPhotos };
