import React from 'react';
import MediaQuery from 'react-responsive';
import { Helmet } from 'react-helmet';
import { ParallaxProvider } from 'react-scroll-parallax';

import PageNavigation from '../components/utils/page-navigation';
import GalleryDesktop from '../components/gallery/gallery-desktop';
import GalleryMobile from '../components/gallery/gallery-mobile';

const Gallery = () => (
  <>
    <Helmet>
      <title>Manhattan Luxury Condominiums | Madison House Gallery</title>
      <meta name="description" content="Photo Gallery" />
    </Helmet>
    <MediaQuery minWidth={1024}>
      <ParallaxProvider>
        <GalleryDesktop />
      </ParallaxProvider>
    </MediaQuery>
    <MediaQuery maxWidth={1023}>
      <GalleryMobile />
    </MediaQuery>
    <PageNavigation
      prevPath="/nomad-real-estate-development"
      prevPage="TEAM"
      nextPath="/nomad-real-estate-news"
      nextPage="PRESS"
    />
  </>
);

export default Gallery;
