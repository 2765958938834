/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import variables from '../../styles/Variables';
import AutoComplete from './auto-complete';
import { MobilePageTitle } from '../utils/page-header';

const RegisterForm = styled.form`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  opacity: ${(props) => (props.showForm ? '1' : '0')};
  visibility: ${(props) => (props.showForm ? 'visible' : 'hidden')};
  transition: all 0.5s ease-in-out;
  padding: 20vh 36px 0 36px;
  h2 {
    font-family: 'Granjon';
    font-size: 2em;
    margin: 0 0 90px 0;
  }
  h5 {
    margin: 0;
    font-size: 0.9em;
  }
  .input-label {
    display: flex;
    flex-direction: column;
    font-size: 0.9em;
    .react-autosuggest__container {
      input {
        width: 100%;
      }
      .react-autosuggest__suggestions-container--open {
        .react-autosuggest__suggestions-list {
          list-style-type: none;
          padding: 0;
          li {
            padding: 0.5em 0.5em;
            cursor: pointer;
          }
        }
      }
    }
    input {
      height: 40px;
      border-radius: 0;
      margin-top: 0.5em;
      padding: 1em 0.5em;
      box-sizing: border-box;
      font-size: 1.25em;
      color: ${variables.colors.darkRed};
      background: transparent;
      border: 1px solid ${variables.colors.darkRed};
      background-color: #fff;
    }
    select {
      border: 1px solid ${variables.colors.darkRed};
      background-color: #fff;
      border-radius: 0;
      height: 40px;
      color: ${variables.colors.darkRed};
      font-size: 1em;
      margin-top: 0.5em;
      padding: 0 0.5em;
      background-image: url(/images/logos/dropdown-down.svg);
      background-position: 100% -25%;
      background-size: 50px 50px;
      background-repeat: no-repeat;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
    }
    input:-webkit-autofill,
    input:-internal-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      background-color: #00000000;
    }
  }
  .radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    background-color: #f1f1f1;
    color: #666;
    border: 0;
    border-radius: 50% !important;
    cursor: pointer;
    outline: none;
    padding: 0 !important;
    &:checked {
      background-color: ${variables.colors.darkRed};
    }
  }
  button {
    height: 40px;
    font-size: 1.25em;
    color: ${variables.colors.darkRed};
    background: transparent;
    border: 1px solid ${variables.colors.darkRed};
    cursor: pointer;
  }
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 1;
`;

const RadioDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .input-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
    input {
      margin: 0 0 0 1em;
      height: 15px;
      width: 15px;
    }
  }
`;

const AlertRow = styled.div`
  display: flex;
  flex-drection: row;
  margin-top: 20px;
  margin-bottom: 32px;
  height: 40px;
  justify-content: space-between;
`;

const RequiredFlag = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1em 1em;
  box-sizing: border-box;
  background: #fff;
  color: #ee5b5b;
  font-size: 0.9em;
  font-weight: bold;
  transition: opacity 0.25s ease-in-out;
  height: 40px;
  opacity: ${(props) => (props.error ? '1' : '0')};
`;

const InfoOverlay = styled.div`
  position: fixed;
  overflow-y: scroll;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 99;
  opacity: ${(props) => (props.overlay ? '1' : '0')};
  visibility: ${(props) => (props.overlay ? 'visible' : 'hidden')};
  transition: opacity 0.5s ease, visibility 0.5s ease;
`;

const OverlayInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 32px;
  a {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .section {
    opacity: ${(props) => (props.navActive ? '0' : '1')};
    display: flex;
    flex-direction: column;
    margin-bottom: 15%;
    text-align: start;
    width: 100%;
    transition: opacity 0.5s ease-in-out;
    &:last-child {
      margin-bottom: 0;
    }
    span,
    a {
      width: 100%;
      font-size: 16px;
      letter-spacing: 2px;
      line-height: 16px;
      margin-bottom: 22px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const OverlayButton = styled.button`
  background: none;
  background-color: ${variables.colors.darkRed};
  color: #fff;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  height: 40px;
  width: 250px;
  margin: 64px auto 64px 0;
  font-size: 14px;
  letter-spacing: 1.75px;
  line-height: 16px;
  cursor: pointer;
`;

const MobileForm = ({
  broker,
  name,
  address,
  email,
  phone,
  represented,
  brokerName,
  brokerFirm,
  errors,
  submitForm,
  showForm,
  handleChange,
  setEmailSubmitted,
  setEmailFormMessageVisable,
}) => {
  const [overlay, setOverlay] = useState(true);

  const updateForm = (...args) => {
    handleChange(args[0]);
  };

  const disableOverlay = () => {
    setOverlay(false);
  };

  useEffect(() => {
    const htmlNode = document.getElementById('html');
    if (overlay) {
      htmlNode.style.overflowY = 'hidden';
    } else {
      htmlNode.style.overflowY = 'auto';
    }
  }, [overlay]);

  return (
    <>
      <InfoOverlay overlay={overlay}>
        <OverlayInner>
          <MobilePageTitle>CONTACT</MobilePageTitle>
          <Link to="/">
            <img
              className="logo"
              src="/images/logos/madison-house-black.svg"
              alt="Madison House Logo"
            />
          </Link>
          <OverlayButton onClick={disableOverlay}>REGISTER YOUR INTEREST</OverlayButton>
          <div className="section">
            <a href="mailto:info@madisonhousenyc.com">
              <span>INFO@MADISONHOUSENYC.COM</span>
            </a>
            <a className="InfinityNumber clickable" href="tel:2126861530">
              <span className="InfinityNumber">212.686.1530</span>
            </a>
          </div>
          <div className="section">
            <span>SALES GALLERY</span>
            <span>15 E 30TH STREET</span>
            <span>NEW YORK, NEW YORK, 10016</span>
          </div>
          <div className="section">
            <a
              href="https://www.instagram.com/madisonhousenyc/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>INSTAGRAM</span>
            </a>
          </div>
          <div className="section">
            <Link to="/legal">
              <span>LEGAL</span>
            </Link>
          </div>
        </OverlayInner>
      </InfoOverlay>
      <RegisterForm
        onSubmit={(e) => {
          submitForm(e);
          setEmailSubmitted(true);
          setEmailFormMessageVisable(false);
        }}
        showForm={showForm}
      >
        <h2>REGISTER</h2>
        <FormRow>
          <h5>I AM A...</h5>
          <RadioDiv>
            <input type="hidden" name="ictvid" id="ictvid" value="" />
            <label className="input-label" htmlFor="buyer">
              Buyer
              <input
                name="broker"
                id="buyer"
                type="radio"
                value={false}
                checked={!JSON.parse(broker)}
                className="radio"
                onChange={(...args) => {
                  updateForm(args[0]);
                }}
              />
            </label>
            <label className="input-label" htmlFor="broker">
              Broker
              <input
                name="broker"
                id="broker"
                type="radio"
                value
                checked={JSON.parse(broker)}
                className="radio"
                onChange={(...args) => {
                  updateForm(args[0]);
                }}
              />
            </label>
          </RadioDiv>
          <AlertRow>
            <RequiredFlag aria-hidden>REQUIRED</RequiredFlag>
          </AlertRow>
        </FormRow>
        <FormRow>
          <label className="input-label" htmlFor="name">
            FIRST + LAST NAME*
            <input
              name="name"
              id="name"
              type="text"
              value={name}
              onChange={(...args) => {
                updateForm(args[0]);
              }}
            />
          </label>
          <AlertRow>
            <RequiredFlag aria-hidden={!errors.name} error={errors.name}>
              REQUIRED
            </RequiredFlag>
          </AlertRow>
        </FormRow>
        {JSON.parse(broker) ? (
          <FormRow>
            <label className="input-label" htmlFor="brokerFirm">
              BROKERAGE FIRM
              <AutoComplete
                name="brokerFirm"
                id="brokerFirm"
                type="text"
                value={brokerFirm}
                onChangeCallback={(...args) => {
                  updateForm(args[0]);
                }}
              />
            </label>
            <AlertRow>
              <RequiredFlag aria-hidden={!errors.brokerFirm} error={errors.brokerFirm}>
                REQUIRED
              </RequiredFlag>
            </AlertRow>
          </FormRow>
        ) : (
          ''
        )}
        <FormRow>
          <label className="input-label" htmlFor="address">
            ADDRESS
            <input
              name="address"
              id="address"
              type="text"
              value={address}
              onChange={(...args) => {
                updateForm(args[0]);
              }}
            />
          </label>
          <AlertRow>
            <RequiredFlag aria-hidden>REQUIRED</RequiredFlag>
          </AlertRow>
        </FormRow>
        <FormRow>
          <label className="input-label" htmlFor="email">
            EMAIL ADDRESS*
            <input
              name="email"
              id="email"
              type="text"
              value={email}
              onChange={(...args) => {
                updateForm(args[0]);
              }}
            />
          </label>
          <AlertRow>
            <RequiredFlag aria-hidden={!errors.email} error={errors.email}>
              REQUIRED
            </RequiredFlag>
          </AlertRow>
        </FormRow>
        <FormRow>
          <label className="input-label" htmlFor="phone">
            PHONE NUMBER*
            <input
              name="phone"
              id="phone"
              type="number"
              format="###-###-####"
              value={phone}
              onChange={(...args) => {
                updateForm(args[0]);
              }}
            />
          </label>
          <AlertRow>
            <RequiredFlag aria-hidden={!errors.phone} error={errors.phone}>
              REQUIRED
            </RequiredFlag>
          </AlertRow>
        </FormRow>
        <FormRow>
          <label className="input-label" htmlFor="howHear">
            HOW DID YOU HEAR ABOUT US?*
            <select
              name="howHear"
              id="howHear"
              onChange={(...args) => {
                updateForm(args[0]);
              }}
            >
              <option selected disabled value="">
                Select an answer
              </option>
              <option value="eBlast">Eblast</option>
              <option value="www.Elliman.com">Elliman.com</option>
              <option value="Other">Online Ad</option>
              <option value="Online Search">Online Search</option>
              <option value="Referral">Referral</option>
              <option value="Site Signage">Site Signage</option>
              <option value="StreetEasy.com">StreetEasy</option>
              <option value="other">Other</option>
            </select>
          </label>
          <AlertRow>
            <RequiredFlag aria-hidden={!errors.howHear} error={errors.howHear}>
              REQUIRED
            </RequiredFlag>
          </AlertRow>
        </FormRow>
        <FormRow>
          <label className="input-label" htmlFor="residence">
            DESIRED RESIDENCE?*
            <select
              name="residence"
              id="residence"
              onChange={(...args) => {
                updateForm(args[0]);
              }}
            >
              <option selected disabled value="">
                Select an answer
              </option>
              <option value="1 bedroom">ONE BEDROOM</option>
              <option value="2 bedroom">TWO BEDROOM</option>
              <option value="3 bedroom">THREE BEDROOM</option>
              <option value="4 bedroom">FOUR BEDROOM</option>
              <option value="penthouse">PENTHOUSE</option>
            </select>
          </label>
          <AlertRow>
            <RequiredFlag aria-hidden={!errors.residence} error={errors.residence}>
              REQUIRED
            </RequiredFlag>
          </AlertRow>
        </FormRow>
        <FormRow>
          <label className="input-label" htmlFor="priceRange">
            PRICED FROM*
            <select
              name="priceRange"
              id="priceRange"
              onChange={(...args) => {
                updateForm(args[0]);
              }}
            >
              <option selected disabled value="">
                Select an answer
              </option>
              <option value="$2-$3 million">$1-3M</option>
              <option value="$3-$5 million">$3-5M</option>
              <option value="$5-$7 million">$5-7M</option>
              <option value="$7-$10 million">$7-10M</option>
              <option value="$10-$15 million">$10-15M</option>
              <option value="$15-$20 million">$15-20M</option>
              <option value="$20 million +">$20M+</option>
            </select>
          </label>
          <AlertRow>
            <RequiredFlag aria-hidden={!errors.priceRange} error={errors.priceRange}>
              REQUIRED
            </RequiredFlag>
          </AlertRow>
        </FormRow>
        {/* IF BUYER */}
        {JSON.parse(broker) ? (
          ''
        ) : (
          <>
            <FormRow>
              <h5 tabIndex="-1">ARE YOU CURRENTLY REPRESENTED BY A BROKER?*</h5>
              <RadioDiv>
                <label className="input-label" htmlFor="represented-yes">
                  Yes
                  <input
                    name="represented"
                    id="represented-yes"
                    type="radio"
                    value
                    checked={JSON.parse(represented) === true}
                    className="radio"
                    onChange={(...args) => {
                      updateForm(args[0]);
                    }}
                  />
                </label>
                <label className="input-label" htmlFor="represented-no">
                  No
                  <input
                    name="represented"
                    id="represented-no"
                    type="radio"
                    value={false}
                    checked={JSON.parse(represented) === false}
                    className="radio"
                    onChange={(...args) => {
                      updateForm(args[0]);
                    }}
                  />
                </label>
              </RadioDiv>
              <AlertRow>
                <RequiredFlag aria-hidden={!errors.represented} error={errors.represented}>
                  REQUIRED
                </RequiredFlag>
              </AlertRow>
            </FormRow>
            {/* IF REPRESENTED BY BROKER */}
            {JSON.parse(represented) ? (
              <>
                <FormRow>
                  <label className="input-label" htmlFor="brokerName">
                    BROKER FIRST + LAST NAME*
                    <input
                      name="brokerName"
                      id="brokerName"
                      type="text"
                      value={brokerName}
                      onChange={(...args) => {
                        updateForm(args[0]);
                      }}
                    />
                  </label>
                  <AlertRow>
                    <RequiredFlag aria-hidden={!errors.brokerName} error={errors.brokerName}>
                      REQUIRED
                    </RequiredFlag>
                  </AlertRow>
                </FormRow>
                <FormRow>
                  <label className="input-label" htmlFor="brokerFirm">
                    BROKER FIRM*
                    <AutoComplete
                      name="brokerFirm"
                      id="brokerFirm"
                      type="text"
                      value={brokerFirm}
                      onChangeCallback={(...args) => {
                        updateForm(args[0]);
                      }}
                    />
                  </label>
                  <AlertRow>
                    <RequiredFlag aria-hidden={!errors.brokerFirm} error={errors.brokerFirm}>
                      REQUIRED
                    </RequiredFlag>
                  </AlertRow>
                </FormRow>
              </>
            ) : (
              ''
            )}
          </>
        )}
        <button type="submit">SUBMIT</button>
      </RegisterForm>
    </>
  );
};

export default MobileForm;
