/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import Context from '../../config/Context';
import { routes } from '../../data/routes';
import InstagramIcon from '../utils/instagram-icon';
import variables from '../../styles/Variables';
import { mediaMin } from '../../styles/MediaQueries';

const DesktopLinksContainer = styled.nav`
  display: none;
  ${mediaMin.tabletLandscape`
z-index: 100;
padding 64px 44px ;
display: block;
opacity: ${(props) => (props.open ? '1' : '0')};
visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
transition: all 0.5s ease-in-out;
overflow: hidden;
ul {
  margin: 0 0 16px 0;
  padding: 0;
  list-style-type: none;
  &:last-child {
    margin: 0;
  }
  li {
    a {
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 2px;
      margin-bottom: 8px;
      color: ${(props) => (props.white ? '#fff' : '#000')};;
      &:hover {
        text-decoration: none;
        color: ${variables.colors.brightRed};
        svg{
          .path{
            fill: ${variables.colors.darkRed};
          }
        }
      }
    }
    a.active {
      color: ${variables.colors.darkRed};
    }
  }
}
`}
`;

const generateRoutes = (context) => {
  const parsedRoutes = [];
  routes.forEach((section) => {
    const tempSection = [];
    section.forEach((el) => {
      tempSection.push(
        <li key={el.text}>
          <NavLink
            onClick={() => {
              context.closeOverlays();
            }}
            activeClassName="active"
            to={`/${el.path}`}
          >
            {el.text}
          </NavLink>
        </li>,
      );
    });
    parsedRoutes.push(<ul key={tempSection}>{tempSection}</ul>);
  });
  return parsedRoutes;
};

const DesktopLinks = ({ pathname }) => {
  const context = useContext(Context);
  const { navigationState } = context;

  return (
    <DesktopLinksContainer white={pathname === '/penthouses'} open={navigationState}>
      {generateRoutes(context)}
      <ul>
        <li>
          <a
            href="https://www.instagram.com/madisonhousenyc/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon dark={pathname !== '/penthouses'} />
          </a>
        </li>
      </ul>
    </DesktopLinksContainer>
  );
};

export default DesktopLinks;
