/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useContext, useRef } from 'react';
import styled from 'styled-components';

import Context from '../../config/Context';
import { mediaMin } from '../../styles/MediaQueries';
import variables from '../../styles/Variables';
import { FilterCheckbox } from '../utils/checkbox';

const FilterMenuContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 330px;
  padding: 32px;
  background-color: #fff;
  border: 1px solid ${variables.colors.darkRed};
  opacity: ${(props) => (props.active ? '1' : '0')};
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
  position: absolute;
  width: calc(100% - 64px);
  margin: 0 32px;
  top: 193px;
  left: 0;
  right: 0;
  z-index: 100;
  ${mediaMin.tablet`
    width: calc(48% - 30px);
    left: unset;
  `}
  ${mediaMin.tabletLandscape`
  width: 330px;
  position: fixed;
  top: 24px;
  right: 44px;
  margin: 0;
  `}
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 48px;
  h5 {
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 16px;
    color: ${variables.colors.darkRed};
    margin: 0 0 0 auto;
  }
`;

const CloseButton = styled.button`
  top: 24px;
  right: 24px;
  z-index: 150;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0 0 0 auto;
  background-color: transparent;
  &:focus {
    outline: none;
  }
`;

const FilterRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const FilterOptionCol = styled.div`
  display: flex;
  flex-direction: column;
  .form-title {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 2px;
    line-height: 16px;
    color: #1a1919;
    margin-bottom: 24px;
    opacity: 0.5;
  }
  .form-options {
    display: flex;
    flex-direction: column;
    .pretty {
      margin-bottom: 24px;
    }
  }
`;

const FilterOptionRow = styled.div`
  display: flex;
  flex-direction: column;
  .form-title {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 2px;
    line-height: 16px;
    color: #1a1919;
    margin-bottom: 24px;
    opacity: 0.5;
  }
  .form-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .pretty {
      margin-bottom: 24px;
    }
  }
`;

const ApplyButton = styled.button`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: ${variables.colors.darkRed};
  font-size: 14px;
  letter-spacing: 1.75px;
  line-height: 16px;
  margin-bottom: 24px;
  cursor: pointer;
`;

const ClearButton = styled.button`
  width: fit-content;
  margin: 0 auto;
  font-size: 14px;
  letter-spacing: 1.75px;
  line-height: 16px;
  padding: 4px 0;
  color: ${variables.colors.darkRed};
  border: none;
  border-bottom: 1px solid ${variables.colors.darkRed};
  cursor: pointer;
  background-color: transparent;
`;

const FilterMenu = ({ filterAvailabilityData, renderClearedFilters }) => {
  const context = useContext(Context);
  const { filterActive, toggleFilterActive, clearFilters } = context;

  const optionsRefOne = useRef(null);
  const optionsRefTwo = useRef(null);
  const optionsRefThree = useRef(null);
  const refArray = [optionsRefOne, optionsRefTwo, optionsRefThree];

  const uncheckAll = () => {
    refArray.forEach((refEl) => {
      for (let idx = 0; idx < refEl.current.children.length; idx += 1) {
        refEl.current.children[idx].firstChild.checked = false;
      }
    });
  };

  return (
    <FilterMenuContainer active={filterActive}>
      <TitleRow>
        <h5>FILTER</h5>
        <CloseButton
          onClick={(e) => {
            e.preventDefault();
            toggleFilterActive();
          }}
        >
          <img src="/images/logos/close.svg" alt="close filters" />
        </CloseButton>
      </TitleRow>
      <FilterRow>
        <FilterOptionCol>
          <div className="form-title">BEDS</div>
          <div className="form-options" ref={optionsRefOne}>
            <FilterCheckbox filterKey="beds" filterValue="1" />
            <FilterCheckbox filterKey="beds" filterValue="2" />
            <FilterCheckbox filterKey="beds" filterValue="3" />
            <FilterCheckbox filterKey="beds" filterValue="4" />
            <FilterCheckbox filterKey="beds" filterValue="5" />
            <FilterCheckbox filterKey="beds" filterValue="PH" />
          </div>
        </FilterOptionCol>
        <FilterOptionCol>
          <div className="form-title">PRICE</div>
          <div className="form-options" ref={optionsRefTwo}>
            <FilterCheckbox filterKey="price" filterValue="$1-3 M" />
            <FilterCheckbox filterKey="price" filterValue="$3-5 M" />
            <FilterCheckbox filterKey="price" filterValue="$5-7 M" />
            <FilterCheckbox filterKey="price" filterValue="$7-10 M" />
            <FilterCheckbox filterKey="price" filterValue="$10-15 M" />
            <FilterCheckbox filterKey="price" filterValue="$15 M+" />
          </div>
        </FilterOptionCol>
      </FilterRow>
      <FilterOptionRow>
        <div className="form-title">EXPOSURES</div>
        <div className="form-options" ref={optionsRefThree}>
          <FilterCheckbox filterKey="exposures" filterValue="N" />
          <FilterCheckbox filterKey="exposures" filterValue="S" />
          <FilterCheckbox filterKey="exposures" filterValue="E" />
          <FilterCheckbox filterKey="exposures" filterValue="W" />
        </div>
      </FilterOptionRow>
      <ApplyButton
        onClick={(e) => {
          e.preventDefault();
          filterAvailabilityData();
        }}
      >
        APPLY
      </ApplyButton>
      <ClearButton
        onClick={(e) => {
          e.preventDefault();
          clearFilters();
          uncheckAll();
          toggleFilterActive();
          renderClearedFilters();
        }}
      >
        CLEAR
      </ClearButton>
    </FilterMenuContainer>
  );
};

export default FilterMenu;
