import { createGlobalStyle } from 'styled-components';
import { mediaMin } from '../MediaQueries';

const ModalStyles = createGlobalStyle`
  .modal{
    width: 90%;
    ${mediaMin.tabletLandscape`
      width: auto;
    `}
  }
  .ReactModal__Overlay {
    opacity: 0;
    
  }

  .ReactModal__Overlay--after-open{
      opacity: 1;
  }

  .ReactModal__Overlay--before-close{
      opacity: 0;
      transition: opacity 500ms ease-in-out;
  }

  .ReactModal__Content {
    width: 85%;
    ${mediaMin.tabletLandscape`
      width: 70%;
    `}
    ${mediaMin.desktopSmall`
      width: 50%;
    `}
  }
`;

export default ModalStyles;
