/* eslint-disable no-restricted-globals */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import variables from '../../styles/Variables';
import { mediaMin } from '../../styles/MediaQueries';

const PageNavigationButtons = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${variables.colors.brightRed};
  transition: transform 0.25s ease-in-out;
  z-index: 99;
  height: 64px;
  transform: ${(props) => (props.mobileCompleted ? 'translate(0)' : 'translate(0, 64px)')};
  ${mediaMin.tabletLandscape`
    height: 40px;
    transform: ${(props) => (props.completed ? 'translate(0)' : 'translate(0, 40px)')};
  `}
  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    &:hover {
      text-decoration: none;
    }
    span {
      font-size: 16px;
      letter-spacing: 2px;
      line-height: 16px;
      color: #fff;
    }
    .desktop-span {
      display: none;
      ${mediaMin.tabletLandscape`
      display: block;
      `}
    }
    .mobile-span {
      display: block;
      ${mediaMin.tabletLandscape`
      display: none;
      `}
    }
  }
  .next-page-link {
    margin-right: 36px;
    ${mediaMin.tabletLandscape`
    margin-right: 44px;
    `}
    img {
      margin-left: 16px;
      ${mediaMin.tabletLandscape`
      margin-left: 24px;
      height: 50%;
      `}
    }
  }
  .prev-page-link {
    margin-left: 36px;
    ${mediaMin.tabletLandscape`
    margin-left: 44px;
    `}
    img {
      margin-right: 16px;
      ${mediaMin.tabletLandscape`
      margin-right: 24px;
      height: 50%;
      `}
    }
  }
`;

const Spacer = styled.div``;

const ProgressBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: red;
  width: ${(props) => `${props.progress}%`};
  transition: width 0.05s ease-in-out;
  z-index: 9;
  height: 2px;
  ${mediaMin.tabletLandscape`
    height: 3px;
  `}
`;

const PageNavigation = ({
  nextPath, nextPage, prevPath, prevPage,
}) => {
  const [scrollProgress, setScrollProgress] = useState(0);

  const updateScrollProgress = () => {
    const { documentElement, scrollingElement } = document;
    const scrollPx = documentElement.scrollTop || scrollingElement.scrollTop;
    const winHeightPx = documentElement.scrollHeight - documentElement.clientHeight;
    let scrolled = (scrollPx / winHeightPx) * 100;
    if (isNaN(scrolled) && nextPage !== 'NOMAD STORY') {
      scrolled = 100;
    }
    setScrollProgress(scrolled);
  };

  useEffect(() => {
    updateScrollProgress();
    window.addEventListener('scroll', updateScrollProgress);
    return () => {
      window.removeEventListener('scroll', updateScrollProgress);
    };
  }, []);

  return (
    <>
      <PageNavigationButtons
        completed={scrollProgress === 100}
        mobileCompleted={scrollProgress >= 95}
      >
        {prevPage && prevPath ? (
          <Link className="prev-page-link" to={prevPath}>
            <img src="/images/logos/nav-arrow-left.svg" alt="Arrow to previous page" />
            <span className="desktop-span">{prevPage}</span>
            <span className="mobile-span">PREV</span>
          </Link>
        ) : (
          <Spacer />
        )}
        {nextPage && nextPath ? (
          <Link className="next-page-link" to={nextPath}>
            <span className="desktop-span">{nextPage}</span>
            <span className="mobile-span">NEXT</span>
            <img src="/images/logos/nav-arrow-right.svg" alt="Arrow to next page" />
          </Link>
        ) : (
          <Spacer />
        )}
      </PageNavigationButtons>
      <ProgressBar progress={scrollProgress} />
    </>
  );
};

export default PageNavigation;
