import React from 'react';
import MediaQuery from 'react-responsive';
import { Helmet } from 'react-helmet';

import ServicesDesktop from '../components/services/services-desktop';
import ServicesMobile from '../components/services/services-mobile';
import PageNavigation from '../components/utils/page-navigation';
import PhotoViewer from '../components/photo-viewer';
import { servicesPhotos } from '../data/services';

const Services = () => (
  <>
    <Helmet>
      <title>NoMad New Condominium Services | Madison House</title>
      <meta
        name="description"
        content="Gachot brings its experience with international hotel design to Madison House's 30,000 square feet of club style amenities spaces."
      />
    </Helmet>
    <MediaQuery minWidth={1024}>
      <ServicesDesktop />
    </MediaQuery>
    <MediaQuery maxWidth={1023}>
      <ServicesMobile />
    </MediaQuery>
    <PageNavigation
      prevPath="/nomad-new-condominium-availability"
      prevPage="AVAILABILITY"
      nextPage="NOMAD STORY"
      nextPath="/nomad-story"
    />
    <PhotoViewer photoArray={servicesPhotos} />
  </>
);

export default Services;
