/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useContext, useState, useRef, useEffect,
} from 'react';
import Fade from 'react-reveal/Fade';
import { Transition } from 'react-transition-group';

import Context from '../../config/Context';
import {
  MainCol,
  MainRow,
  PaddingColumn,
  ImageColumn,
  TextColumn,
  ImageSection,
  TextSection,
  CopyBody,
  CopyHeading,
  defaultStyle,
  bodyTransitionStyles,
  headingTransitionStyles,
} from '../utils/desktop-columns';
import {
  HeaderCol,
  SubHeaderRow,
  HeaderLogo,
  GrayBackground,
  SubHeaderLeftCol,
  SubHeaderRightCol,
  HeroImage,
} from '../utils/page-header';
import ResponsiveImage from '../utils/responsive-image';
import { amenitiesCopy } from '../../data/amenities';

const AmenitiesDesktop = () => {
  const context = useContext(Context);
  const { togglePhotoViewerState } = context;
  const [activeSection, setActiveSection] = useState(0);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [heroImageHeight, setHeroImageHeight] = useState(0);
  const [heroImageWidth, setHeroImageWidth] = useState(0);
  const heroImageRef = useRef(null);
  const sectionRef1 = useRef(null);
  const sectionRef2 = useRef(null);
  const sectionRef3 = useRef(null);
  const sectionRef4 = useRef(null);
  const sectionRef5 = useRef(null);
  const sectionRef6 = useRef(null);
  const sectionRef7 = useRef(null);
  const sectionRef8 = useRef(null);
  const sectionRef9 = useRef(null);
  const sectionRef10 = useRef(null);
  const sectionRef11 = useRef(null);
  const sectionRef12 = useRef(null);
  const sectionRef13 = useRef(null);
  const sectionRef14 = useRef(null);
  const refArray = [
    sectionRef1, sectionRef2, sectionRef3, sectionRef4, sectionRef5,
    sectionRef6, sectionRef7, sectionRef8, sectionRef9, sectionRef10,
    sectionRef11, sectionRef12, sectionRef13, sectionRef14
  ];

  const handleResize = () => {
    if (heroImageRef.current !== null) {
      setHeroImageHeight(heroImageRef.current.height);
      setHeroImageWidth(heroImageRef.current.width);
    }
  };

  const handleScroll = () => {
    refArray.forEach((ref, idx) => {
      const top = ref.current.offsetTop;
      const bottom = ref.current.offsetTop + ref.current.offsetHeight + 120;
      const middle = ref.current.offsetTop + ref.current.offsetHeight / 2;
      const middleWindow = 64 + window.scrollY;
      if (idx === 0 && middleWindow < top) {
        setActiveSection(0);
      } else if (activeSection === 0 && idx === 0 && middleWindow >= middle) {
        setActiveSection(1);
      } else if (middleWindow >= top && middleWindow < bottom) {
        setActiveSection(idx + 1);
      }
    });
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    return () => {
      document.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  });

  useEffect(() => {
    setTimeout(() => {
      if (heroImageRef.current !== null) {
        setHeroImageHeight(heroImageRef.current.height);
        setHeroImageWidth(heroImageRef.current.width);
      }
    }, 500);
  }, [heroImageRef.current]);

  return (
    <MainCol>
      <HeaderCol>
        <HeaderLogo />
        <SubHeaderRow>
          <SubHeaderLeftCol contentLoaded={contentLoaded} arrowLocation={heroImageHeight}>
            <h2>{amenitiesCopy.desktop.heading}</h2>
            <img src="/images/logos/nav-arrow-down-red.svg" alt="continue scrolling" />
          </SubHeaderLeftCol>
          <SubHeaderRightCol contentLoaded={contentLoaded} imageWidth={heroImageWidth}>
            <HeroImage>
              <ResponsiveImage
                onClickProp={() => {
                  togglePhotoViewerState(0);
                }}
                srcPath="/images/amenities/amenities_1"
                refProp={heroImageRef}
                onLoadProp={() => {
                  setContentLoaded(true);
                }}
              />
            </HeroImage>
            <p>{amenitiesCopy.desktop.subHeading}</p>
          </SubHeaderRightCol>
        </SubHeaderRow>
        <GrayBackground contentLoaded={contentLoaded} backgroundHeight={heroImageHeight} />
      </HeaderCol>
      <MainRow>
        <PaddingColumn />
        <TextColumn>
          <TextSection activeSection={activeSection}>
            <Transition timeout={0} in={activeSection <= 3}>
              {(state) => (
                <CopyHeading style={{ ...defaultStyle, ...headingTransitionStyles[state] }}>
                  {amenitiesCopy.desktop.sectionOneTitle}
                </CopyHeading>
              )}
            </Transition>
            <Transition timeout={0} in={activeSection <= 3}>
              {(state) => (
                <CopyBody style={{ ...defaultStyle, ...bodyTransitionStyles[state] }}>
                  {amenitiesCopy.desktop.sectionOneText}
                </CopyBody>
              )}
            </Transition>
          </TextSection>
          <TextSection activeSection={activeSection}>
            <Transition timeout={0} in={activeSection > 3 && activeSection <= 7}>
              {(state) => (
                <CopyHeading style={{ ...defaultStyle, ...headingTransitionStyles[state] }}>
                  {amenitiesCopy.desktop.sectionTwoTitle}
                </CopyHeading>
              )}
            </Transition>
            <Transition timeout={0} in={activeSection > 3 && activeSection <= 7}>
              {(state) => (
                <CopyBody style={{ ...defaultStyle, ...bodyTransitionStyles[state] }}>
                  {amenitiesCopy.desktop.sectionTwoText}
                </CopyBody>
              )}
            </Transition>
          </TextSection>
          <TextSection activeSection={activeSection}>
            <Transition timeout={0} in={activeSection > 7 && activeSection <= 13}>
              {(state) => (
                <CopyHeading style={{ ...defaultStyle, ...headingTransitionStyles[state] }}>
                  {amenitiesCopy.desktop.sectionThreeTitle}
                </CopyHeading>
              )}
            </Transition>
            <Transition timeout={0} in={activeSection > 7 && activeSection <= 13}>
              {(state) => (
                <CopyBody style={{ ...defaultStyle, ...bodyTransitionStyles[state] }}>
                  {amenitiesCopy.desktop.sectionThreeText}
                </CopyBody>
              )}
            </Transition>
          </TextSection>

          <TextSection activeSection={activeSection}>
            <Transition timeout={0} in={activeSection === 9}>
              {(state) => (
                <CopyHeading style={{ ...defaultStyle, ...headingTransitionStyles[state] }}>
                  {amenitiesCopy.desktop.sectionFourTitle}
                </CopyHeading>
              )}
            </Transition>
            <Transition timeout={0} in={activeSection === 9}>
              {(state) => (
                <CopyBody style={{ ...defaultStyle, ...bodyTransitionStyles[state] }}>
                  {amenitiesCopy.desktop.sectionFourText}
                </CopyBody>
              )}
            </Transition>
          </TextSection>

          <TextSection activeSection={activeSection}>
            <Transition timeout={0} in={activeSection === 10}>
              {(state) => (
                <CopyHeading style={{ ...defaultStyle, ...headingTransitionStyles[state] }}>
                  {amenitiesCopy.desktop.sectionFiveTitle}
                </CopyHeading>
              )}
            </Transition>
            <Transition timeout={0} in={activeSection === 10}>
              {(state) => (
                <CopyBody style={{ ...defaultStyle, ...bodyTransitionStyles[state] }}>
                  {amenitiesCopy.desktop.sectionFiveText}
                </CopyBody>
              )}
            </Transition>
          </TextSection>
          <TextSection activeSection={activeSection}>
            <Transition timeout={0} in={activeSection === 11}>
              {(state) => (
                <CopyHeading style={{ ...defaultStyle, ...headingTransitionStyles[state] }}>
                  {amenitiesCopy.desktop.sectionSixTitle}
                </CopyHeading>
              )}
            </Transition>
            <Transition timeout={0} in={activeSection === 11}>
              {(state) => (
                <CopyBody style={{ ...defaultStyle, ...bodyTransitionStyles[state] }}>
                  {amenitiesCopy.desktop.sectionSixText}
                </CopyBody>
              )}
            </Transition>
          </TextSection>
          <TextSection activeSection={activeSection}>
            <Transition timeout={0} in={activeSection > 13 && activeSection <= 14}>
              {(state) => (
                <CopyHeading style={{ ...defaultStyle, ...headingTransitionStyles[state] }}>
                  {amenitiesCopy.desktop.sectionSevenTitle}
                </CopyHeading>
              )}
            </Transition>
            <Transition timeout={0} in={activeSection > 13 && activeSection <= 14}>
              {(state) => (
                <CopyBody style={{ ...defaultStyle, ...bodyTransitionStyles[state] }}>
                  {amenitiesCopy.desktop.sectionSevenText}
                </CopyBody>
              )}
            </Transition>
          </TextSection>
        </TextColumn>
        <ImageColumn>
          <ImageSection ref={sectionRef1}>
            <Fade>
              <ResponsiveImage
                onClickProp={() => {
                  togglePhotoViewerState(1);
                }}
                srcPath="/images/amenities/amenities_2"
              />
            </Fade>
          </ImageSection>
          <ImageSection ref={sectionRef2}>
            <Fade>
              <ResponsiveImage
                onClickProp={() => {
                  togglePhotoViewerState(2);
                }}
                srcPath="/images/amenities/amenities_2_3"
              />
            </Fade>
          </ImageSection>
          <ImageSection ref={sectionRef3}>
            <Fade>
              <ResponsiveImage
                onClickProp={() => {
                  togglePhotoViewerState(3);
                }}
                srcPath="/images/amenities/amenities_2_2"
              />
            </Fade>
          </ImageSection>
          <ImageSection ref={sectionRef4}>
            <Fade>
              <ResponsiveImage
                onClickProp={() => {
                  togglePhotoViewerState(4);
                }}
                srcPath="/images/amenities/amenities_3"
              />
            </Fade>
          </ImageSection>
          <ImageSection ref={sectionRef5}>
            <Fade>
              <ResponsiveImage
                onClickProp={() => {
                  togglePhotoViewerState(5);
                }}
                srcPath="/images/amenities/amenities_4"
              />
            </Fade>
          </ImageSection>
          <ImageSection ref={sectionRef6}>
            <Fade>
              <ResponsiveImage
                onClickProp={() => {
                  togglePhotoViewerState(6);
                }}
                srcPath="/images/amenities/amenities_5"
              />
            </Fade>
          </ImageSection>
          <ImageSection ref={sectionRef7}>
            <Fade>
              <ResponsiveImage
                onClickProp={() => {
                  togglePhotoViewerState(7);
                }}
                srcPath="/images/amenities/amenities_10"
              />
            </Fade>
          </ImageSection>
          <ImageSection ref={sectionRef8}>
            <Fade>
              <ResponsiveImage
                onClickProp={() => {
                  togglePhotoViewerState(8);
                }}
                srcPath="/images/amenities/amenities_5_2"
              />
            </Fade>
          </ImageSection>
          <ImageSection ref={sectionRef9}>
            <Fade>
              <ResponsiveImage
                onClickProp={() => {
                  togglePhotoViewerState(9);
                }}
                srcPath="/images/amenities/amenities_6"
              />
            </Fade>
          </ImageSection>
          <ImageSection ref={sectionRef10} portrait>
            <Fade>
              <ResponsiveImage
                onClickProp={() => {
                  togglePhotoViewerState(10);
                }}
                srcPath="/images/amenities/amenities_7"
              />
            </Fade>
          </ImageSection>
          <ImageSection ref={sectionRef11}>
            <Fade>
              <ResponsiveImage
                onClickProp={() => {
                  togglePhotoViewerState(11);
                }}
                srcPath="/images/amenities/amenities_8"
              />
            </Fade>
          </ImageSection>
          <ImageSection ref={sectionRef12}>
            <Fade>
              <ResponsiveImage
                onClickProp={() => {
                  togglePhotoViewerState(12);
                }}
                srcPath="/images/amenities/amenities_9"
              />
            </Fade>
          </ImageSection>
          <ImageSection ref={sectionRef13} portrait>
            <Fade>
              <ResponsiveImage
                onClickProp={() => {
                  togglePhotoViewerState(13);
                }}
                srcPath="/images/amenities/amenities_11"
              />
            </Fade>
          </ImageSection>
          <ImageSection ref={sectionRef14}>
            <Fade>
              <ResponsiveImage
                onClickProp={() => {
                  togglePhotoViewerState(14);
                }}
                srcPath="/images/amenities/amenities_12"
              />
            </Fade>
          </ImageSection>
        </ImageColumn>
      </MainRow>
    </MainCol>
  );
};

export default AmenitiesDesktop;
