/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useContext, useRef } from 'react';
import styled from 'styled-components';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

import variables from '../../styles/Variables';
import Context from '../../config/Context';

const FilterCheckboxContainer = styled.div`
  .form-label-span {
    color: #1a1919;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 1.75px;
    line-height: 16px;
    margin-left: ${(props) => (props.margin ? '16px' : '0')};
  }
  .p-success {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    top: 0;
    .icon {
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      width: 100% !important;
      height: 100% !important;
      stroke: #fff !important;
    }
  }
`;

const FilterCheckbox = ({ filterKey, filterValue }) => {
  const context = useContext(Context);
  const { filters, updateFilters } = context;
  return (
    <FilterCheckboxContainer margin className="pretty p-icon p-smooth">
      <input
        onClick={() => {
          updateFilters(filterKey, filterValue);
        }}
        defaultChecked={filters[filterKey].has(filterValue)}
        className="form-option"
        type="checkbox"
        value={filterValue}
      />
      <div className="state p-danger-o">
        <Icon className="icon mdi mdi-close" path={mdiClose} />
        <label>
          <span className="form-label-span">{filterValue}</span>
        </label>
      </div>
    </FilterCheckboxContainer>
  );
};

const ShareCheckboxContainer = styled.div`
  width: 16px;
  height: 16px;
  border: 1px solid #d7d7d7;
  background-color: #fff;
  .form-label-span {
    color: #1a1919;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 1.75px;
    line-height: 16px;
    margin-left: ${(props) => (props.margin ? '16px' : '0')};
  }
  .p-success {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    top: 0;
    background-color: ${(props) => (props.hasCheck ? variables.colors.brightRed : '#fff')};
    transition: background-color: .5s ease-in-out;
    .svg-icon {
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      width: 100% !important;
      height: 100% !important;
      stroke: #fff !important;
    }
  }
`;

const ShareCheckbox = ({ shareValue }) => {
  const context = useContext(Context);
  const checkboxRef = useRef(null);

  const { updateSharedListings, sharedListings } = context;
  return (
    <ShareCheckboxContainer
      hasCheck={sharedListings.has(shareValue)}
      className="pretty p-curve p-svg"
    >
      <input
        ref={checkboxRef}
        onClick={() => {
          if (sharedListings.size >= 4 && checkboxRef.current.checked) {
            checkboxRef.current.checked = false;
          } else {
            updateSharedListings(shareValue);
          }
        }}
        defaultChecked={sharedListings.has(shareValue)}
        className="form-option"
        type="checkbox"
        value={shareValue}
      />
      <div className="state p-success">
        <svg className="svg svg-icon" viewBox="0 0 20 20">
          <path
            d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
            style={{ stroke: 'white', fill: 'white' }}
          />
        </svg>
      </div>
    </ShareCheckboxContainer>
  );
};

const FormCheckboxContainer = styled.div`
  .form-label-span {
    color: #1a1919;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 1.75px;
    line-height: 16px;
    margin-left: 8px;
  }
  .p-success {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    top: 0;
    .icon {
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      width: 100% !important;
      height: 100% !important;
      stroke: #fff !important;
      height: 16px !important;
      width: 16px !important;
    }
  }
`;

const FormCheckbox = ({ checkValue, checkLabel, onChange }) => (
  <FormCheckboxContainer margin className="pretty p-icon p-smooth">
    <input onClick={onChange} className="form-option" type="checkbox" value={checkValue} />
    <div className="state p-danger-o">
      <Icon className="icon mdi mdi-close" path={mdiClose} />
      <label>
        <span className="form-label-span small-black">{checkLabel}</span>
      </label>
    </div>
  </FormCheckboxContainer>
);

export { FilterCheckbox, ShareCheckbox, FormCheckbox };
