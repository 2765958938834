/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
} from 'react';
import styled from 'styled-components';
import Swiper from 'react-id-swiper';
import { Link } from 'react-router-dom';

import Context from '../../config/Context';
import variables from '../../styles/Variables';
import { mediaMin } from '../../styles/MediaQueries';
import HorizontalPageNavigation from '../utils/horizontal-page-navigation';
import ResponsiveImage from '../utils/responsive-image';

const StoryColumn = styled.div`
  display: flex !important;
  flex-direction: column;
  height: 100%;
  max-height: 100vh;
  box-sizing: border-box;
  justify-content: ${(props) => (props['justify-content'] ? props['justify-content'] : 'flex-start')};
  align-items: ${(props) => (props['align-items'] ? props['align-items'] : 'start')};
  min-width: ${(props) => props.width};
  .child {
    opacity: ${(props) => (props.revealed && props.contentLoaded ? '1' : '0')};
    transform: ${(props) => (props.revealed && props.contentLoaded
    ? 'translateY(0)'
    : 'translateY(48px)')};
    transition: opacity 0.75s ease-out, transform 1.5s ease-out;
    &:nth-child(1) {
      transition-delay: 0;
    }
    &:nth-child(2) {
      transition-delay: 0.75s;
    }
    &:nth-child(3) {
      transition-delay: 1.5s;
    }
  }
  img.quarter-height {
    max-height: 25%;
  }
  img.half-height {
    max-height: 50%;
  }
  img.three-quarter-height {
    max-height: 75%;
  }
  img.full-height {
    max-height: unset;
  }
  img.padding-bottom {
    padding-bottom: 5%;
  }
  img.vertical-center {
    margin: auto 0;
  }
  .next-arrow {
    cursor: pointer;
  }
`;

const RedHeading = styled.h3`
  font-family: AWConquerorProDidotL-Regular;
  font-size: 64px;
  line-height: 64px;
  color: ${variables.colors.darkRed};
  margin: 0;
`;

const Caption = styled.p`
  font-family: 'Granjon';
  font-size: 20px;
  line-height: 28px;
  color: ${variables.colors.darkGray};
  width: 100%;
  padding: ${(props) => (props.padding ? '10%' : '0')};
  ${(props) => (props.paddingLeft ? 'padding-left: 50%;' : '')}
  ${(props) => (props.paddingRight ? 'padding-right: 50%;' : '')}
`;

const FixedHeader = styled.div`
  display: none;
  ${mediaMin.tabletLandscape`
    width: 100%
    position: fixed;
    display: flex;
    justify-content: center;
    top: 24px;
    height: 56px;
    z-index: 11;
    transition: opacity .5s ease-in-out, visibility .5s ease-in-out;
    a{
        height: 100%;
        width: fit-content;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
  `}
`;

const Fader = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  opacity: ${(props) => (props.active ? '.75' : '0')};
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  z-index: 10;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
`;

const HeaderLogo = () => (
  <FixedHeader>
    <Link className="header-logo-link" to="/">
      <img src="/images/logos/madison-house-gray.svg" alt="Madison House" />
    </Link>
  </FixedHeader>
);

const setImageWidth = (dimX, dimY) => {
  const slideHeight = window.innerHeight - 120;
  return `${(dimX * slideHeight) / dimY}px`;
};

const setFullWidth = (dimX, dimY) => {
  const slideHeight = window.innerHeight;
  return `${(dimX * slideHeight) / dimY}px`;
};

const DesktopStory = () => {
  const context = useContext(Context);
  const { navigationState } = context;
  const [swiper, setSwiper] = useState(null);
  const [swiperIndex, setSwiperIndex] = useState(2);
  const [contentLoaded, setContentLoaded] = useState(false);

  const swiperSettings = {
    freeMode: true,
    freeModeMomentum: true,
    mousewheel: { sensitivity: 0.2 },
    freeModeMomentumRatio: 1.5,
    slidesPerView: 'auto',
    spaceBetween: 100,
    getSwiper: setSwiper,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 3,
    },
  };

  const updateIndex = useCallback(() => {
    setSwiperIndex(swiper.realIndex);
  }, [swiper]);

  useEffect(() => {
    if (swiper !== null) {
      swiper.on('slideChange', updateIndex);
    }

    return () => {
      if (swiper !== null) {
        swiper.off('slideChange', updateIndex);
      }
    };
  }, [swiper, updateIndex]);

  const isVisible = (idx) => {
    if (idx <= swiperIndex + 2 && idx >= swiperIndex - 2) {
      return true;
    }
    return false;
  };

  return (
    <>
      <HeaderLogo />
      <Fader active={navigationState} />
      <Swiper {...swiperSettings}>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(0)}
          style={{ width: '300px' }}
        />
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(1)}
          style={{ width: '680px' }}
        >
          <ResponsiveImage
            srcPath="/images/nomad_story/story_1"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child half-height child"
            onLoadProp={() => {
              setContentLoaded(true);
            }}
          />
          <RedHeading className="child">
            NOMAD IS THE
            <br />
            MIDDLE OF WHERE
            <br />
            MANHATTAN
            <br />
            MATTERS
          </RedHeading>
          <img
            src="/images/logos/nav-arrow-right-red.svg"
            alt="continue scrolling"
            className="vertical-center child next-arrow"
            onClick={() => {
              if (swiper) {
                swiper.slideTo(2, 1000);
              }
            }}
          />
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(2)}
          style={{ width: setImageWidth(1335, 2000) }}
          justify-content="flex-end"
        >
          <ResponsiveImage
            srcPath="/images/nomad_story/story_2"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child"
          />
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(3)}
          style={{ width: '400px' }}
          align-items="center"
          justify-content="space-between"
        >
          <Caption className="child" padding>
            NoMad sits in the heart of what matters about Manhattan—the fashion
            and design districts, the river parks to east and west, the
            financial and media hubs to south and north. At the center of all
            the spokes, NoMad is now the destination for cool-hunters in search
            of great food, cutting-edge style, art happenings, and the latest in
            design.
          </Caption>
          <ResponsiveImage
            srcPath="/images/nomad_story/story_3"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child half-height"
          />
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(4)}
          style={{ width: setImageWidth(1154, 1500) }}
          justify-content="flex-end"
        >
          <ResponsiveImage
            srcPath="/images/nomad_story/story_4"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child"
          />
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(5)}
          style={{ width: '380px' }}
        >
          <ResponsiveImage
            srcPath="/images/nomad_story/story_5"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child half-height"
          />
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(6)}
          style={{ width: '280px' }}
        >
          <Caption className="child">
            NoMad is graced with some of the most recognizable artifacts of New
            York design and architectural history, from Madison Square Park to
            the iconic Empire State Building. With its visual marvels anchoring
            the neighborhood, NoMad has burst into a whole new life.
          </Caption>
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(7)}
          style={{ width: '680px' }}
        >
          <ResponsiveImage
            srcPath="/images/nomad_story/story_6"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child half-height"
          />
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(8)}
          style={{ width: '680px' }}
        >
          <ResponsiveImage
            srcPath="/images/nomad_story/story_7"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child half-height"
          />
          <Caption className="child">
            When the Ace Hotel opened, it drew trendsetters who came for the
            Michelin-starred dining, the ever lively lobby, and the art
            installations and literary events. That spirit has now filled the
            neighborhood, attracting creatives to launch boutiques, eateries,
            and nightspots.
          </Caption>
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(9)}
          style={{ width: '680px' }}
        >
          <ResponsiveImage
            srcPath="/images/nomad_story/story_8"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child half-height"
          />
          <RedHeading className="child">
            A BEAUTY BUILT UP
            <br />
            OVER CENTURIES,
            <br />
            RIGHT TO THE NOW
          </RedHeading>
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(10)}
          style={{ width: setImageWidth(1556, 2000) }}
        >
          <ResponsiveImage
            srcPath="/images/nomad_story/story_9"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child"
          />
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(11)}
          style={{ width: '400px' }}
          justify-content="center"
        >
          <ResponsiveImage
            srcPath="/images/nomad_story/story_10"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child three-quarter-height"
          />
          <Caption className="child">
            The beauty of NoMad peeks above rooftops, reveals itself around
            every corner and has called out to visual connoisseurs in New York
            City for centuries. These architectural gems are now home to much of
            the great dining and social activity in a neighborhood alive with
            what makes a city great.
          </Caption>
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(12)}
          style={{ width: '240px' }}
        >
          <Caption className="child">
            The Art Deco Chrysler building stands proudly in the northern NoMad
            skyline. The beloved Flatiron building, New York City’s oldest
            skyscraper, marks the flowing movement on Broadway and Fifth Avenue.
          </Caption>
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(13)}
          style={{ width: setImageWidth(1334, 2000) }}
          justify-content="flex-end"
        >
          <ResponsiveImage
            srcPath="/images/nomad_story/story_11"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child"
          />
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(14)}
          style={{ width: '680px' }}
        >
          <ResponsiveImage
            srcPath="/images/nomad_story/story_14"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child half-height"
          />
          <Caption className="child">
            Not all is bustle: Madison Square Park is not just a stage for event
            programming but six acres of lush greenery that can be enjoyed for
            picnics, reading, or simply lying out to enjoy the sun and breezes.
          </Caption>
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(15)}
          style={{ width: '680px' }}
          justify-content="space-between"
        >
          <ResponsiveImage
            srcPath="/images/nomad_story/story_13"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child half-height padding-bottom"
          />
          <ResponsiveImage
            srcPath="/images/nomad_story/story_12"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child half-height"
          />
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(16)}
          style={{ width: '680px' }}
        >
          <ResponsiveImage
            srcPath="/images/nomad_story/story_15"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child quarter-height"
          />
          <RedHeading className="child">
            DINING GETS
            <br />
            THE NOMAD
            <br />
            TOUCH
          </RedHeading>
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(17)}
          style={{ width: '680px' }}
        >
          <ResponsiveImage
            srcPath="/images/nomad_story/story_16"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child half-height"
          />
          <Caption className="child">
            NoMad has also become known for an emphasis on perfect core
            ingredients: everything from the roasted coffee beans of Stumptown
            to the array of 400 cheeses on offer at Eataly. In this
            neighborhood, everyone is on a NoMad-ic search for pleasures.
          </Caption>
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(18)}
          style={{ width: '400px' }}
          justify-content="space-between"
        >
          <Caption className="child" padding>
            Eataly is 50,000 square feet of curated food offerings, a
            destination as well as a weekly shopping resource for lucky local
            residents. A parade of new arrivals adheres to a common mission:
            Prepare authentic cuisine with the best ingredients in a joyful
            setting.
          </Caption>
          <ResponsiveImage
            srcPath="/images/nomad_story/story_17"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child"
          />
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(19)}
          style={{ width: '680px' }}
        >
          <ResponsiveImage
            srcPath="/images/nomad_story/story_18"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child half-height"
          />
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(20)}
          style={{ width: '680px' }}
          justify-content="space-between"
        >
          <Caption className="child" padding>
            Famed restauranteur Stephen Starr produced a British-inspired menu
            for the clubby rooms of The Clocktower in the Edition hotel and has
            earned a Michelin star for his efforts.
          </Caption>
          <ResponsiveImage
            srcPath="/images/nomad_story/story_19"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child half-height"
          />
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(21)}
          style={{ width: '400px' }}
          justify-content="flex-end"
        >
          <ResponsiveImage
            srcPath="/images/nomad_story/story_20"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child half-height"
          />
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(22)}
          style={{ width: '680px' }}
        >
          <ResponsiveImage
            srcPath="/images/nomad_story/story_21"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child half-height"
          />
          <Caption className="child">
            Even cocktail hour gets a food focus with small plates at Simon &
            The Whale, a lively evening oasis.
          </Caption>
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(23)}
          style={{ width: '260px' }}
        >
          <Caption className="child">
            Neighborhoods need places to gather after work, after dinner, after
            the theater. NoMad offers options for all occasions.
          </Caption>
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(24)}
          style={{ width: setImageWidth(1335, 2000) }}
          justify-content="flex-end"
        >
          <ResponsiveImage
            srcPath="/images/nomad_story/story_22"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child"
          />
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(25)}
          style={{ width: '680px' }}
          justify-content="center"
        >
          <ResponsiveImage
            srcPath="/images/nomad_story/story_23"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child quarter-height"
          />
          <RedHeading className="child">
            A LIFESTYLE WITH
            <br />
            ACCESS TO THE
            <br />
            BEST DESIGN
          </RedHeading>
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(26)}
          style={{ width: setImageWidth(1334, 2000) }}
          justify-content="flex-end"
        >
          <ResponsiveImage
            srcPath="/images/nomad_story/story_24"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child"
          />
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(27)}
          style={{ width: '400px' }}
          justify-content="flex-start"
        >
          <Caption className="child">
            Life in the design district not only means having access to the best
            in fashion and furniture — the entire experience of daily life gets
            a makeover, whether it’s a trip to the barbershop where the stylists
            use handcrafted lotions and soaps, or an evening spent at a magic
            show that takes wonder to a whole new level.
          </Caption>
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(28)}
          style={{ width: '400px' }}
          justify-content="flex-end"
        >
          <ResponsiveImage
            srcPath="/images/nomad_story/story_25"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child half-height"
          />
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(29)}
          style={{ width: '680px' }}
        >
          <ResponsiveImage
            srcPath="/images/nomad_story/story_26"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child half-height"
          />
          <Caption className="child">
            High-end, cutting-edge style can be found on NoMad’s streets. Rei
            Kawakubo brought her international department store, Dover Street
            Market, to NoMad with a curated selection of brands such as
            Balenciaga, Gucci and Raf Simmons.
          </Caption>
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(30)}
          style={{ width: setImageWidth(1078, 1680) }}
        >
          <ResponsiveImage
            srcPath="/images/nomad_story/story_27"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child"
          />
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(31)}
          style={{ width: '320px' }}
          justify-content="space-between"
        >
          <Caption className="child">
            Boutiques for baubles, accent pieces, and featured furniture make
            pleasure shopping a neighborhood activity. Poliform and B&B Italia
            expand the options for selecting high-design furniture as well as
            smaller grace notes for gifts or self-gifting.
          </Caption>
          <ResponsiveImage
            srcPath="/images/nomad_story/story_28"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child half-height"
          />
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(32)}
          style={{ width: setImageWidth(1078, 1680) }}
        >
          <ResponsiveImage
            srcPath="/images/nomad_story/story_29"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child"
          />
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(33)}
          style={{ width: '500px' }}
        >
          <ResponsiveImage
            srcPath="/images/nomad_story/story_30"
            imgAlt="Sketch of Manhattan Skyline"
          />
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(34)}
          style={{ width: '680px' }}
        >
          <ResponsiveImage
            srcPath="/images/nomad_story/story_31"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child half-height"
          />
          <Caption className="child">
            Simply getting a haircut or shave becomes a spa stop at Fellow
            Barber or Rudy&#39;s. At Le Labo, the nearly addictive colognes are
            mixed on premises while you wait.
          </Caption>
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(35)}
          style={{ width: '600px' }}
          justify-content="space-between"
          align-items="center"
        >
          <Caption className="child" paddingLeft>
            Long-loved Rizzoli moved to NoMad in 2016 to take over an exquisite
            turn-of-the-century space with wooden shelves of beautifully curated
            book selections.
          </Caption>
          <ResponsiveImage
            srcPath="/images/nomad_story/story_32"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child three-quarter-height"
          />
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(36)}
          style={{ width: setImageWidth(1346, 2000) }}
        >
          <ResponsiveImage
            srcPath="/images/nomad_story/story_33"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child"
          />
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(37)}
          style={{ width: '1000px' }}
        >
          <ResponsiveImage
            srcPath="/images/nomad_story/story_34"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child three-quarter-height"
          />
          <Caption className="child" paddingRight>
            Madison Square Park is itself an outdoor museum, with a calendar
            curated to feature almost three dozen artist exhibitions specially
            commissioned for the space.
          </Caption>
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(38)}
          style={{ width: '600px' }}
          justify-content="space-between"
        >
          <Caption className="child" paddingRight>
            Whether biking the protected lanes of NoMad or hitting a SoulCycle
            class, even fitness becomes not just an item to check off a to-do
            list but a moment for recharging.
          </Caption>
          <ResponsiveImage
            srcPath="/images/nomad_story/story_35"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child three-quarter-height"
          />
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(39)}
          style={{ width: '800px' }}
          justify-content="space-between"
          align-items="flex-end"
        >
          <ResponsiveImage
            srcPath="/images/nomad_story/story_36"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child three-quarter-height"
          />
          <ResponsiveImage
            srcPath="/images/nomad_story/story_37"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child quarter-height"
          />
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(40)}
          style={{ width: setImageWidth(2480, 3508) }}
        >
          <ResponsiveImage
            srcPath="/images/nomad_story/story_38"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child"
          />
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(41)}
          style={{ width: '300px' }}
        >
          <Caption className="child" padding>
            With so much pleasure to be found in NoMad, the greatest pleasure is
            in finding one’s self. NoMad has so much on offer, it awaits
            whatever lifestyle one wants to fashion.
          </Caption>
        </StoryColumn>
        <StoryColumn
          contentLoaded={contentLoaded}
          revealed={isVisible(42)}
          className="full-height"
          style={{ width: setFullWidth(1354, 2000) }}
        >
          <ResponsiveImage
            srcPath="/images/nomad_story/story_39"
            imgAlt="Sketch of Manhattan Skyline"
            imgClass="child full-height"
          />
        </StoryColumn>
      </Swiper>
      <HorizontalPageNavigation
        nextPage="NOMAD GUIDE"
        nextPath="/nomad-neighborhood-guide"
        prevPage="AVAILABILITY"
        prevPath="/nomad-new-condominium-availability"
        activeIndex={swiperIndex}
        maxIndex={40}
      />
    </>
  );
};

export default DesktopStory;
