/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useContext, useState, useRef, useEffect,
} from 'react';
import Fade from 'react-reveal/Fade';
import { Transition } from 'react-transition-group';

import Context from '../../config/Context';
import {
  MainCol,
  MainRow,
  PaddingColumn,
  ImageColumn,
  TextColumn,
  ImageSection,
  TextSection,
  CopyBody,
  CopyHeading,
  defaultStyle,
  bodyTransitionStyles,
  headingTransitionStyles,
} from '../utils/desktop-columns';
import {
  HeaderCol,
  SubHeaderRow,
  HeaderLogo,
  GrayBackground,
  SubHeaderLeftCol,
  SubHeaderRightCol,
  HeroImage,
} from '../utils/page-header';
import ResponsiveImage from '../utils/responsive-image';
import { architectureCopy } from '../../data/architecture';

const ArchitectureDesktop = () => {
  const context = useContext(Context);
  const { togglePhotoViewerState } = context;
  const [activeSection, setActiveSection] = useState(0);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [heroImageHeight, setHeroImageHeight] = useState(0);
  const [heroImageWidth, setHeroImageWidth] = useState(0);
  const heroImageRef = useRef(null);
  const sectionRef1 = useRef(null);
  const sectionRef2 = useRef(null);
  const sectionRef3 = useRef(null);
  const refArray = [sectionRef1, sectionRef2, sectionRef3];

  const handleResize = () => {
    if (heroImageRef.current !== null) {
      setHeroImageHeight(heroImageRef.current.height);
      setHeroImageWidth(heroImageRef.current.width);
    }
  };

  const handleScroll = () => {
    refArray.forEach((ref, idx) => {
      const top = ref.current.offsetTop;
      const bottom = ref.current.offsetTop + ref.current.offsetHeight + 120;
      const middle = ref.current.offsetTop + ref.current.offsetHeight / 2;
      const middleWindow = 64 + window.scrollY;
      if (idx === 0 && middleWindow < top) {
        setActiveSection(0);
      } else if (activeSection === 0 && idx === 0 && middleWindow >= middle) {
        setActiveSection(1);
      } else if (middleWindow >= top && middleWindow < bottom) {
        setActiveSection(idx + 1);
      }
    });
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    return () => {
      document.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  });

  useEffect(() => {
    setTimeout(() => {
      if (heroImageRef.current !== null) {
        setHeroImageHeight(heroImageRef.current.height);
        setHeroImageWidth(heroImageRef.current.width);
      }
    }, 500);
  }, [heroImageRef.current]);

  return (
    <MainCol>
      <HeaderCol>
        <HeaderLogo />
        <SubHeaderRow>
          <SubHeaderLeftCol contentLoaded={contentLoaded} arrowLocation={heroImageHeight}>
            <h2>{architectureCopy.desktop.heading}</h2>
            <img src="/images/logos/nav-arrow-down-red.svg" alt="continue scrolling" />
          </SubHeaderLeftCol>
          <SubHeaderRightCol contentLoaded={contentLoaded} imageWidth={heroImageWidth}>
            <HeroImage>
              <ResponsiveImage
                onClickProp={() => {
                  togglePhotoViewerState(0);
                }}
                srcPath="/images/architecture/architecture_1"
                refProp={heroImageRef}
                onLoadProp={() => {
                  setContentLoaded(true);
                }}
              />
            </HeroImage>
            <p>{architectureCopy.desktop.subHeading}</p>
          </SubHeaderRightCol>
        </SubHeaderRow>
        <GrayBackground contentLoaded={contentLoaded} backgroundHeight={heroImageHeight} />
      </HeaderCol>
      <MainRow>
        <PaddingColumn />
        <TextColumn>
          <TextSection activeSection={activeSection}>
            <Transition timeout={0} in={activeSection <= 1}>
              {(state) => (
                <CopyBody style={{ ...defaultStyle, ...bodyTransitionStyles[state] }}>
                  {architectureCopy.desktop.sectionOneText}
                </CopyBody>
              )}
            </Transition>
          </TextSection>
          <TextSection activeSection={activeSection}>
            <Transition timeout={0} in={activeSection === 2 || activeSection === 3}>
              {(state) => (
                <CopyHeading style={{ ...defaultStyle, ...headingTransitionStyles[state] }}>
                  {architectureCopy.desktop.sectionTwoTitle}
                </CopyHeading>
              )}
            </Transition>
            <Transition timeout={0} in={activeSection === 2}>
              {(state) => (
                <CopyBody style={{ ...defaultStyle, ...bodyTransitionStyles[state] }}>
                  {architectureCopy.desktop.sectionTwoText}
                </CopyBody>
              )}
            </Transition>
          </TextSection>
          <TextSection activeSection={activeSection}>
            <CopyHeading />
            <Transition timeout={0} in={activeSection === 3}>
              {(state) => (
                <CopyBody style={{ ...defaultStyle, ...bodyTransitionStyles[state] }}>
                  {architectureCopy.desktop.sectionThreeText}
                </CopyBody>
              )}
            </Transition>
          </TextSection>
        </TextColumn>
        <ImageColumn>
          <ImageSection ref={sectionRef1}>
            <Fade>
              <ResponsiveImage
                onClickProp={() => {
                  togglePhotoViewerState(1);
                }}
                srcPath="/images/architecture/architecture_2"
              />
            </Fade>
          </ImageSection>
          <ImageSection ref={sectionRef2}>
            <Fade>
              <ResponsiveImage
                onClickProp={() => {
                  togglePhotoViewerState(2);
                }}
                srcPath="/images/architecture/architecture_3"
              />
            </Fade>
          </ImageSection>
          <ImageSection ref={sectionRef3}>
            <Fade>
              <ResponsiveImage
                onClickProp={() => {
                  togglePhotoViewerState(3);
                }}
                srcPath="/images/architecture/architecture_4"
              />
            </Fade>
          </ImageSection>
        </ImageColumn>
      </MainRow>
    </MainCol>
  );
};

export default ArchitectureDesktop;
