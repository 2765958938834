/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import { Helmet } from 'react-helmet';

import {
  SFtoSM,
  numberWithCommas,
  getQueryVariable,
  sortListings,
  removeLetters,
  parseAdditionalFields,
} from '../components/availability/listing-utils';
import Context from '../config/Context';
import { HeaderLogo, MobilePageTitle } from '../components/utils/page-header';
import variables from '../styles/Variables';
import { mediaMin, mediaMax } from '../styles/MediaQueries';
import FilterMenu from '../components/availability/filter-menu';
import ShareDrawer from '../components/availability/share-drawer';
import ShareForm from '../components/availability/share-form';
import LoadingSpinner from '../components/utils/loading-spinner';
import FetchError from '../components/utils/fetch-error';
import FilterError from '../components/utils/filter-error';
import { ShareCheckbox } from '../components/utils/checkbox';
import PageNavigation from '../components/utils/page-navigation';
import viewsData from '../data/views';

const AvailabilityColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  position: relative;
  padding: 0 32px;
  ${mediaMin.tabletLandscape`
    padding: 0;
  `}
`;

const FilterAndShareRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 4px;
  ${mediaMin.tabletLandscape`
  z-index: 100;
  margin-bottom: 0;
  width: unset;
  position: fixed;
  top: 24px;
  right: 44px;
  `}
`;

const FilterButton = styled.button`
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 16px;
  color: ${variables.colors.darkRed};
  border: 1px solid ${variables.colors.darkRed};
  cursor: pointer;
  background-color: transparent;
  height: 40px;
  width: 48%;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
  ${mediaMin.tabletLandscape`
  height: 48px;
  width: 104px;
  `}
`;

const ShareToggle = styled.label`
  position: relative;
  display: inline-block;
  width: 32px;
  height: 16px;
  margin-right: 8px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #d7d7d7;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 14px;
    width: 14px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
  input:checked + .slider {
    background-color: ${variables.colors.darkRed};
  }
`;

const ShareLabel = styled.label`
  color: ${variables.colors.darkRed};
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 16px;
  height: 40px;
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${variables.colors.lightGray};
  ${mediaMin.tabletLandscape`
  background-color: transparent;
  margin-right: 16px;
  height: 48px;
  width: 104px;
  `}
`;

const ListingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 50vh;
  margin-bottom: 240px;
  .react-reveal {
    width: 100%;
  }
  ${mediaMin.tabletLandscape`
  padding: 0 20%;
  `}
`;

const LeftRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 32px;
  ${mediaMin.tabletLandscape`
    height: 100%;
    margin-bottom: 0;
  `}
`;

const ListingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin 4px 0;
  padding: 32px;
  width: 100%;
  background-color: ${variables.colors.lightGray};
  transition: all .25s ease-in-out;
  flex-direction: row;
  ${mediaMin.tablet`
    padding: 40px;
    height: 240px
  `}
  ${mediaMin.tabletLandscape`
    height: 180px;
    padding: 48px;
  `}
`;

const CheckboxColumn = styled.div`
  align-items: center;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  width: 100%;
  justify-content: start;
  max-width: ${(props) => (props.active ? '32px' : '0')};
  transition: max-width 0.5s ease-in-out;
  ${mediaMin.tabletLandscape`
  max-width: ${(props) => (props.active ? '100%' : '0')};
  justify-content: center;
    width: unset;
    flex-direction: column;
  `}
`;

const UnitNumber = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  ${mediaMin.tabletLandscape`
    justify-content: center;
  `}
  h3 {
    margin: 0;
    color: ${variables.colors.darkRed};
    font-variant-numeric: lining-nums;
    font-size: 48px;
    line-height: 48px;
    font-family: AWConquerorProDidot-Regular;
    ${mediaMin.tabletLandscape`
    font-family: AWConquerorProDidotL-Regular;
      font-size: 64px;
      line-height: 64px;
      height: 100%;
      ${(props) => (props.chars > 3 ? 'font-size: 32px;' : '')}
      `}
      ${mediaMin.desktopSmall`
        ${(props) => (props.chars > 3 ? 'font-size: 40px;' : '')}
      `}
      ${mediaMin.desktopLarge`
        ${(props) => (props.chars > 3 ? 'font-size: 48px;' : '')}
      `}
  }
`;

const DesktopDetailColumn = styled.div`
  display: none;
  ${mediaMin.tabletLandscape`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 22%;
    .bold-detail {
      font-size: 16px;
      letter-spacing: 2px;
      line-height: 16px;
      color: #1a1919;
    }
    .light-detail {
      font-size: 12px;
      letter-spacing: 1.5px;
      line-height: 16px;
      color: #1a1919;
      opacity: .5;
    }
`}
`;

const MobileDetailColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 32px;
  .bold-detail {
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 16px;
    color: #1a1919;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  ${mediaMin.tablet`
    display: none;
  `}
`;

const TabletDetailColumn = styled.div`
  ${mediaMax.phoneXL`
    display: none;
  `}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .bold-detail {
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 16px;
    color: #1a1919;
  }
  .light-detail {
    font-size: 12px;
    letter-spacing: 1.5px;
    line-height: 16px;
    color: #1a1919;
  }
  ${mediaMin.tabletLandscape`
    display: none;
  `}
`;

const ButtonColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  ${mediaMin.tabletLandscape`
    height: 100%;
  `}
  a {
    padding: 0;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 16px;
    border: none;
    background-color: #00000000;
    color: ${variables.colors.darkRed};
    justify-content: flex-start;
    margin-bottom: 16px;
    text-align: right;
    &:last-child {
      margin-bottom: 0;
    }
    &:visited {
      color: ${variables.colors.darkRed};
    }
    &:hover {
      text-decoration: none;
      color: ${variables.colors.brightRed};
    }
    ${mediaMin.tabletLandscape`
      justify-content: flex-end;
      margin-bottom: 0
    `}
  }
  .views-link {
    color: ${(props) => (props.viewActive ? variables.colors.darkRed : variables.colors.darkGray)};
    pointer-events: ${(props) => (props.viewActive ? 'all' : 'none')};
    &:visited {
      color: ${(props) => (props.viewActive
    ? variables.colors.darkRed
    : variables.colors.darkGray)};
    }
    &:hover {
      text-decoration: none;
      color: ${variables.colors.brightRed};
    }
  }
`;

const renderAvailabilityData = (availabilityData, context) => {
  const sortedAvailabilityArray = sortListings(availabilityData);
  return sortedAvailabilityArray.map(
    (listing) => (
      <Fade cascade key={listing.id}>
        <ListingRow>
          <DesktopDetailColumn>
            <LeftRow>
              <CheckboxColumn active={context.shareDrawerActive}>
                <ShareCheckbox shareValue={listing.unit} />
              </CheckboxColumn>
              <UnitNumber chars={listing.unit.length}>
                <h3>{listing.unit}</h3>
              </UnitNumber>
            </LeftRow>
          </DesktopDetailColumn>
          <DesktopDetailColumn>
            <span className="bold-detail">
              {`${parseInt(removeLetters(listing.bedrooms), 10)} ${parseInt(removeLetters(listing.bedrooms), 10) > 1 ? 'BEDROOMS' : 'BEDROOM'}`}
            </span>
            <span className="bold-detail">
              {`${parseFloat(removeLetters(listing.bathrooms))} ${parseFloat(removeLetters(listing.bathrooms)) > 1 ? 'BATHS' : 'BATH'}`}
            </span>
            <span className="bold-detail">
              {`${listing.area} SF / ${SFtoSM(listing.area)} SM`}
            </span>
          </DesktopDetailColumn>
          <DesktopDetailColumn>
            <span className="bold-detail">
              {`$${numberWithCommas(listing.price)}`}
            </span>
            <span className="light-detail">
              {`${parseAdditionalFields(listing.additional_fields, 'Common Charges')} EST. C.C.`}
            </span>
            <span className="light-detail">
              {`${parseAdditionalFields(listing.additional_fields, 'RE Taxes')} EST. R.E. TAXES`}
            </span>
          </DesktopDetailColumn>
          <DesktopDetailColumn>
            <ButtonColumn viewActive={!!viewsData[listing.unit]}>
              <Link
                className="listing-link"
                to={`/nomad-new-condominium-availability/${listing.id}`}
              >
                FLOOR PLAN
              </Link>
              <Link className="views-link" to={`/exposures/${listing.id}`}>
                {`${listing.exposures.join(', ')} EXPOSURES`}
              </Link>
            </ButtonColumn>
          </DesktopDetailColumn>
          <TabletDetailColumn>
            <LeftRow>
              <CheckboxColumn active={context.shareDrawerActive}>
                <ShareCheckbox shareValue={listing.unit} />
              </CheckboxColumn>
              <UnitNumber>
                <h3>{listing.unit}</h3>
              </UnitNumber>
            </LeftRow>
            <span className="bold-detail">
              {`${parseInt(removeLetters(listing.bedrooms), 10)} ${parseInt(removeLetters(listing.bedrooms), 10) > 1 ? 'BEDROOMS' : 'BEDROOM'}`}
            </span>
            <span className="bold-detail">
              {`${parseFloat(removeLetters(listing.bathrooms))} ${parseFloat(removeLetters(listing.bathrooms)) > 1 ? 'BATHS' : 'BATH'}`}
            </span>
            <span className="bold-detail">
              {`${listing.area} SF / ${SFtoSM(listing.area)} SM`}
            </span>
          </TabletDetailColumn>
          <TabletDetailColumn>
            <span className="bold-detail">
              {`$${numberWithCommas(listing.price)}`}
            </span>
            <span className="light-detail">
              {`${parseAdditionalFields(listing.additional_fields, 'Common Charges')} EST. C.C.`}
            </span>
            <span className="light-detail">
              {`${parseAdditionalFields(listing.additional_fields, 'RE Taxes')} EST. R.E. TAXES`}
            </span>
            <ButtonColumn viewActive={!!viewsData[listing.unit]}>
              <Link
                className="listing-link"
                to={`/nomad-new-condominium-availability/${listing.id}`}
              >
                FLOOR PLAN
              </Link>
              <Link className="views-link" to={`/exposures/${listing.id}`}>
                {`${listing.exposures.join(', ')} EXPOSURES`}
              </Link>
            </ButtonColumn>
          </TabletDetailColumn>
          <MobileDetailColumn>
            <LeftRow>
              <CheckboxColumn active={context.shareDrawerActive}>
                <ShareCheckbox shareValue={listing.unit} />
              </CheckboxColumn>
              <UnitNumber>
                <h3>{listing.unit}</h3>
              </UnitNumber>
            </LeftRow>
            <span className="bold-detail">
              {`${parseInt(removeLetters(listing.bedrooms), 10)} ${parseInt(removeLetters(listing.bedrooms), 10) > 1 ? 'BEDROOMS' : 'BEDROOM'} / ${parseFloat(removeLetters(listing.bathrooms))} ${parseFloat(removeLetters(listing.bathrooms)) > 1 ? 'BATHS' : 'BATH'}`}
            </span>
            <span className="bold-detail">
              {`${listing.area} SF / ${SFtoSM(listing.area)} SM`}
            </span>
            <span className="bold-detail">
              {`$${numberWithCommas(listing.price)}`}
            </span>
            <ButtonColumn viewActive={!!viewsData[listing.unit]}>
              <Link
                className="listing-link"
                to={`/nomad-new-condominium-availability/${listing.id}`}
              >
                FLOOR PLAN
              </Link>
              <Link className="views-link" to={`/exposures/${listing.id}`}>
                {`${listing.exposures.join(', ')} EXPOSURES`}
              </Link>
            </ButtonColumn>
          </MobileDetailColumn>
        </ListingRow>
      </Fade>
    ),
  );
};

const Availability = () => {
  const context = useContext(Context);
  const listRef = useRef(null);
  const toggleRef = useRef(null);
  const [availabilityList, setAvailabilityList] = useState(null);
  const {
    toggleFilterActive,
    toggleShareDrawerActive,
    availabilityData,
    fetchAvailabilityData,
    shareDrawerActive,
    filters,
    updateFilters,
    clearSharedListings,
  } = context;

  const filterAvailabilityData = () => {
    const availabilityArray = Object.values(availabilityData);
    const { beds, price, exposures } = filters;

    let newData = availabilityArray;

    // FILTER BEDS
    if (beds.size > 0) {
      newData = newData.filter((el) => {
        if (beds.has('1') && parseInt(removeLetters(el.bedrooms), 10) === 1) {
          return true;
        }
        if (beds.has('2') && parseInt(removeLetters(el.bedrooms), 10) === 2) {
          return true;
        }
        if (beds.has('3') && parseInt(removeLetters(el.bedrooms), 10) === 3) {
          return true;
        }
        if (beds.has('4') && parseInt(removeLetters(el.bedrooms), 10) === 4) {
          return true;
        }
        if (beds.has('5') && parseInt(removeLetters(el.bedrooms), 10) === 5) {
          return true;
        }
        if (beds.has('PH') && el.unit_num.slice(0, 2) === 'PH') {
          return true;
        }
        return false;
      });
    }
    // FILTER PRICE
    if (price.size > 0) {
      newData = newData.filter((el) => {
        if (price.has('$1-3 M') && el.price <= 3000000) {
          return true;
        }
        if (
          price.has('$3-5 M')
          && el.price >= 3000000
          && el.price <= 5000000
        ) {
          return true;
        }
        if (
          price.has('$5-7 M')
          && el.price >= 5000000
          && el.price <= 7000000
        ) {
          return true;
        }
        if (
          price.has('$7-10 M')
          && el.price >= 7000000
          && el.price <= 10000000
        ) {
          return true;
        }
        if (
          price.has('$10-15 M')
          && el.price >= 10000000
          && el.price <= 15000000
        ) {
          return true;
        }
        if (price.has('$15 M+') && el.price >= 15000000) {
          return true;
        }
        return false;
      });
    }
    // FILTER EXPOSURES
    if (exposures.size > 0) {
      newData = newData.filter((el) => {
        if (exposures.has('N') && el.exposures.includes('N')) {
          return true;
        }
        if (exposures.has('S') && el.exposures.includes('S')) {
          return true;
        }
        if (exposures.has('E') && el.exposures.includes('E')) {
          return true;
        }
        if (exposures.has('W') && el.exposures.includes('W')) {
          return true;
        }
        return false;
      });
    }

    if (newData.length === 0) {
      setAvailabilityList(<FilterError />);
    } else {
      setAvailabilityList(renderAvailabilityData(newData, context));
    }
  };

  const renderClearedFilters = () => {
    setAvailabilityList(renderAvailabilityData(availabilityData, context));
  };

  // Query string example: ?listings=16C+16D

  const filterListingsFromParams = () => {
    const availabilityArray = Object.values(availabilityData);
    const { listings } = filters;

    let newData = availabilityArray;

    // FILTER LISTINGS
    if (listings.size > 0) {
      newData = newData.filter((el) => {
        if (listings.has(el.unit)) {
          return true;
        }
        return false;
      });
    }
    setAvailabilityList(renderAvailabilityData(newData, context));

    // replace URL
    window.history.replaceState(null, null, window.location.pathname);
  };

  const selectAvailabilityComponent = () => {
    switch (availabilityData) {
      case null:
        return <LoadingSpinner />;
      case 'ERROR':
        return <FetchError page="AVAILABILITY" />;
      default:
        return renderAvailabilityData(availabilityData, context);
    }
  };

  const closeAndResetShareDrawer = () => {
    toggleRef.current.checked = false;
    clearSharedListings();
  };

  useEffect(() => {
    if (availabilityData === null) {
      fetchAvailabilityData();
    }
  }, []);

  useEffect(() => {
    setAvailabilityList(selectAvailabilityComponent());
    const searchedListings = getQueryVariable('listings');
    if (availabilityData && searchedListings) {
      searchedListings.split(',').forEach((el) => {
        updateFilters('listings', el);
      });
      filterListingsFromParams();
    }
  }, [availabilityData, shareDrawerActive]);

  return (
    <>
      <Helmet>
        <title>New Luxury Condominiums in NoMad | Madison House</title>
        <meta
          name="description"
          content="1-4 Bedroom residences, Madison House offers a double-height attended lobby and 30,000 square feet of amenities."
        />
      </Helmet>
      <AvailabilityColumn>
        <HeaderLogo />
        <MobilePageTitle>AVAILABILITY</MobilePageTitle>
        <FilterAndShareRow>
          <ShareLabel htmlFor="share">
            <ShareToggle>
              <input
                id="share"
                type="checkbox"
                onClick={toggleShareDrawerActive}
                ref={toggleRef}
              />
              <span className="slider" />
            </ShareToggle>
            SHARE
          </ShareLabel>
          <FilterButton
            onClick={(e) => {
              e.preventDefault();
              toggleFilterActive();
            }}
          >
            FILTER
          </FilterButton>
          <FilterMenu
            filterAvailabilityData={filterAvailabilityData}
            renderClearedFilters={renderClearedFilters}
          />
        </FilterAndShareRow>
        <ListingContainer ref={listRef}>{availabilityList}</ListingContainer>
        <ShareDrawer closeAndResetShareDrawer={closeAndResetShareDrawer} />
        <PageNavigation
          prevPage="AMENITIES"
          prevPath="/nomad-new-condominium-amenities"
          nextPage="SERVICES"
          nextPath="/nomad-new-condominium-services"
        />
      </AvailabilityColumn>
      <ShareForm closeAndResetShareDrawer={closeAndResetShareDrawer} />
    </>
  );
};

export default Availability;
