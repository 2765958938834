/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import $ from 'jquery';

import variables from '../../styles/Variables';
import { mediaMin } from '../../styles/MediaQueries';
import Context from '../../config/Context';
import { FormCheckbox } from '../utils/checkbox';

const ShareContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  overflow-y: scroll;
  z-index: 150;
  background-color: #fff;
  opacity: ${(props) => (props.active ? '1' : '0')};
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  box-sizing: border-box;
  padding: 0 36px;
  ${mediaMin.tabletLandscape`
    padding: 0;
  `}
`;

const CloseRow = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  top: 24px;
  right: 36px;
  ${mediaMin.tabletLandscape`
    right: 44px;
  `}
  span {
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 16px;
    color: ${variables.colors.darkRed};
    margin-right: 16px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 80px 0;
  opacity: ${(props) => (props.active && props.shareFormActive ? '1' : '0')};
  visibility: ${(props) => (props.active && props.shareFormActive ? 'visible' : 'hidden')};
  transition: opacity 0.5s ease, visibility 0.5s ease;
  ${mediaMin.tabletLandscape`
    margin: auto auto;
    width: 50%;
  `}
  .section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-bottom: 32px;
  }
  .shared-header {
    font-family: Granjon;
    font-size: 20px;
    line-height: 28px;
  }
  .big-red {
    font-family: AWConquerorProDidotL-Regular;
    font-variant-numeric: lining-nums;
    font-size: 32px;
    line-height: 32px;
    color: ${variables.colors.darkRed};
  }
  .small-black {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 12px;
    color: ${variables.colors.darkGray};
  }
  .form-header {
    margin-bottom: 24px;
  }

  .center {
    text-align: center;
  }
  .small-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    ${mediaMin.tabletLandscape`
      flex-direction: row;
    `}
    .required {
      font-size: 12px;
      line-height: 12px;
      margin-top: 16px;
      ${mediaMin.tabletLandscape`
        margin-top: 0;
      `}
    }
  }

  button {
    width: 100%;
    height: 64px;
    color: #fff;
    background-color: ${variables.colors.darkRed};
    font-size: 14px;
    letter-spacing: 1.75px;
    line-height: 16px;
    cursor: pointer;
  }
`;

const FormLabel = styled.label`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
  .label-text {
    margin-bottom: 8px;
  }
  input {
    border-radius: 0;
    width: 100%;
    border: 1px solid
      ${(props) => (props.error ? variables.colors.darkRed : variables.colors.darkGray)};
    font-size: 18px;
    padding: 8px;
    ${mediaMin.tabletLandscape`
      height: 64px;
        padding: 0 16px;
    `}
  }
`;

const Success = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 80px 0;
  opacity: ${(props) => (props.active && props.shareFormActive ? '1' : '0')};
  visibility: ${(props) => (props.active && props.shareFormActive ? 'visible' : 'hidden')};
  transition: opacity 0.5s ease, visibility 0.5s ease;
  p {
    text-align: center;
  }
  ${mediaMin.tabletLandscape`
    margin: auto auto;
    width: 50%;
  `}
`;

const ShareForm = ({ closeAndResetShareDrawer }) => {
  const context = useContext(Context);
  const { shareFormActive, toggleShareFormActive, sharedListings } = context;
  const [formMounted, setFormMounted] = useState(true);
  const [formVisible, setFormVisible] = useState(true);
  const [successMounted, setSuccessMounted] = useState(false);
  const [successVisible, setSuccessVisible] = useState(false);
  const [toName, setToName] = useState('');
  const [fromName, setFromName] = useState('');
  const [toEmail, setToEmail] = useState('');
  const [fromEmail, setFromEmail] = useState('');
  const [shareEmail, setShareEmail] = useState(false);
  const [toNameError, setToNameError] = useState(false);
  const [toEmailError, setToEmailError] = useState(false);
  const [fromNameError, setFromNameError] = useState(false);
  const [fromEmailError, setFromEmailError] = useState(false);
  const [listingOne, setListingOne] = useState('');
  const [listingOneLink, setListingOneLink] = useState('');
  const [listingTwo, setListingTwo] = useState('');
  const [listingTwoLink, setListingTwoLink] = useState('');
  const [listingThree, setListingThree] = useState('');
  const [listingThreeLink, setListingThreeLink] = useState('');
  const [listingFour, setListingFour] = useState('');
  const [listingFourLink, setListingFourLink] = useState('');

  const transitionFormAndSuccess = () => {
    setFormVisible(false);
    setTimeout(() => {
      setFormMounted(false);
      setSuccessMounted(true);
      setSuccessVisible(true);
    }, 500);
  };

  const setUpListingLinks = () => {
    const sharedListingsArray = Array.from(sharedListings);
    for (let idx = 0; idx < sharedListingsArray.length; idx += 1) {
      if (idx === 0) {
        setListingOne(`RESIDENCE ${sharedListingsArray[idx]}`);
        setListingOneLink(
          `http://madisonhousenyc.com/nomad-new-condominium-availability?listings=${sharedListingsArray[idx]}`,
        );
      }
      if (idx === 1) {
        setListingTwo(`RESIDENCE ${sharedListingsArray[idx]}`);
        setListingTwoLink(
          `http://madisonhousenyc.com/nomad-new-condominium-availability?listings=${sharedListingsArray[idx]}`,
        );
      }
      if (idx === 2) {
        setListingThree(`RESIDENCE ${sharedListingsArray[idx]}`);
        setListingThreeLink(
          `http://madisonhousenyc.com/nomad-new-condominium-availability?listings=${sharedListingsArray[idx]}`,
        );
      }
      if (idx === 3) {
        setListingFour(`RESIDENCE ${sharedListingsArray[idx]}`);
        setListingFourLink(
          `http://madisonhousenyc.com/nomad-new-condominium-availability?listings=${sharedListingsArray[idx]}`,
        );
      }
    }
  };

  useEffect(() => {
    setUpListingLinks();
  }, [shareFormActive]);

  const checkForErrors = () => {
    let returnValue = false;
    if (!toName) {
      setToNameError(true);
      returnValue = true;
    } else {
      setToNameError(false);
    }
    if (!toEmail) {
      setToEmailError(true);
      returnValue = true;
    } else {
      setToEmailError(false);
    }
    if (!fromName) {
      setFromNameError(true);
      returnValue = true;
    } else {
      setFromNameError(false);
    }
    if (!fromEmail) {
      setFromEmailError(true);
      returnValue = true;
    } else {
      setFromEmailError(false);
    }
    return !returnValue;
  };

  const submitEmail = () => {
    $.ajax({
      url: 'https://form.api.dbxd.com/post-spark-form/',
      type: 'POST',
      dataType: 'json',
      data: {
        projectname: 'madisonhousenyc',
        contact: {
          email: fromEmail,
        },
      },
      crossDomain: true,
      success: () => {
        console.log('success - email submitted');
      },
      error: (jqXHR, textStatus, errorThrown) => {
        console.log('http request failed', errorThrown);
      },
    });
  };

  const clearFieldsAndClose = () => {
    closeAndResetShareDrawer();
    toggleShareFormActive();
    setTimeout(() => {
      setToName('');
      setFromName('');
      setToEmail('');
      setFromEmail('');
      setShareEmail(false);
      setToNameError(false);
      setToEmailError(false);
      setFromNameError(false);
      setFromEmailError(false);
      setFormMounted(true);
      setFormVisible(true);
      setSuccessMounted(false);
      setSuccessVisible(false);
    }, 500);
  };

  const shareListings = () => {
    const sharedListingsArray = Array.from(sharedListings);
    const phrase = sharedListingsArray.join(', ');

    const generareListingsList = () => sharedListingsArray.reduce(
      (acc, curr, i) => (i !== 0 ? `${acc}${sharedListingsArray.length - 1 === i ? ' and  ' : ', '}${curr}` : curr),
      '',
    );

    const emailData = {
      from: '"Madison House NYC Team" <no_reply_madisonhousenyc@dbox.com>',
      to: `"${toName}" <${toEmail}>`,
      subject: `${fromName} Shared: Madison House NYC Residence ${generareListingsList()}`,
      htmlUrl: 'https://s3.amazonaws.com/madisonhousenyc.com/share_email/share.html',
      variables: {
        toName: toName.toUpperCase(),
        fromName: fromName.toUpperCase(),
        listings: phrase,
        listingOne,
        listingTwo,
        listingThree,
        listingFour,
        listingOneLink,
        listingTwoLink,
        listingThreeLink,
        listingFourLink,
      },
    };

    fetch('https://form.api.dbxd.com/post-ses-email', {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(emailData),
    });

    if (shareEmail) {
      submitEmail();
    }
    transitionFormAndSuccess();
  };

  return (
    <ShareContainer active={shareFormActive}>
      <CloseRow onClick={clearFieldsAndClose}>
        <span>CANCEL</span>
        <img src="/images/logos/close.svg" alt="back to availability list" />
      </CloseRow>
      {formMounted ? (
        <Form
          shareFormActive={shareFormActive}
          active={formVisible}
          onSubmit={(e) => {
            e.preventDefault();
            if (checkForErrors()) {
              shareListings();
            }
          }}
        >
          <div className="section">
            <span className="shared-header center">
              You have requested to share the floor plan(s) for:
            </span>
            <span className="big-red center">{Array.from(sharedListings).join(', ')}</span>
          </div>
          <div className="section">
            <span className="big-red form-header">TO</span>
            <FormLabel className="small-black" htmlFor="to_name" error={toNameError}>
              <span className="label-text">*FIRST + LAST NAME</span>
              <input
                id="to_name"
                type="text"
                value={toName}
                onChange={(e) => setToName(e.target.value)}
              />
            </FormLabel>
            <FormLabel className="small-black" htmlFor="to_email" error={toEmailError}>
              <span className="label-text">*EMAIL ADDRESS</span>
              <input
                id="to_email"
                type="email"
                value={toEmail}
                onChange={(e) => setToEmail(e.target.value)}
              />
            </FormLabel>
          </div>
          <div className="section">
            <span className="big-red form-header">FROM</span>
            <FormLabel className="small-black" htmlFor="from_name" error={fromNameError}>
              <span className="label-text">*FIRST + LAST NAME</span>
              <input
                id="from_name"
                type="text"
                value={fromName}
                onChange={(e) => setFromName(e.target.value)}
              />
            </FormLabel>
            <FormLabel className="small-black" htmlFor="from_email" error={fromEmailError}>
              <span className="label-text">*EMAIL ADDRESS</span>
              <input
                id="from_email"
                type="email"
                value={fromEmail}
                onChange={(e) => setFromEmail(e.target.value)}
              />
            </FormLabel>
            <div className="small-row">
              <FormCheckbox
                checkValue="mailing-list"
                checkLabel="ADD YOUR EMAIL ADDRESS TO THE MAILING LIST"
                onChange={() => setShareEmail(!shareEmail)}
              />
              <span className="required">* required field</span>
            </div>
          </div>
          <button type="submit">SUBMIT</button>
        </Form>
      ) : (
        ''
      )}
      {successMounted ? (
        <Success shareFormActive={shareFormActive} active={successVisible}>
          <p>THANK YOU FOR YOUR INTEREST IN MADISON HOUSE</p>
          <p>{`YOUR EMAIL HAS BEEN SENT TO ${toEmail.toUpperCase()}`}</p>
        </Success>
      ) : (
        ''
      )}
    </ShareContainer>
  );
};

export default ShareForm;
