/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';

import variables from '../../styles/Variables';

const ErrorMessage = styled.h2`
  margin: auto 0;
  color: ${variables.colors.darkRed};
  z-index: 100;
`;

const FetchError = ({ page }) => (
  <ErrorMessage>{`UNABLE TO FETCH ${page.toUpperCase()} DATA`}</ErrorMessage>
);

export default FetchError;
