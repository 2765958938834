import styled from 'styled-components';

import { mediaMin, mediaMax } from '../../styles/MediaQueries';
import variables from '../../styles/Variables';

const MainCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 64px;
  box-sizing: border-box;
  ${mediaMin.tabletLandscape`
  padding-bottom: 20vh;
  `} .desktop {
    display: none;
    ${mediaMin.tabletLandscape`
      display: block;    
    `}
  }
  .mobile {
    display: block;
    ${mediaMin.tabletLandscape`
    display: none;
    `}
  }
`;

const MobileCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 64px;
  h3 {
    color: ${variables.colors.darkRed};
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 16px;
    width: 100%;
    margin: 16px 0;
  }
  p {
    color: ${variables.colors.darkGray};
    font-family: 'Granjon';
    font-size: 17px;
    line-height: 22px;
    width: 100%;
  }
`;

const MainRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  ${mediaMin.tabletLandscape`
  flex-direction: row;
  `}
`;

const PaddingColumn = styled.div`
  display: none;
  ${mediaMin.tabletLandscape`
  display: flex;
  flex-direction: column;
  width: 20vw;
  `}
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${mediaMin.tabletLandscape`
  width: 80%;
  `}
`;

const ContentRow = styled.div`
    display: flex;
    width: 100%:
    height: 100%;
    flex-direction: column;
    padding: 0 36px;
    margin-bottom: 24px;
    ${mediaMin.tabletLandscape`
    margin-bottom: 120px;
    padding: 0;
    flex-direction: row;
    `}
`;

const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
  p {
    margin-bottom: 0;
  }
  ${mediaMin.tabletLandscape`
    width: 16%;
  `}
`;

const ImageColumn = styled.div`
  display: flex;
  flex-direction: column;
  .react-reveal {
    width: 100%;
  }
  ${mediaMin.tabletLandscape`
  width: 64%;
  `}
`;

const ImageSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 120px;
  ${mediaMin.tabletLandscape`
  width: ${(props) => (props.portrait ? '60%' : '100%')};
  `}
  .responsive-image {
    cursor: pointer;
  }
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 24px;
  box-sizing: border-box;
  position: ${(props) => (props.activeSection > 0 ? 'fixed' : 'relative')};
  ${(props) => {
    if (props.activeSection > 0) {
      return 'width: 16%; top: 64px;';
    }
    return '';
  }}
  h3 {
    color: ${variables.colors.darkRed};
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 16px;
    width: 100%;
    margin: 0 0 16px 0;
  }
  p {
    color: ${variables.colors.darkGray};
    font-family: 'Granjon';
    font-size: 17px;
    line-height: 22px;
    width: 100%;
    margin-bottom: 0;
    ${mediaMin.tabletLandscape`
      font-size: 2.5vh;
      line-height: 3.5vh;
    `}
  }
`;

const CopyHeading = styled.h3`
  color: ${variables.colors.darkRed};
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 16px;
  min-height: 20px;
  width: 100%;
  margin: 0 0 16px 0;
`;

const CopyBody = styled.p`
  color: ${variables.colors.darkGray};
  font-family: 'Granjon',serif;
  width: 100%;
  margin: 0;
  font-size: 2.5vh;
  line-height: 3.5vh;
`;

const defaultStyle = {
  opacity: 0,
  transition: 'opacity 0.5s ease-in-out 0.5s, transform 0.5s ease-in-out 0.5s',
};

const bodyTransitionStyles = {
  entering: {
    opacity: 1,
    transform: 'translateY(64px)',
    transition: 'opacity 0.5s ease-in-out 0.25s, transform 1s ease-in-out',
  },
  entered: {
    opacity: 1,
    transform: 'translateY(0)',
    transition: 'opacity 0.5s ease-in-out 0.25s, transform 1s ease-in-out',
  },
  exiting: {
    opacity: 0,
    transform: 'translateY(0)',
    transition: 'opacity 0.5s ease-in-out , transform 0s ease-in-out 0.5s',
  },
  exited: {
    opacity: 0,
    transform: 'translateY(64px)',
    transition: 'opacity 0.5s ease-in-out , transform 0s ease-in-out 0.5s',
  },
};

const headingTransitionStyles = {
  entering: {
    opacity: 1,
    transition: 'opacity 0.25s ease-in-out 0.25s',
  },
  entered: {
    opacity: 1,
    transition: 'opacity 0.25s ease-in-out 0.25s',
  },
  exiting: {
    opacity: 0,
    transition: 'opacity 0.25s ease-in-out 0.25s',
  },
  exited: { opacity: 0 },
};

export {
  MainCol,
  MobileCol,
  MainRow,
  PaddingColumn,
  RightColumn,
  ContentRow,
  TextColumn,
  ImageColumn,
  TextSection,
  ImageSection,
  CopyHeading,
  CopyBody,
  defaultStyle,
  bodyTransitionStyles,
  headingTransitionStyles,
};
