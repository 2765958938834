const galleryLayout = {
  row_1: [
    {
      width: '80%',
      imgs: [
        {
          src: '/images/gallery/gallery_1',
          alt: 'Living room with multiple corner exposures',
          clickIdx: 0,
        },
      ],
    },
    {
      width: '20%',
      imgs: [
        {
          src: '/images/gallery/gallery_2',
          alt: '',
          margin: 'auto 0 0 0',
        },
      ],
    },
  ],
  row_2: [
    {
      width: '60%',
      imgs: [
        { src: '/images/gallery/gallery_3', alt: 'Extraordinary views are forever in style' },
        {
          src: '/images/gallery/gallery_4',
          alt: 'Madison Square Park art installation',
          clickIdx: 1,
          width: '66%',
        },
      ],
    },
    {
      width: '40%',
      imgs: [
        {
          src: '/images/gallery/gallery_5',
          alt: 'Detail of residence corner with Empire State Building Views',
          clickIdx: 2,
        },
      ],
    },
  ],
  row_3: [
    {
      width: '20%',
      imgs: [
        {
          src: '/images/gallery/gallery_6',
          alt: '',
          margin: 'auto 0 0 0',
        },
      ],
    },
    {
      width: '80%',
      imgs: [{ src: '/images/gallery/gallery_7', alt: "Madison Houses's crown", clickIdx: 3 }],
    },
  ],
  row_4: [
    {
      width: '40%',
      imgs: [{ src: '/images/gallery/gallery_8', alt: 'Shopping in NoMad', clickIdx: 4 }],
    },
    { width: '20%', imgs: [] },
    {
      width: '40%',
      imgs: [
        {
          src: '/images/gallery/gallery_9',
          alt: '',
          width: '50%',
          margin: '0 auto 0 0',
        },
        { src: '/images/gallery/gallery_10', alt: 'Outside the Ace Hotel', clickIdx: 5 },
      ],
    },
  ],
  row_5: [
    { width: '10%', imgs: [] },
    {
      width: '40%',
      imgs: [
        { src: '/images/gallery/gallery_11', alt: '2,800-SF landscaped roof garden', clickIdx: 6 },
      ],
    },
    { width: '20%', imgs: [] },
    {
      width: '20%',
      imgs: [{ src: '/images/gallery/gallery_12', alt: '' }],
    },
    { width: '10%', imgs: [] },
  ],
  row_6: [
    { width: '10%', imgs: [] },
    { width: '20%', imgs: [{ src: '/images/gallery/gallery_13', alt: '' }] },
    {
      width: '60%',
      imgs: [
        {
          src: '/images/gallery/gallery_14',
          alt: 'Kitchen with custom cabinetry and Gaggenau appliances',
          clickIdx: 7,
        },
      ],
    },
    { width: '10%', imgs: [] },
  ],
  row_7: [
    {
      width: '40%',
      imgs: [{ src: '/images/gallery/gallery_15', alt: 'Culture in NoMad', clickIdx: 8 }],
    },
    { width: '10%', imgs: [] },
    {
      width: '40%',
      imgs: [
        {
          src: '/images/gallery/gallery_16',
          alt: '',
          width: '50%',
          margin: '0 auto 0 0',
        },
        { src: '/images/gallery/gallery_17', alt: 'Stumptown Coffee Roasters', clickIdx: 9 },
      ],
    },
    { width: '10%', imgs: [] },
  ],
  row_8: [
    { width: '10%', imgs: [] },
    { width: '20%', imgs: [{ src: '/images/gallery/gallery_18', alt: '' }] },
    { width: '10%', imgs: [] },
    {
      width: '40%',
      imgs: [
        {
          src: '/images/gallery/gallery_19',
          alt: "Detail of Maidison Houses's Crown",
          clickIdx: 10,
        },
      ],
    },
    { width: '20%', imgs: [] },
  ],
  row_9: [
    { width: '10%', imgs: [] },
    {
      width: '60%',
      imgs: [
        {
          src: '/images/gallery/gallery_20',
          alt: 'Light-filled fitness center, 120’ in the air',
          clickIdx: 11,
        },
      ],
    },
    { width: '10%', imgs: [] },
    {
      width: '20%',
      imgs: [
        {
          src: '/images/gallery/gallery_21',
          alt: '',
          margin: 'auto 0 0 0',
        },
      ],
    },
  ],
  row_10: [
    { width: '10%', imgs: [] },
    {
      width: '30%',
      imgs: [
        {
          src: '/images/gallery/gallery_22',
          alt: '',
          margin: 'auto 0 0 0',
        },
      ],
    },
    { width: '20%', imgs: [] },
    {
      width: '20%',
      imgs: [{ src: '/images/gallery/gallery_23', alt: "Rudy's Barbershop", clickIdx: 12 }],
    },
    { width: '20%', imgs: [] },
  ],
  row_11: [
    { width: '10%', imgs: [] },
    {
      width: '30%',
      imgs: [
        {
          src: '/images/gallery/gallery_24',
          alt: 'Master Bathroom designed by Gachot',
          clickIdx: 13,
        },
      ],
    },
    { width: '20%', imgs: [] },
    {
      width: '20%',
      imgs: [{ src: '/images/gallery/gallery_25', alt: '' }],
    },
    { width: '20%', imgs: [] },
  ],
  row_12: [
    { width: '10%', imgs: [] },
    {
      width: '80%',
      imgs: [
        {
          src: '/images/gallery/gallery_26',
          alt: 'Living room with Empire State Building views',
          clickIdx: 14,
        },
      ],
    },
    { width: '10%', imgs: [] },
  ],
  row_13: [
    { width: '10%', imgs: [] },
    {
      width: '40%',
      imgs: [{ src: '/images/gallery/gallery_27', alt: 'The Flatiron Building', clickIdx: 15 }],
    },
    { width: '10%', imgs: [] },
    {
      width: '40%',
      imgs: [
        { src: '/images/gallery/gallery_28', alt: '' },
        {
          src: '/images/gallery/gallery_29',
          alt: '',
          width: '50%',
          margin: '0 auto',
        },
      ],
    },
  ],
  row_14: [
    {
      width: '40%',
      imgs: [
        {
          src: '/images/gallery/gallery_30',
          alt: '',
          width: '50%',
          margin: '0 0 0 auto',
        },
        { src: '/images/gallery/gallery_31', alt: 'Cyclist on Madison Ave', clickIdx: 16 },
      ],
    },
    { width: '20%', imgs: [] },
    {
      width: '40%',
      imgs: [{ src: '/images/gallery/gallery_32', alt: 'Lifestyle in NoMad', clickIdx: 17 }],
    },
  ],
  row_15: [
    { width: '10%', imgs: [] },
    {
      width: '20%',
      imgs: [
        {
          src: '/images/gallery/gallery_33',
          alt: '',
          margin: 'auto 0 0 0',
        },
      ],
    },
    { width: '10%', imgs: [] },
    {
      width: '60%',
      imgs: [
        {
          src: '/images/gallery/gallery_34',
          alt: 'Double-height club room designed by Gachot',
          clickIdx: 18,
        },
      ],
    },
  ],
  row_16: [
    {
      width: '80%',
      imgs: [
        {
          src: '/images/gallery/gallery_35',
          alt: '75-foot lap pool with hot tub, cold plunge, and sauna',
          clickIdx: 19,
        },
      ],
    },
    {
      width: '20%',
      imgs: [{ src: '/images/gallery/gallery_36', alt: '' }],
    },
  ],
  row_17: [
    {
      width: '10%',
      imgs: [],
    },
    {
      width: '40%',
      imgs: [{ src: '/images/gallery/gallery_37', alt: 'Madison House at Dusk', clickIdx: 20 }],
    },
    {
      width: '20%',
      imgs: [],
    },
    {
      width: '30%',
      imgs: [
        { src: '/images/gallery/gallery_38', alt: '', clickIdx: 21 },
        {
          src: '/images/gallery/gallery_39',
          alt: '',
          width: '66%',
          margin: '0 auto 0 0',
        },
      ],
    },
  ],
  row_18: [
    {
      width: '40%',
      imgs: [
        { src: '/images/gallery/gallery_40', alt: '' },
        {
          src: '/images/gallery/gallery_41',
          alt: '',
          width: '50%',
          margin: '0 auto',
        },
      ],
    },
    {
      width: '60%',
      imgs: [{ src: '/images/gallery/gallery_42', alt: 'The Madison House façade', clickIdx: 22 }],
    },
  ],
  row_19: [
    {
      width: '40%',
      imgs: [{ src: '/images/gallery/gallery_43', alt: 'Dining in NoMad', clickIdx: 23 }],
    },
    {
      width: '20%',
      imgs: [],
    },
    {
      width: '40%',
      imgs: [
        { src: '/images/gallery/gallery_44', alt: 'Flowers in Madison Square Park', clickIdx: 24 },
        {
          src: '/images/gallery/gallery_45',
          alt: '',
          width: '50%',
          margin: '0 auto 0 0',
        },
      ],
    },
  ],
  row_20: [
    {
      width: '40%',
      imgs: [
        {
          src: '/images/gallery/gallery_46',
          alt: '',
          margin: 'auto 0 0 0',
        },
      ],
    },
    {
      width: '60%',
      imgs: [{ src: '/images/gallery/gallery_47', alt: 'Entrance on 30th Street', clickIdx: 25 }],
    },
  ],
  row_21: [
    {
      width: '10%',
      imgs: [],
    },
    {
      width: '40%',
      imgs: [{ src: '/images/gallery/gallery_48', alt: 'Landmarks in NoMad', clickIdx: 26 }],
    },
    {
      width: '10%',
      imgs: [],
    },
    {
      width: '40%',
      imgs: [
        {
          src: '/images/gallery/gallery_49',
          alt: 'Purfumes at Le Labo',
          clickIdx: 27,
        },
        {
          src: '/images/gallery/gallery_50',
          alt: '',
          width: '50%',
          margin: '0 auto 0 0',
        },
      ],
    },
  ],
  row_22: [
    {
      width: '10%',
      imgs: [],
    },
    {
      width: '20%',
      imgs: [{ src: '/images/gallery/gallery_51', alt: '25th and Broadway', clickIdx: 28 }],
    },
    {
      width: '20%',
      imgs: [],
    },
    {
      width: '40%',
      imgs: [
        {
          src: '/images/gallery/gallery_52',
          alt: 'Lobby offering 24-hour concierge',
          clickIdx: 29,
        },
      ],
    },
    {
      width: '10%',
      imgs: [],
    },
  ],
  row_23: [
    {
      width: '60%',
      imgs: [
        { src: '/images/gallery/gallery_53', alt: '14-seat private dining room', clickIdx: 30 },
      ],
    },
    {
      width: '10%',
      imgs: [],
    },
    {
      width: '30%',
      imgs: [{ src: '/images/gallery/gallery_54', alt: '' }],
    },
  ],
  row_24: [
    {
      width: '20%',
      imgs: [
        {
          src: '/images/gallery/gallery_55',
          alt: '',
          margin: 'auto 0 0 0',
        },
      ],
    },
    {
      width: '10%',
      imgs: [],
    },
    {
      width: '30%',
      imgs: [
        {
          src: '/images/gallery/gallery_56',
          alt: 'Reception Lounge overlooking the glassed-in garden',
          clickIdx: 31,
        },
      ],
    },
    {
      width: '10%',
      imgs: [],
    },
    {
      width: '30%',
      imgs: [{ src: '/images/gallery/gallery_57', alt: 'Poliform', clickIdx: 32 }],
    },
  ],
};

const galleryPhotos = [
  {
    src: '/images/gallery/gallery_1',
    alt: 'Living room with multiple corner exposures',
    link: { text: 'Go to Residences & Views', url: '/nomad-new-condominiums' },
  },
  {
    src: '/images/gallery/gallery_4',
    alt: 'Madison Square Park art installation',
    link: { text: 'Go to NoMad Story', url: '/nomad-story' },
  },
  {
    src: '/images/gallery/gallery_5',
    alt: 'Detail of residence corner with Empire State Building Views',
    link: { text: 'Go to Residences & Views', url: '/nomad-new-condominiums' },
  },
  {
    src: '/images/gallery/gallery_7',
    alt: "Madison Houses's crown",
    link: { text: 'Go to Architecture', url: '/manhattan-luxury-condominiums' },
  },
  {
    src: '/images/gallery/gallery_8',
    alt: 'Shopping in NoMad',
    link: { text: 'Go to NoMad Guide', url: 'nomad-neighborhood-guide' },
  },
  {
    src: '/images/gallery/gallery_10',
    alt: 'Outside the Ace Hotel',
    link: { text: 'Go to NoMad Story', url: '/nomad-story' },
  },
  {
    src: '/images/gallery/gallery_11',
    alt: '2,800-SF landscaped roof garden',
    link: { text: 'Go to Amenities', url: '/nomad-new-condominium-amenities' },
  },
  {
    src: '/images/gallery/gallery_14',
    alt: 'Kitchen with custom cabinetry and Gaggenau appliances',
    link: { text: 'Go to Residences & Views', url: '/nomad-new-condominiums' },
  },
  {
    src: '/images/gallery/gallery_15',
    alt: 'Culture in NoMad',
    link: { text: 'Go to NoMad Guide', url: 'nomad-neighborhood-guide' },
  },
  {
    src: '/images/gallery/gallery_17',
    alt: 'Stumptown Coffee Roasters',
    link: { text: 'Go to NoMad Story', url: '/nomad-story' },
  },
  {
    src: '/images/gallery/gallery_19',
    alt: "Detail of Maidison Houses's Crown",
    link: { text: 'Go to Architecture', url: '/manhattan-luxury-condominiums' },
  },
  {
    src: '/images/gallery/gallery_20',
    alt: 'Light-filled fitness center, 120’ in the air',
    link: { text: 'Go to Amenities', url: '/nomad-new-condominium-amenities' },
  },
  {
    src: '/images/gallery/gallery_23',
    alt: "Rudy's Barbershop",
    link: { text: 'Go to NoMad Story', url: '/nomad-story' },
  },
  {
    src: '/images/gallery/gallery_24',
    alt: 'Master Bathroom designed by Gachot',
    link: { text: 'Go to Residences & Views', url: '/nomad-new-condominiums' },
  },
  {
    src: '/images/gallery/gallery_26',
    alt: 'Living room with Empire State Building views',
    link: { text: 'Go to Residences & Views', url: '/nomad-new-condominiums' },
  },
  {
    src: '/images/gallery/gallery_27',
    alt: 'The Flatiron Building',
    link: { text: 'Go to NoMad Story', url: '/nomad-story' },
  },
  {
    src: '/images/gallery/gallery_31',
    alt: 'Cyclist on Madison Ave',
    link: { text: 'Go to NoMad Story', url: '/nomad-story' },
  },
  {
    src: '/images/gallery/gallery_32',
    alt: 'Lifestyle in NoMad',
    link: { text: 'Go to NoMad Guide', url: 'nomad-neighborhood-guide' },
  },
  {
    src: '/images/gallery/gallery_34',
    alt: 'Double-height club room designed by Gachot',
    link: { text: 'Go to Amenities', url: '/nomad-new-condominium-amenities' },
  },
  {
    src: '/images/gallery/gallery_35',
    alt: '75-foot lap pool with hot tub, cold plunge, and sauna',
    link: { text: 'Go to Amenities', url: '/nomad-new-condominium-amenities' },
  },
  {
    src: '/images/gallery/gallery_37',
    alt: 'Madison House at Dusk',
    link: { text: 'Go to Architecture', url: '/manhattan-luxury-condominiums' },
  },
  {
    src: '/images/gallery/gallery_38',
    alt: 'Dinner and drinks at Simon & The Whale',
    link: { text: 'Go to NoMad Story', url: '/nomad-story' },
  },
  {
    src: '/images/gallery/gallery_42',
    alt: 'The Madison House façade',
    link: { text: 'Go to Architecture', url: '/manhattan-luxury-condominiums' },
  },
  {
    src: '/images/gallery/gallery_43',
    alt: 'Dining in NoMad',
    link: { text: 'Go to NoMad Guide', url: 'nomad-neighborhood-guide' },
  },
  {
    src: '/images/gallery/gallery_44',
    alt: 'Flowers in Madison Square Park',
    link: { text: 'Go to NoMad Story', url: '/nomad-story' },
  },
  {
    src: '/images/gallery/gallery_47',
    alt: 'Entrance on 30th Street',
    link: { text: 'Go to Architecture', url: '/manhattan-luxury-condominiums' },
  },
  {
    src: '/images/gallery/gallery_48',
    alt: 'Landmarks in NoMad',
    link: { text: 'Go to NoMad Guide', url: 'nomad-neighborhood-guide' },
  },
  {
    src: '/images/gallery/gallery_49',
    alt: 'Purfumes at Le Labo',
    link: { text: 'Go to NoMad Story', url: '/nomad-story' },
  },
  {
    src: '/images/gallery/gallery_51',
    alt: '25th and Broadway',
    link: { text: 'Go to NoMad Story', url: '/nomad-story' },
  },
  {
    src: '/images/gallery/gallery_52',
    alt: 'Lobby offering 24-hour concierge',
    link: { text: 'Go to Architecture', url: '/manhattan-luxury-condominiums' },
  },
  {
    src: '/images/gallery/gallery_53',
    alt: '14-seat private dining room',
    link: { text: 'Go to Amenities', url: '/nomad-new-condominium-amenities' },
  },
  {
    src: '/images/gallery/gallery_56',
    alt: 'Reception Lounge overlooking the glassed-in garden',
    link: { text: 'Go to Amenities', url: '/nomad-new-condominium-amenities' },
  },
  {
    src: '/images/gallery/gallery_57',
    alt: 'Poliform',
    link: { text: 'Go to NoMad Story', url: '/nomad-story' },
  },
];

const galleryPhotosMobile = [
  {
    src: '/images/gallery/gallery_1',
    alt: 'Living room with multiple corner exposures',
    link: { text: 'Go to Residences & Views', url: '/nomad-new-condominiums' },
  },
  {
    src: '/images/gallery/gallery_4',
    alt: 'Madison Square Park art installation',
    link: { text: 'Go to NoMad Story', url: '/nomad-story' },
    width: '50%',
  },
  {
    src: '/images/gallery/gallery_5',
    alt: 'Detail of residence corner with Empire State Building Views',
    link: { text: 'Go to Residences & Views', url: '/nomad-new-condominiums' },
    width: '50%',
    margin: '0 0 0 auto',
  },
  {
    src: '/images/gallery/gallery_7',
    alt: "Madison Houses's crown",
    link: { text: 'Go to Architecture', url: '/manhattan-luxury-condominiums' },
    width: '75%',
    margin: '0 auto 0 0',
  },
  {
    src: '/images/gallery/gallery_10',
    alt: 'Outside the Ace Hotel',
    link: { text: 'Go to NoMad Story', url: '/nomad-story' },
    width: '50%',
    margin: '0 0 0 auto',
  },
  {
    src: '/images/gallery/gallery_11',
    alt: '2,800-SF landscaped roof garden',
    link: { text: 'Go to Amenities', url: '/nomad-new-condominium-amenities' },
    width: '50%',
  },
  {
    src: '/images/gallery/gallery_14',
    alt: 'Kitchen with custom cabinetry and Gaggenau appliances',
    link: { text: 'Go to Residences & Views', url: '/nomad-new-condominiums' },
    width: '75%',
    margin: '0 0 0 auto',
  },
  {
    src: '/images/gallery/gallery_17',
    alt: 'Stumptown Coffee Roasters',
    link: { text: 'Go to NoMad Story', url: '/nomad-story' },
    width: '50%',
    margin: '0 auto 0 0',
  },
  {
    src: '/images/gallery/gallery_19',
    alt: "Detail of Maidison Houses's Crown",
    link: { text: 'Go to Architecture', url: '/manhattan-luxury-condominiums' },
    width: '50%',
  },
  {
    src: '/images/gallery/gallery_20',
    alt: 'Light-filled fitness center, 120’ in the air',
    link: { text: 'Go to Amenities', url: '/nomad-new-condominium-amenities' },
  },
  {
    src: '/images/gallery/gallery_23',
    alt: "Rudy's Barbershop",
    link: { text: 'Go to NoMad Story', url: '/nomad-story' },
    width: '50%',
    margin: '0 0 0 auto',
  },
  {
    src: '/images/gallery/gallery_24',
    alt: 'Master Bathroom designed by Gachot',
    link: { text: 'Go to Residences & Views', url: '/nomad-new-condominiums' },
    width: '50%',
  },
  {
    src: '/images/gallery/gallery_26',
    alt: 'Living room with Empire State Building views',
    link: { text: 'Go to Residences & Views', url: '/nomad-new-condominiums' },
    width: '75%',
    margin: '0 auto 0 0',
  },
  {
    src: '/images/gallery/gallery_27',
    alt: 'The Flatiron Building',
    link: { text: 'Go to NoMad Story', url: '/nomad-story' },
    width: '50%',
    margin: '0 0 0 auto',
  },
  {
    src: '/images/gallery/gallery_31',
    alt: 'Cyclist on Madison Ave',
    link: { text: 'Go to NoMad Story', url: '/nomad-story' },
    width: '50%',
  },
  {
    src: '/images/gallery/gallery_34',
    alt: 'Double-height club room designed by Gachot',
    link: { text: 'Go to Amenities', url: '/nomad-new-condominium-amenities' },
    width: '75%',
    margin: '0 0 0 auto',
  },
  {
    src: '/images/gallery/gallery_35',
    alt: '75-foot lap pool with hot tub, cold plunge, and sauna',
    link: { text: 'Go to Amenities', url: '/nomad-new-condominium-amenities' },
  },
  {
    src: '/images/gallery/gallery_37',
    alt: 'Madison House at Dusk',
    link: { text: 'Go to Architecture', url: '/manhattan-luxury-condominiums' },
    width: '50%',
  },
  {
    src: '/images/gallery/gallery_38',
    alt: 'Dinner and drinks at Simon & The Whale',
    link: { text: 'Go to NoMad Story', url: '/nomad-story' },
    width: '50%',
    margin: '0 0 0 auto',
  },
  {
    src: '/images/gallery/gallery_42',
    alt: 'The Madison House façade',
    link: { text: 'Go to Architecture', url: '/manhattan-luxury-condominiums' },
    width: '75%',
    margin: '0 auto 0 0',
  },
  {
    src: '/images/gallery/gallery_44',
    alt: 'Flowers in Madison Square Park',
    link: { text: 'Go to NoMad Story', url: '/nomad-story' },
    width: '50%',
    margin: '0 0 0 auto',
  },
  {
    src: '/images/gallery/gallery_47',
    alt: 'Entrance on 30th Street',
    link: { text: 'Go to Architecture', url: '/manhattan-luxury-condominiums' },
    width: '75%',
    margin: '0 auto 0 0',
  },
  {
    src: '/images/gallery/gallery_49',
    alt: 'Purfumes at Le Labo',
    link: { text: 'Go to NoMad Story', url: '/nomad-story' },
    width: '50%',
  },
  {
    src: '/images/gallery/gallery_51',
    alt: '25th and Broadway',
    link: { text: 'Go to NoMad Story', url: '/nomad-story' },
    width: '50%',
    margin: '0 0 0 auto',
  },
  {
    src: '/images/gallery/gallery_52',
    alt: 'Lobby offering 24-hour concierge',
    link: { text: 'Go to Architecture', url: '/manhattan-luxury-condominiums' },
    width: '50%',
  },
  {
    src: '/images/gallery/gallery_53',
    alt: '14-seat private dining room',
    link: { text: 'Go to Amenities', url: '/nomad-new-condominium-amenities' },
    width: '75%',
    margin: '0 auto 0 0',
  },
  {
    src: '/images/gallery/gallery_56',
    alt: 'Reception Lounge overlooking the glassed-in garden',
    link: { text: 'Go to Amenities', url: '/nomad-new-condominium-amenities' },
    width: '50%',
    margin: '0 0 0 auto',
  },
  {
    src: '/images/gallery/gallery_57',
    alt: 'Poliform',
    link: { text: 'Go to NoMad Story', url: '/nomad-story' },
    width: '50%',
  },
];

export { galleryLayout, galleryPhotos, galleryPhotosMobile };
