/* eslint-disable react/no-array-index-key */
import React, { useContext, useLayoutEffect } from 'react';
import styled from 'styled-components';
// import LazyLoad from 'react-lazyload';
// import Fade from 'react-reveal/Fade';
import { Parallax, useController } from 'react-scroll-parallax';

import Context from '../../config/Context';
import ResponsiveImage from '../utils/responsive-image';
import { HeaderCol, HeaderLogo } from '../utils/page-header';
import { galleryLayout, galleryPhotos } from '../../data/gallery';
import PhotoViewer from '../photo-viewer';

const GalleryColumn = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  padding: 0 12%;
`;

const GalleryRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 80px;
`;

const InnerColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: ${(props) => (props.width ? props.width : '100%')};
  padding: 0 16px;
  box-sizing: border-box;
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
  .react-reveal {
    display: flex;
    height: 100%;
    width: 100%;
  }
  img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
  .parallax-outer {
    z-index: 1;
    height: 100%;
    .parallax-inner {
      display: flex;
      height: 100%;
      // transition: transform 0.1s ease-in-out;
    }
  }

  .illustration {
    z-index: 0;
  }
`;

const ParallaxCache = () => {
  const { parallaxController } = useController();

  useLayoutEffect(() => {
    const handler = () => parallaxController.update();
    window.addEventListener('load', handler);
    return () => window.removeEventListener('load', handler);
  }, [parallaxController]);

  return null;
};

const GalleryDesktop = () => {
  const context = useContext(Context);
  const { togglePhotoViewerState } = context;

  const generateRows = () => Object.values(galleryLayout).map((row, rowIdx) => (
    <GalleryRow key={rowIdx}>
      {row.map((col, colIdx) => (
        <InnerColumn width={col.width} key={colIdx}>
          {col.imgs.map((image) => (image.clickIdx !== undefined ? (
            <Parallax y={[10, -10]} key={image.src}>
              <ResponsiveImage
                srcPath={image.src}
                imgAlt={image.alt}
                margin={image.margin}
                width={image.width}
                onClickProp={
                      image.clickIdx !== undefined
                        ? () => togglePhotoViewerState(image.clickIdx)
                        : null
                    }
              />
            </Parallax>
          ) : (
            <Parallax className="illustration" y={[25, -25]} key={image.src}>
              <ResponsiveImage
                srcPath={image.src}
                imgAlt={image.alt}
                margin={image.margin}
                width={image.width}
                onClickProp={
                      image.clickIdx !== undefined
                        ? () => togglePhotoViewerState(image.clickIdx)
                        : null
                    }
              />
            </Parallax>
          )))}
        </InnerColumn>
      ))}
    </GalleryRow>
  ));

  return (
    <>
      <GalleryColumn>
        <HeaderCol>
          <HeaderLogo />
        </HeaderCol>
        {generateRows()}
      </GalleryColumn>
      <PhotoViewer photoArray={galleryPhotos} />
      <ParallaxCache />
    </>
  );
};

export default GalleryDesktop;
