import React from 'react';
import styled from 'styled-components';

import variables from '../../styles/Variables';

const ErrorMessage = styled.h2`
  margin: auto 0;
  color: ${variables.colors.darkRed};
  z-index: 100;
`;

const FilterError = () => <ErrorMessage>No Matching Results</ErrorMessage>;

export default FilterError;
