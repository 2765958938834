import { createGlobalStyle } from 'styled-components';

const AccessibilityStyles = createGlobalStyle`
  .accessibility-visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .accessibility-line-break {
    &::before {
      content: ' '; 
      display: block;
    }
  }
`;

export default AccessibilityStyles;
