const nomadGuideData = [
  {
    sectionTitle: 'DINING:',
    sectionSubTitle: 'RESTAURANTS,\nCAFÉS,\n& NIGHTLIFE',
    svgSection: 'dining',
    mobileImage: {
      src: '/images/nomad_guide/MH_map_dining',
      alt: 'Dining Illustration',
    },
    sectionBody: [
      { name: 'BLANK SLATE COFFEE AND KITCHEN', address: '121 MADISON AVENUE' },
      {
        name: 'THE CLOCKTOWER AT THE EDITION HOTEL',
        address: '5 MADISON AVENUE',
      },
      {
        name: 'COSME',
        address: '35 E 21ST STREET',
      },
      {
        name: 'ELEVEN MADISON PARK',
        address: '11 MADISON AVENUE',
      },
      {
        name: 'FRIEDMAN’S',
        address: '132 W 31ST STREET',
      },
      {
        name: 'GRAMERCY TAVERN',
        address: '42 E 20TH STREET',
      },
      {
        name: 'HANGAWI',
        address: '12 E 32ND STREET',
      },
      {
        name: 'LA PECORA BIANCA',
        address: '1133 BROADWAY',
      },
      {
        name: 'MAIALINO',
        address: '2 LEXINGTON AVENUE',
      },
      {
        name: 'PARK AVENUE AUTUMN/WINTER/SPRING/SUMMER',
        address: '360 PARK AVENUE S',
      },
      {
        name: 'SCARPETTA',
        address: '88 MADISON AVENUE',
      },
      {
        name: 'SIMON & THE WHALE',
        address: '23 LEXINGTON AVENUE',
      },
      {
        name: 'STUMPTOWN COFFEE ROASTERS',
        address: '18 W 29TH STREET',
      },
    ],
  },
  {
    sectionTitle: 'LANDMARKS:',
    sectionSubTitle: 'HISTORY,\nARCHITECTURE,\n& PARKS',
    svgSection: 'landmarks',
    mobileImage: {
      src: '/images/nomad_guide/MH_map_landmarks',
      alt: 'Landmarks Illustration',
    },
    sectionBody: [
      { name: 'ACE HOTEL', address: '20 W 29TH STREET' },
      {
        name: 'THE CHURCH OF THE TRANSFIGURATION',
        address: '1 E 29TH STREET',
      },
      {
        name: 'THE CLOCKTOWER',
        address: '5 MADISON AVENUE',
      },
      {
        name: 'EMPIRE STATE BUILDING',
        address: '20 W 34TH STREET',
      },
      {
        name: 'FLATIRON BUILDING',
        address: '175 FIFTH AVENUE',
      },
      {
        name: 'GRAMERCY PARK',
        address:
          'BETWEEN E 20TH AND E 21 ST STREETS AND BETWEEN GRAMERCY PARK W AND GRAMERCY PARK E',
      },
      {
        name: 'MADISON SQUARE PARK',
        address: '11 MADISON AVENUE',
      },
      {
        name: 'NEW YORK LIFE',
        address: '51 MADISON AVENUE',
      },
      {
        name: 'THE NOMAD HOTEL',
        address: '1 170 BROADWAY',
      },
      {
        name: 'PENNSYLVANIA STATION',
        address: 'SEVENTH AND EIGHTH AVENUES BETWEEN 31 ST AND 33RD STREETS',
      },
      {
        name: 'UNION SQUARE PARK',
        address: 'BETWEEN 14TH AND 17TH STREETS AND UNION SQUARE',
      },
    ],
  },
  {
    sectionTitle: 'SHOPPING:',
    sectionSubTitle: 'MARKETS,\nAPPAREL,\nBEAUTY,\n& DESIGN',
    svgSection: 'shopping',
    mobileImage: {
      src: '/images/nomad_guide/MH_map_shopping',
      alt: 'Shopping Illustration',
    },
    sectionBody: [
      { name: 'B&B ITALIA', address: '135 MADISON AVENUE' },
      { name: 'DDC DESIGN POST', address: '181 MADISON AVENUE' },
      { name: 'DOVER STREET MARKET', address: '160 LEXINGTON AVENUE' },
      { name: 'EATALY', address: '200 FIFTH AVENUE' },
      { name: 'KASSATEX', address: '295 FIFTH AVENUE' },
      { name: 'LE LABO', address: '14 W 29TH STREET' },
      { name: 'MINOTTI', address: '134 MADISON AVENUE' },
      { name: 'MOLTENI', address: '160 MADISON AVENUE' },
      { name: 'NEW YORK DESIGN CENTER', address: '200 LEXINGTON AVENUE' },
      { name: 'OPENING CEREMONY', address: '1190 BROADWAY' },
      { name: 'PORCELANOSA', address: '202 FIFTH AVENUE' },
      { name: 'RIZZOLI', address: '1133 BROADWAY' },
      { name: 'TODD SNYDER', address: '25 E 26TH STREET' },
      { name: 'WANT APOTHECARY', address: '1170 BROADWAY' },
    ],
  },
  {
    sectionTitle: 'CULTURE:',
    sectionSubTitle: 'ENTERTAINMENT,\nMEMBERSHIPS,\n& THE ARTS',
    svgSection: 'culture',
    mobileImage: {
      src: '/images/nomad_guide/MH_map_culture',
      alt: 'Culture Illustration',
    },
    sectionBody: [
      { name: 'BLENDER WORKSPACE', address: '135 MADISON AVENUE, 8TH FL' },
      { name: 'CENTER FOR BOOK ARTS', address: '28 W 27TH STREET' },
      { name: 'JAZZ STANDARD', address: '116 E 27TH STREET' },
      {
        name: 'MAD. SQ. ART - MADISON SQUARE PARK CONSERVANCY',
        address: '11 MADISON AVENUE',
      },
      { name: 'NEUEHOUSE', address: '110 E 25TH STREET' },
      { name: 'THE NOMAD UPSTAIRS', address: '1170 BROADWAY, 2ND FL' },
      { name: 'RIZZOLI', address: '1133 BROADWAY' },
      { name: 'THE ASSEMBLAGE', address: '114 E 25TH STREET' },
      { name: 'THE MORGAN LIBRARY & MUSEUM', address: '225 MADISON AVENUE' },
    ],
  },
  {
    sectionTitle: 'LIFESTYLE:',
    sectionSubTitle: 'SALONS,\nSPAS,\n& FITNESS',
    mobileImage: {
      src: '/images/nomad_guide/MH_map_lifestyle',
      alt: 'Lifestyle Illustration',
    },
    sectionBody: [
      { name: 'EXHALE FLATIRON', address: '19 W 2 1ST STREET' },
      { name: 'FLYWHEEL SPORTS FLATIRON', address: '39 W 21ST STREET' },
      { name: 'FLYWHEEL SPORTS NOMAD', address: '420 PARK AVENUE S' },
      { name: 'HEYDAY', address: '1130 BROADWAY' },
      { name: 'ORANGETHEORY FITNESS', address: '124 W 23RD STREET' },
      { name: 'RUDY’S BARBERSHOP', address: '14 W 29TH STREET' },
      { name: 'SALON RUGGERI', address: '254 FIFTH AVENUE, 4TH FL' },
      { name: 'SOLACE NEW YORK', address: '38 E 32ND STREET' },
      { name: 'SOULCYCLE', address: '12 W 27TH STREET' },
      { name: 'SUNDAYS NAIL STUDIO', address: '51 E 25TH STREET' },
      { name: 'TONE HOUSE', address: '32 E 31ST STREET' },
    ],
  },
];

const nomadGuideSVGKeys = {
  dining: {
    'BLANK SLATE COFFEE AND KITCHEN': 'dining-1',
    'THE CLOCKTOWER AT THE EDITION HOTEL': 'dining-2',
    COSME: 'dining-3',
    'ELEVEN MADISON PARK': 'dining-4',
    'FRIEDMAN’S': 'dining-5',
    'GRAMERCY TAVERN': 'dining-6',
    HANGAWI: 'dining-7',
    'LA PECORA BIANCA': 'dining-8',
    MAIALINO: 'dining-9',
    'PARK AVENUE AUTUMN/WINTER/SPRING/SUMMER': 'dining-10',
    SCARPETTA: 'dining-11',
    'SIMON & THE WHALE': 'dining-12',
    'STUMPTOWN COFFEE ROASTERS': 'dining-13',
  },
  landmarks: {
    'ACE HOTEL': 'landmarks-1',
    'THE CHURCH OF THE TRANSFIGURATION': 'landmarks-2',
    'THE CLOCKTOWER': 'landmarks-3',
    'EMPIRE STATE BUILDING': 'landmarks-4',
    'FLATIRON BUILDING': 'landmarks-5',
    // 'GRAMERCY PARK': 'landmarks-6',
    // 'MADISON SQUARE PARK': 'landmarks-7',
    'NEW YORK LIFE': 'landmarks-6',
    'THE NOMAD HOTEL': 'landmarks-8',
    'PENNSYLVANIA STATION': 'landmarks-7',
    // 'UNION SQUARE PARK': 'landmarks-11'
  },
  shopping: {
    'B&B ITALIA': 'shopping-1',
    'DDC DESIGN POST': 'shopping-2',
    'DOVER STREET MARKET': 'shopping-3',
    EATALY: 'shopping-4',
    KASSATEX: 'shopping-5',
    'LE LABO': 'shopping-6',
    MINOTTI: 'shopping-7',
    MOLTENI: 'shopping-8',
    'NEW YORK DESIGN CENTER': 'shopping-9',
    'OPENING CEREMONY': 'shopping-10',
    PORCELANOSA: 'shopping-11',
    RIZZOLI: 'shopping-12',
    'TODD SNYDER': 'shopping-13',
    'WANT APOTHECARY': 'shopping-14',
  },
  culture: {
    'BLENDER WORKSPACE': 'culture-1',
    'CENTER FOR BOOK ARTS': 'culture-2',
    'JAZZ STANDARD': 'culture-3',
    'MAD. SQ. ART - MADISON SQUARE PARK CONSERVANCY': 'culture-4',
    NEUEHOUSE: 'culture-5',
    'THE NOMAD UPSTAIRS': 'culture-6',
    RIZZOLI: 'culture-7',
    'THE ASSEMBLAGE': 'culture-8',
    'THE MORGAN LIBRARY & MUSEUM': 'culture-9',
  },
  lifestyle: {
    'EXHALE FLATIRON': 'lifestyle-1',
    'FLYWHEEL SPORTS FLATIRON': 'lifestyle-2',
    'FLYWHEEL SPORTS NOMAD': 'lifestyle-3',
    HEYDAY: 'lifestyle-4',
    'ORANGETHEORY FITNESS': 'lifestyle-5',
    'RUDY’S BARBERSHOP': 'lifestyle-6',
    'SALON RUGGERI': 'lifestyle-7',
    'SOLACE NEW YORK': 'lifestyle-8',
    SOULCYCLE: 'lifestyle-9',
    'SUNDAYS NAIL STUDIO': 'lifestyle-10',
    'TONE HOUSE': 'lifestyle-11',
  },
};

export { nomadGuideData, nomadGuideSVGKeys };
