import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import ScrollToTop from './components/utils/scroll-to-top';
import Navigation from './components/navigation/navigation';
import {
  Amenities,
  Architecture,
  Availability,
  Contact,
  Exposure,
  Gallery,
  Home,
  Legal,
  Listing,
  Guide,
  Penthouses,
  Press,
  Residences,
  Services,
  Story,
  Team,
} from './pages/_module';
import {
  AccessibilityStyles,
  GlobalStyles,
  TypographyStyles,
  ModalStyles,
  ReactPlayerStyles,
} from './styles/global/_module';
import ContextProvider from './provider/ContextProvider';

const App = () => (
  <div className="App">
    <AccessibilityStyles />
    <GlobalStyles />
    <TypographyStyles />
    <ModalStyles />
    <ReactPlayerStyles />
    <Router>
      <ScrollToTop>
        <ContextProvider>
          <Navigation />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/nomad-new-condominium-amenities" component={Amenities} />
            <Route path="/manhattan-luxury-condominiums" component={Architecture} />
            <Route path="/contact-madison-house" component={Contact} />
            <Route path="/exposures/:listing_idx" component={Exposure} />
            <Route path="/nomad-new-condominium-availability/:idx" component={Listing} />
            <Route path="/nomad-new-condominium-availability" component={Availability} />
            <Route path="/nomad-new-condominium-services" component={Services} />
            <Route path="/gallery" component={Gallery} />
            <Route path="/nomad-neighborhood-guide" component={Guide} />
            <Route path="/legal" component={Legal} />
            <Route path="/manhattan-penthouse-condominiums" component={Penthouses} />
            <Route path="/nomad-real-estate-news" component={Press} />
            <Route path="/nomad-new-condominiums" component={Residences} />
            <Route path="/nomad-story" component={Story} />
            <Route path="/nomad-real-estate-development" component={Team} />
            <Redirect to="/" />
          </Switch>
        </ContextProvider>
      </ScrollToTop>
    </Router>
  </div>
);

export default App;
