/* eslint-disable react/prop-types */
import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Squeeze } from 'react-burgers';
import { withRouter } from 'react-router-dom';

import { mediaMin } from '../../styles/MediaQueries';
import Context from '../../config/Context';
import variables from '../../styles/Variables';
import { navTitles } from '../../data/routes';
import DesktopLinks from './desktop-links';
import MobileLinks from './mobile-links';

const transitionColor = scrollProgress => {
  const inverse = 100 - scrollProgress;
  const val1 = inverse * 0.06;
  const val2 = inverse * 0.51;
  const val3 = scrollProgress < 44 ? 44 : scrollProgress;
  return `background-color: hsla(${val1}, ${val2}%, ${val3}%, 1);`;
};

const NavigationContainer = styled.div`
  position: fixed;
  z-index: 100;
  display: ${props => (props.navHiddenMobile ? ' none' : 'block')};
  ${mediaMin.tabletLandscape`
    background-color: transparent;
    display: ${props => (props.navHidden ? 'none' : 'block')};
  `};
`;

const ButtonContainer = styled.div`
  z-index: 101;
  .Burger {
    z-index: 101;
    position: fixed;
    padding: 0;
    width: 32px;
    height: 14px;
    top: 75px;
    left: 36px;
    transform: translateY(-${props => props.scrollProgress * 0.58}px);
    // transition: transform 0.05s ease-in-out;
    ${mediaMin.tabletLandscape`
    transform: translateY(0);
    top: 24px;
    left: 44px;
    `}
    .BurgerBox {
      height: 100%;
      width: 100%;
      .BurgerInner {
        ${props => transitionColor(props.scrollProgress)}
        ${props => (props.white ? 'background-color: #fff;' : '')};
        ${mediaMin.tabletLandscape`
        background-color: ${props => (props.white ? '#fff' : variables.colors.darkRed)};
        `}
        height: 2px;
        &:before,
        &:after {
          height: 2px;
          ${props => transitionColor(props.scrollProgress)}
          ${props => (props.white ? 'background-color: #fff;' : '')};

          ${mediaMin.tabletLandscape`
          background-color: ${props => (props.white ? '#fff' : variables.colors.darkRed)};
          `}
        }
        &:before {
          top: -6px;
        }
        &:after {
          bottom: -6px;
        }
      }
    }
  }
  .BurgerActive {
    .BurgerBox {
      .BurgerInner {
        background-color: #fff;
        ${mediaMin.tabletLandscape`
         background-color: ${props => (props.white ? '#fff' : variables.colors.darkRed)};
        `}
        &:before {
          top: 0px;
          background-color: #fff;
          ${mediaMin.tabletLandscape`
            background-color: ${props => (props.white ? '#fff' : variables.colors.darkRed)};
          `}
        }
        &:after {
          bottom: 0px;
          background-color: #fff;
          ${mediaMin.tabletLandscape`
            background-color: ${props => (props.white ? '#fff' : variables.colors.darkRed)};
          `}
        }
      }
    }
  }
`;

const PageTitle = styled.h1`
  background-color: ${variables.colors.darkRed};
  position: fixed;
  color: #fff;
  width: 100%;
  height: 48px;
  top: -48px;
  left: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 2px;
  transform: translateY(${props => props.scrollProgress}%);
  ${mediaMin.tabletLandscape`
    transform: translateY(0);
    background-color: transparent;
    width: unset;
    top: 64px;
    left: 44px;
    height: 20px;
    opacity: ${props => (props.open ? '0' : '1')};
    visibility: ${props => (props.open ? 'hidden' : 'visible')};
    transition: all 0.5s ease-in-out;
    color: ${props => (props.white ? '#fff' : variables.colors.darkRed)};
    z-index: 0;
  `}
`;

const hideNavigation = pathname => {
  if (pathname === '/') {
    return true;
  }
  if (
    pathname.split('/')[1] === 'nomad-new-condominium-availability' &&
    pathname.split('/').length === 3
  ) {
    return true;
  }
  if (pathname.split('/')[1] === 'exposures') {
    return true;
  }
  return false;
};

const hideNavigationMobile = pathname => {
  if (
    pathname.split('/')[1] === 'nomad-new-condominium-availability' &&
    pathname.split('/').length === 3
  ) {
    return true;
  }
  if (pathname.split('/')[1] === 'exposures') {
    return true;
  }
  return false;
};

const Navigation = ({ location }) => {
  const context = useContext(Context);
  const { pathname } = location;
  const { toggleNavigationState, navigationState } = context;
  const [scrollProgress, setScrollProgress] = useState(0);

  const updateScrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop || document.scrollingElement.scrollTop;
    let scrolled = (scrollPx * 100) / 193;
    if (scrolled > 100) {
      scrolled = 100;
    }
    setScrollProgress(scrolled);
  };

  useEffect(() => {
    window.addEventListener('scroll', updateScrollProgress);
    return () => {
      window.removeEventListener('scroll', updateScrollProgress);
    };
  }, []);

  return (
    <NavigationContainer
      navHidden={hideNavigation(pathname)}
      navHiddenMobile={hideNavigationMobile(pathname)}
    >
      <ButtonContainer
        scrollProgress={scrollProgress}
        white={pathname === '/manhattan-penthouse-condominiums' || pathname === '/'}
      >
        <Squeeze onClick={toggleNavigationState} active={navigationState} />
        <PageTitle
          scrollProgress={scrollProgress}
          white={pathname === '/manhattan-penthouse-condominiums'}
          open={navigationState}
        >
          {navTitles[pathname]}
        </PageTitle>
      </ButtonContainer>
      <DesktopLinks pathname={pathname} />
      <MobileLinks pathname={pathname} />
    </NavigationContainer>
  );
};

export default withRouter(Navigation);
