/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import ResponsiveImage from '../components/utils/responsive-image';
import Context from '../config/Context';
import variables from '../styles/Variables';
import { mediaMin } from '../styles/MediaQueries';
import LoadingSpinner from '../components/utils/loading-spinner';
import viewData from '../data/views';

const NextArrow = ({ className, onClick }) => (
  <img
    src="/images/logos/arrow-right.svg"
    alt="next arrow"
    className={className}
    onClick={onClick}
  />
);

const PrevArrow = ({ className, onClick }) => (
  <img
    src="/images/logos/arrow-left.svg"
    alt="previous arrow"
    className={className}
    onClick={onClick}
  />
);

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const ExposurePage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .slick-slider {
    width: 100%;
    height: 100%;
    .slick-list {
      margin: 0 auto;
      height: 100%;
      width: 100%;
      ${mediaMin.tabletLandscape`
      width: 75%;
      `}
      .slick-track {
        height: 100%;
        .slick-slide {
          height: 100%;
          div {
            height: 100%;
          }
        }
      }
    }
    .slick-arrow {
      height: 45px;
      z-index: 100;
      display: none;
      ${mediaMin.tabletLandscape`
      display: block;
      `}
    }
    .slick-next {
      right: 44px;
    }
    .slick-prev {
      left: 44px;
    }
  }
`;

const CloseRow = styled.div`
  position: fixed;
  z-index: 100;
  top: 75px;
  right: 36px;
  ${mediaMin.tabletLandscape`
  top: 24px;
  right: 44px;
  `}
  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover {
      text-decoration: none;
    }
    span {
      font-size: 16px;
      letter-spacing: 2px;
      line-height: 16px;
      color: ${variables.colors.darkRed};
      margin-right: 16px;
    }
  }
`;

const SliderSlide = styled.div`
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    max-height: 75vh;
    max-width: 100%;
    margin: 0 auto;
  }
  p {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: ${variables.colors.darkGray};
    font-family: AW Conqueror Didot;
  }
`;

// eslint-disable-next-line consistent-return
const generateSlides = (photoArray) => {
  console.log(photoArray);
  if (photoArray) {
    return photoArray.map((el) => (
      <SliderSlide key={el.src}>
        <ResponsiveImage srcPath={el.src} alt={el.alt} />
        <p>{el.alt}</p>
      </SliderSlide>
    ));
  }
};

const Exposure = ({ match }) => {
  const context = useContext(Context);
  const { availabilityData, fetchAvailabilityData } = context;
  const { params } = match;
  const [currentListing, setCurrentListing] = useState(false);
  const [currentViews, setCurrentViews] = useState([]);
  const [hasViews, setHasViews] = useState(true);

  useEffect(() => {
    if (availabilityData === null) {
      fetchAvailabilityData();
    }
  }, []);

  useEffect(() => {
    if (availabilityData) {
      setCurrentListing(availabilityData[params.listing_idx]);
    }
  }, [availabilityData]);

  useEffect(() => {
    if (currentListing) {
      setCurrentViews(viewData[currentListing.unit]);
    }
  }, [currentListing]);

  useEffect(() => {
    setHasViews(!!currentViews);
  }, [currentViews]);

  return currentListing ? (
    hasViews ? (
      <>
        <Helmet>
          <title>New Luxury Condominiums in NoMad | Madison House</title>
          <meta
            name="description"
            content="1-4 Bedroom residences, Madison House offers a double-height attended lobby and 30,000 square feet of amenities."
          />
        </Helmet>
        <ExposurePage>
          <CloseRow>
            <Link to="/nomad-new-condominium-availability">
              <span>ALL LISTINGS</span>
              <img src="/images/logos/close.svg" alt="back to availability list" />
            </Link>
          </CloseRow>
          <Slider {...settings}>{generateSlides(currentViews)}</Slider>
        </ExposurePage>
      </>
    ) : (
      <Redirect to="/nomad-new-condominium-availability" />
    )
  ) : (
    <ExposurePage>
      <LoadingSpinner />
    </ExposurePage>
  );
};

export default withRouter(Exposure);
