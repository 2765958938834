const architectureCopy = {
  desktop: {
    heading: 'AROUND\nEVERY\nCORNER,\nA NEW DAY',
    subHeading:
      'Madison House, a dramatic and shimmering tower designed by Handel Architects in the heart of NoMad, residences start 150 feet up and feature corner exposures, presenting each residence with unique and compelling views.',
    sectionOneText:
      'The Madison House facade evokes the corded formations of natural basalt and plays off the setback architecture of surrounding landmarks.\n\nResidents experience direct contact with the extraordinary cityscape—with 11-foot ceilings and nary a column in sight.',
    sectionTwoTitle: 'ARRIVAL',
    sectionTwoText:
      'From 30th Street, enter a majestic garden-framed lobby that transitions residents from the excitement of NoMad to gracious living. The 24-hour concierge offers a warm welcome.',
    sectionThreeText:
      'Overhead in the lobby, sculptural lighting echoes the pattern of dragonfly wings, signaling Madison House’s place in the heart of the design district. This is a building where special care has been taken, from the artwork in the foyer to the garden terraces of the double-height penthouses.',
  },
  mobile: {
    heading: 'AROUND\nEVERY\nCORNER,\nA NEW DAY',
    subHeading:
      'Madison House, a dramatic and shimmering tower designed by Handel Architects, residences start 150 feet up and feature corner exposures, presenting each residence with unique and compelling views.',
    sectionOneText: '',
    sectionTwoTitle: 'ARRIVAL',
    sectionTwoText: 'Lobby on 30th Street offering 24-hour concierge',
    sectionThreeText: '',
  },
};

const architecturePhotos = [
  { src: '/images/architecture/architecture_1', alt: "Detail of Madison House's crown" },
  { src: '/images/architecture/architecture_2', alt: 'The Madison House façade' },
  { src: '/images/architecture/architecture_3', alt: 'Entrance on 30th Street' },
  { src: '/images/architecture/architecture_4', alt: 'Lobby offering 24-hour concierge.' },
];

export { architectureCopy, architecturePhotos };
