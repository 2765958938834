const truncateSummary = (summary) => {
  let sumArray = summary.split(' ');
  if (sumArray.length < 40) {
    return summary;
  }
  sumArray = sumArray.slice(0, 40);
  let newSum = sumArray.join(' ');
  newSum += '...';
  return newSum;
};

const parseDateforScreenReader = (date) => {
  const dateArray = date.split('.');
  const year = dateArray[2];
  const monthIndex = dateArray[0] - 1;
  const day = dateArray[1];
  return new Date(year, monthIndex, day);
};

export { truncateSummary, parseDateforScreenReader };
