/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import ResponsiveImage from './utils/responsive-image';
import variables from '../styles/Variables';
import { mediaMin } from '../styles/MediaQueries';
import Context from '../config/Context';

const NextArrow = ({ className, onClick }) => (
  <img
    src="/images/logos/arrow-right.svg"
    alt="next arrow"
    className={className}
    onClick={onClick}
  />
);

const PrevArrow = ({ className, onClick }) => (
  <img
    src="/images/logos/arrow-left.svg"
    alt="previous arrow"
    className={className}
    onClick={onClick}
  />
);

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const SliderContainer = styled.div`
  opacity: ${(props) => (props.active ? '1' : '0')};
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  transition: all 0.5s ease-in-out;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  boottom: 0;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 150;
  .slick-slider {
    width: 100%;
    height: 100%;
    .slick-list {
      margin: 0 auto;
      height: 100%;
      width: 100%;
      ${mediaMin.tabletLandscape`
      width: 75%;
      `}
      .slick-track {
        height: 100%;
        .slick-slide {
          height: 100%;
          div {
            height: 100%;
          }
        }
      }
    }
    .slick-arrow {
      display: none;
      ${mediaMin.tabletLandscape`
      display: block;
      `}
      height: 45px;
      z-index: 100;
    }
    .slick-next {
      right: 44px;
    }
    .slick-prev {
      left: 44px;
    }
  }
`;

const SliderSlide = styled.div`
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    max-height: 75vh;
    max-width: 100%;
    margin: 0 auto;
  }
  p {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: ${variables.colors.darkGray};
    font-family: Granjon;
  }
  a {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: ${variables.colors.darkRed};
    text-transform: uppercase;
    &:visited {
      color: ${variables.colors.darkRed};
    }
    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
  }
`;

const CloseButton = styled.button`
  position: fixed;
  z-index: 150;
  border: none;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  top: 75px;
  right: 36px;
  &:focus {
    outline: none;
  }
  ${mediaMin.tabletLandscape`
  top: 24px;
  right: 44px;
  `}
`;

const PhotoViewer = ({ photoArray }) => {
  const context = useContext(Context);
  const {
    activePhoto, photoViewerState, togglePhotoViewerState, closeOverlays,
  } = context;

  const sliderRef = useRef(null);

  const generateSlides = () => photoArray.map((el) => (
    <SliderSlide key={el.src}>
      <ResponsiveImage srcPath={el.src} alt={el.alt} />
      <p>{el.alt}</p>
      {el.link && (
      <Link to={el.link.url} onClick={closeOverlays}>
        {el.link.text}
      </Link>
      )}
    </SliderSlide>
  ));

  useEffect(() => {
    sliderRef.current.slickGoTo(activePhoto, true);
  }, [activePhoto]);

  return (
    <SliderContainer active={photoViewerState}>
      <CloseButton onClick={togglePhotoViewerState}>
        <img src="/images/logos/close.svg" alt="close overlay" />
      </CloseButton>
      <Slider ref={sliderRef} {...settings}>
        {generateSlides()}
      </Slider>
    </SliderContainer>
  );
};

export default PhotoViewer;
