import React, { useContext } from 'react';
import Fade from 'react-reveal/Fade';

import Context from '../../config/Context';
import {
  MobileCol,
  MainRow,
  RightColumn,
  ContentRow,
  TextColumn,
  ImageColumn,
} from '../utils/desktop-columns';
import {
  MobileHeaderCol,
  HeaderLogo,
  GrayBackground,
  HeroImage,
  MobilePageTitle,
  MobileImageColumn,
} from '../utils/page-header';
import ResponsiveImage from '../utils/responsive-image';
import { servicesCopy } from '../../data/services';

const ServicesMobile = () => {
  const context = useContext(Context);
  const { togglePhotoViewerState } = context;
  return (
    <MobileCol>
      <MobileHeaderCol>
        <HeaderLogo />
        <MobilePageTitle>SERVICES</MobilePageTitle>
        <MobileImageColumn>
          <HeroImage>
            <ResponsiveImage
              onClickProp={() => {
                togglePhotoViewerState(0);
              }}
              srcPath="/images/services/services_1"
            />
          </HeroImage>
          <h2>{servicesCopy.mobile.heading}</h2>
        </MobileImageColumn>
        <GrayBackground />
      </MobileHeaderCol>
      <MainRow>
        <RightColumn>
          <Fade>
            <ContentRow>
              <TextColumn>
                <p className="sub-heading">{servicesCopy.mobile.subHeading}</p>
              </TextColumn>
            </ContentRow>
          </Fade>
          <Fade>
            <ContentRow>
              <TextColumn>
                <h3>{servicesCopy.mobile.sectionOneTitle}</h3>
              </TextColumn>
              <ImageColumn>
                <ResponsiveImage
                  onClickProp={() => {
                    togglePhotoViewerState(1);
                  }}
                  srcPath="/images/services/services_2"
                />
              </ImageColumn>
              <TextColumn>
                <p>{servicesCopy.mobile.sectionOneText}</p>
              </TextColumn>
            </ContentRow>
          </Fade>
          <Fade>
            <ContentRow>
              <TextColumn>
                <h3>{servicesCopy.mobile.sectionTwoTitle}</h3>
              </TextColumn>
              <ImageColumn>
                <ResponsiveImage
                  onClickProp={() => {
                    togglePhotoViewerState(2);
                  }}
                  srcPath="/images/services/services_3"
                />
              </ImageColumn>
              <TextColumn>
                <p>{servicesCopy.mobile.sectionTwoText}</p>
              </TextColumn>
            </ContentRow>
          </Fade>
          <Fade>
            <ContentRow>
              <TextColumn>
                <h3>{servicesCopy.mobile.sectionThreeTitle}</h3>
              </TextColumn>
              <ImageColumn>
                <ResponsiveImage
                  onClickProp={() => {
                    togglePhotoViewerState(3);
                  }}
                  srcPath="/images/services/services_4"
                />
              </ImageColumn>
              <TextColumn>
                <p>{servicesCopy.mobile.sectionThreeText}</p>
              </TextColumn>
            </ContentRow>
          </Fade>
          <Fade>
            <ContentRow>
              <TextColumn>
                <h3>{servicesCopy.mobile.sectionFourTitle}</h3>
              </TextColumn>
              <ImageColumn>
                <ResponsiveImage
                  onClickProp={() => {
                    togglePhotoViewerState(4);
                  }}
                  srcPath="/images/services/services_5"
                />
              </ImageColumn>
              <TextColumn>
                <p>{servicesCopy.mobile.sectionFourText}</p>
              </TextColumn>
            </ContentRow>
          </Fade>
          <Fade>
            <ContentRow>
              <TextColumn>
                <h3>{servicesCopy.mobile.sectionFiveTitle}</h3>
              </TextColumn>
              <ImageColumn>
                <ResponsiveImage
                  onClickProp={() => {
                    togglePhotoViewerState(5);
                  }}
                  srcPath="/images/services/services_6"
                />
              </ImageColumn>
              <TextColumn>
                <p>{servicesCopy.mobile.sectionFiveText}</p>
              </TextColumn>
            </ContentRow>
          </Fade>
          <Fade>
            <ContentRow>
              <TextColumn>
                <h3>{servicesCopy.mobile.sectionSixTitle}</h3>
              </TextColumn>
              <ImageColumn>
                <ResponsiveImage
                  onClickProp={() => {
                    togglePhotoViewerState(6);
                  }}
                  srcPath="/images/services/services_7"
                />
              </ImageColumn>
              <TextColumn>
                <p>{servicesCopy.mobile.sectionSixText}</p>
              </TextColumn>
            </ContentRow>
          </Fade>
          <Fade>
            <ContentRow>
              <TextColumn>
                <h3>{servicesCopy.mobile.sectionSevenTitle}</h3>
              </TextColumn>
              <ImageColumn>
                <ResponsiveImage
                  onClickProp={() => {
                    togglePhotoViewerState(7);
                  }}
                  srcPath="/images/services/services_8"
                />
              </ImageColumn>
              <TextColumn>
                <p>{servicesCopy.mobile.sectionSevenText}</p>
              </TextColumn>
            </ContentRow>
          </Fade>
          <Fade>
            <ContentRow>
              <TextColumn>
                <h3>{servicesCopy.mobile.sectionEightTitle}</h3>
              </TextColumn>
              <ImageColumn>
                <ResponsiveImage
                  onClickProp={() => {
                    togglePhotoViewerState(8);
                  }}
                  srcPath="/images/services/services_9"
                />
              </ImageColumn>
              <TextColumn>
                <p>{servicesCopy.mobile.sectionEightText}</p>
              </TextColumn>
            </ContentRow>
          </Fade>
        </RightColumn>
      </MainRow>
    </MobileCol>
  );
};

export default ServicesMobile;
