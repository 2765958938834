import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import { Helmet } from 'react-helmet';

import { mediaMin } from '../styles/MediaQueries';
import ResponsiveImage from '../components/utils/responsive-image';
import { HeaderLogo, MobilePageTitle } from '../components/utils/page-header';
import PageNavigation from '../components/utils/page-navigation';

const PenthousesContainer = styled.div`
  display: flex;
  flex-direction: column;
  // width: 100vw;
  min-height: 100vh;
  justify-content: center;
  position: relative;
`;

const FixedImage = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  transform: translateY(-${(props) => parseInt(props.scrollProgress, 10)}px);
  .responsive-image {
    min-height: 100%;
    max-width: unset;
  }
`;

const LandingText = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  opacity: ${(props) => (props.scrolled ? '0' : '1')};
  transition: opacity 1s ease-in-out;
  ${mediaMin.tabletLandscape`
  justify-content: space-between;
  `}
  h3 {
    opacity: ${(props) => (props.contentLoaded ? '1' : '0')};
    transition: opacity 0.5s ease-in-out;
    transition-delay: 0.5s;
    font-size: 40px;
    letter-spacing: 0.8px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    font-family: AWConquerorProDidotL-Regular;
    transform: translateY(-88px);
    ${mediaMin.tabletLandscape`
    letter-spacing: unset;
    font-size: 64px;
    line-height: 64px;
    `}
  }
  .scroll-icon {
    transform: ${(props) => (props.bounced ? 'translateY(-100px)' : 'translateY(-88px)')};
    opacity: ${(props) => (props.contentLoaded ? '1' : '0')};
    transition: opacity 0.5s ease-in-out 1s, transform 0.5s ease-in-out 0s;
  }
  h1 {
    margin-bottom: 0;
  }
`;

const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${(props) => (props.contentLoaded ? '1' : '0')};
  transition: opacity 0.5s ease-in-out;
`;

const ScrollImage = styled.div`
  .responsive-image {
    min-height: 100vh;
    min-width: 100%;
    width: 100%;
    margin-bottom: -2vh;
    object-fit: cover;
    box-shadow: 0px 12px 10px 10px #00000099;
  }
`;

const BottomSlide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000075;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  opacity: ${(props) => (props.mobileScrollComplete ? '1' : '0')};
  visibility: ${(props) => (props.mobileScrollComplete ? 'visible' : 'hidden')};
  ${mediaMin.tabletLandscape`
  opacity: ${(props) => (props.scrollComplete ? '1' : '0')};
  visibility: ${(props) => (props.scrollComplete ? 'visible' : 'hidden')};
  `}
  z-index: 99;
  h3 {
    opacity: ${(props) => (props.mobileScrollComplete ? '1' : '0')};
    transform: ${(props) => (props.mobileScrollComplete ? 'translateY(0)' : 'translateY(12px)')};
    ${mediaMin.tabletLandscape`
      opacity: ${(props) => (props.mobileScrollComplete ? '1' : '0')};
      transform: ${(props) => (props.mobileScrollComplete ? 'translateY(0)' : 'translateY(12px)')};
      `}
    transition: opacity 0.5s ease-in-out 0.5s, transform 1s ease-in-out 0.5s;
    color: #ffffff;
    font-family: 'Granjon';
    font-size: 20px;
    line-height: 28px;
    text-align: center;
  }
`;

const Fader = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #000;
  opacity: ${(props) => (props.active ? '.25' : '0')};
  transition: opacity 1s ease-in-out;
`;

const Penthouses = () => {
  const [scrollProgress, setScrollProgress] = useState(0);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [bounced, setBounced] = useState(false);

  const bounceArrow = () => {
    setBounced(true);
    setTimeout(() => {
      setBounced(false);
    }, 500);
  };

  const updateScrollProgress = () => {
    const { documentElement, scrollingElement } = document;
    const scrollPx = documentElement.scrollTop || scrollingElement.scrollTop;
    const winHeightPx = documentElement.scrollHeight - documentElement.clientHeight;
    const scrolled = (scrollPx / winHeightPx) * 100;
    setScrollProgress(scrolled);
  };

  useEffect(() => {
    window.addEventListener('scroll', updateScrollProgress);
    const interval = setInterval(bounceArrow, 4000);
    setContentLoaded(true);
    return () => {
      window.removeEventListener('scroll', updateScrollProgress);
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Manhattan Penthouse Condominiums | Madison House NoMad</title>
        <meta
          name="description"
          content="The three penthouses include soaring double-height ceilings, terraces that encompass the expanse of New York City and views from river to river."
        />
      </Helmet>
      <PenthousesContainer>
        <FixedImage scrollProgress={scrollProgress}>
          <Fader active={scrollProgress === 0} />
          <MediaQuery maxWidth={1023}>
            <ResponsiveImage
              srcPath="/images/penthouses/penthouses_1_mobile"
              imgAlt="Penthouse Exterior"
            />
          </MediaQuery>
          <MediaQuery minWidth={1024}>
            <ResponsiveImage
              srcPath="/images/penthouses/penthouses_1"
              imgAlt="Penthouse Exterior"
            />
          </MediaQuery>
        </FixedImage>
        <LandingText contentLoaded={contentLoaded} bounced={bounced} scrolled={scrollProgress > 0}>
          <TopContent contentLoaded={contentLoaded}>
            <HeaderLogo white />
            <MobilePageTitle white>PENTHOUSES</MobilePageTitle>
          </TopContent>
          <h3>
            HOME,
            <br />
            WHERE THE
            <br />
            SKYLINE
            <br />
            IS THE ONLY
            <br />
            LIMIT
          </h3>
          <img
            className="scroll-icon"
            src="/images/logos/nav-arrow-down.svg"
            alt="continue scrolling"
          />
        </LandingText>
        <ScrollImage>
          <MediaQuery maxWidth={1023}>
            <ResponsiveImage
              srcPath="/images/penthouses/penthouses_2_mobile"
              imgAlt="Penthouse Exterior"
            />
          </MediaQuery>
          <MediaQuery minWidth={1024}>
            <ResponsiveImage
              srcPath="/images/penthouses/penthouses_2"
              imgAlt="Penthouse Exterior"
            />
          </MediaQuery>
        </ScrollImage>
        <BottomSlide
          scrollComplete={scrollProgress === 100}
          mobileScrollComplete={scrollProgress > 80}
        >
          <h3>
            A marvel in every moment, the
            <br />
            three penthouses include soaring
            <br />
            double-height ceilings, terraces that
            <br />
            encompass the expanse of New York City,
            <br />
            and beyond, views from river to river.
            <br />
            <br />
            For more information on the Penthouse offering,
            <br />
            please contact the sales gallery.
          </h3>
        </BottomSlide>
        <PageNavigation
          nextPage="AMENITIES"
          nextPath="/nomad-new-condominium-amenities"
          prevPage="RESIDENCES & VIEWS"
          prevPath="/nomad-new-condominiums"
        />
      </PenthousesContainer>
    </>
  );
};

export default Penthouses;
