/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import variables from '../../styles/Variables';
import { mediaMin, mediaMax } from '../../styles/MediaQueries';
import Context from '../../config/Context';

const HeaderCol = styled.div`
  display: none;
  ${mediaMin.tabletLandscape`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: 0 20%;
  box-sizing: border-box;
  margin-bottom: 120px;
  white-space: pre-line;

  .header-logo-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`}
`;

const MobileHeaderCol = styled.div`
  width: 100%;
  padding: 0 36px;
  margin-bottom: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${variables.colors.lightGray};
  white-space: pre-line;
  .header-logo-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  h2 {
    font-family: AWConquerorProDidot-Regular;
    font-size: 48px;
    letter-spacing: 0.86px;
    line-height: 48px;
    color: ${variables.colors.darkRed};
    text-align: center;
  }
  p {
    font-family: 'Granjon';
    font-size: 20px;
    line-height: 28px;
  }
  ${mediaMin.tabletLandscape`
    display: none;
  `}
`;

const MobileImageColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${mediaMin.tablet`
    flex-direction: row-reverse;
    h2 {
      display: flex;
      align-items: center;
      width: 50%;
      padding-right: 16px;
    }
  `}
`;

const SubHeaderRow = styled.div`
  display: flex;
  flex-direction: column-reverse;
  ${mediaMin.tabletLandscape`
  height: 100%;
  flex-direction: row;
  `}
`;

const SubHeaderLeftCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  position: relative;
  ${mediaMin.desktopLarge`
    justify-content: space-between;
  `}
  h2 {
    display: flex;
    align-items: center;
    padding-right: 5%;
    margin: 0;
    height: ${(props) => props.arrowLocation}px;
    font-size: 8vh;
    line-height: 8vh;
    color: ${variables.colors.darkRed};
    text-align: center;
    transform: ${(props) => (props.contentLoaded ? 'translateY(0)' : 'translateY(24px)')};
    opacity: ${(props) => (props.contentLoaded ? '1' : '0')};
    transition: opacity 0.5s ease-in-out 0.5s, transform 1s ease-in-out 0.5s;
    font-family: AWConquerorProDidotL-Regular,serif;
    
    ${mediaMax.desktopMedium`
      font-size: 7vh;
      line-height: 7vh;
    `}    
    ${mediaMax.desktop`
      font-size: 6.4vh;
      line-height: 6.4vh;
    `}
    ${mediaMax.desktopSmall`
      font-size: 5vh;
      line-height: 5vh;
    `}
  }
  img {
    display: none;
    ${mediaMin.tabletLandscape`
    display: block;
    position: absolute;
    height: 32px;
    width: 32px;
    top: ${(props) => props.arrowLocation}px;
    margin: auto auto auto 0;
    transform: ${(props) => (props.contentLoaded ? 'translateY(0)' : 'translateY(-24px)')};
    opacity: ${(props) => (props.contentLoaded ? '1' : '0')};
    transition: opacity .5s ease-in-out, transform 1s ease-in-out;
    transition-delay: .5s;
    `}
    ${mediaMin.desktopLarge`
      margin: 0 auto 20vh 0;
    `}
  }
`;

const SubHeaderRightCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  img {
    transform: ${(props) => (props.contentLoaded ? 'translateY(0)' : 'translateY(24px)')};
    opacity: ${(props) => (props.contentLoaded ? '1' : '0')};
    transition: opacity 0.5s ease-in-out 0.5s, transform 1s ease-in-out 0.5s;
  }
  p {
    width: ${(props) => props.imageWidth}px;
    font-family: Granjon;
    font-size: 20px;
    line-height: 28px;
    color: ${variables.colors.darkGray};
    transform: ${(props) => (props.contentLoaded ? 'translateY(0)' : 'translateY(-24px)')};
    opacity: ${(props) => (props.contentLoaded ? '1' : '0')};
    transition: opacity 0.5s ease-in-out, transform 1s ease-in-out;
    transition-delay: 0.5s;
  }
`;

const HeroImage = styled.div`
  width: 100%;
  height: 70%;
  margin-bottom: 40px;
  overflow: hidden;
  .responsive-image {
    cursor: pointer;
  }
  ${mediaMin.tablet`
    width: 50%;
    padding-left: 16px;
  `}
  ${mediaMin.tabletLandscape`
    width: 100%;
    padding-left: 0;
  `}
`;

const GrayBackground = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  max-height: ${(props) => (props.contentLoaded ? `${props.backgroundHeight * 0.95 + 128}px` : '0')};
  transition: max-height 1.5s ease-in-out;

  width: 100%;
  background-color: ${variables.colors.lightGray};
`;

const HeaderLogoImg = styled.img`
  height: 41px;
  margin: 75px auto 16px auto;
  ${mediaMin.tabletLandscape`
    height: ${(props) => (props.white ? '80px' : '56px')};
    margin: 24px auto 48px auto;
  `}
`;

const HeaderLogo = ({ white }) => {
  const context = useContext(Context);
  const { closeOverlays } = context;
  return (
    <Link className="header-logo-link" to="/" onClick={closeOverlays}>
      <HeaderLogoImg
        white={white}
        src={`/images/logos/madison-house-${white ? 'white' : 'gray'}.svg`}
        alt="Madison House"
      />
    </Link>
  );
};

const MobilePageTitle = styled.h1`
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 16px;
  margin: 0 0 40px 0;
  color: ${(props) => (props.white ? '#FFF' : variables.colors.darkRed)};
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  ${mediaMin.tabletLandscape`
    display: none;
  `}
`;

export {
  HeaderCol,
  MobileHeaderCol,
  MobileImageColumn,
  SubHeaderRow,
  HeaderLogo,
  GrayBackground,
  SubHeaderLeftCol,
  SubHeaderRightCol,
  HeroImage,
  MobilePageTitle,
};
