import React from 'react';
import MediaQuery from 'react-responsive';
import { Helmet } from 'react-helmet';

import AmenitiesDesktop from '../components/amenities/amenities-desktop';
import AmenitiesMobile from '../components/amenities/amenities-mobile';
import PageNavigation from '../components/utils/page-navigation';
import PhotoViewer from '../components/photo-viewer';
import { amenitiesPhotos } from '../data/amenities';

const Amenities = () => (
  <>
    <Helmet>
      <title>NoMad New Condominium Amenities | Madison House</title>
      <meta
        name="description"
        content="Gachot brings its experience with international hotel design to Madison House's 30,000 square feet of club style amenities spaces."
      />
    </Helmet>
    <MediaQuery minWidth={1024}>
      <AmenitiesDesktop />
    </MediaQuery>
    <MediaQuery maxWidth={1023}>
      <AmenitiesMobile />
    </MediaQuery>
    <PageNavigation
      prevPath="/manhattan-penthouse-condominiums"
      prevPage="PENTHOUSES"
      nextPath="/nomad-new-condominium-availability"
      nextPage="AVAILABILITY"
    />
    <PhotoViewer photoArray={amenitiesPhotos} />
  </>
);

export default Amenities;
