/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Context from '../config/Context';

const defaultFilter = {
  beds: new Set([]),
  price: new Set([]),
  exposures: new Set([]),
  listings: new Set([]),
};

const parsePressData = (pressData) =>
  pressData.map((el) => {
    el.acf.id = el.id;
    return el.acf;
  });

const ContextProvider = (props) => {
  const { children } = props;

  const [navigationState, setNavigationState] = useState(false);
  const [photoViewerState, setPhotoViewerState] = useState(false);
  const [activePhoto, setActivePhoto] = useState(0);
  const [filterActive, setFilterActive] = useState(false);
  const [filters, setFilters] = useState(defaultFilter);
  const [shareDrawerActive, setShareDrawerActive] = useState(false);
  const [shareFormActive, setShareFormActive] = useState(false);
  const [sharedListings, setSharedListings] = useState(new Set([]));
  const [availabilityData, setAvailabilityData] = useState(null);
  const [pressData, setPressData] = useState(null);
  const [emailSubmitted, setEmailSubmitted] = useState(true);
  const [emailFormMessageVisable, setEmailFormMessageVisable] = useState(true);

  useEffect(() => {
    const htmlNode = document.getElementById('html');
    if (photoViewerState) {
      htmlNode.style.overflowY = 'hidden';
    } else {
      htmlNode.style.overflowY = 'auto';
    }
  }, [photoViewerState]);

  useEffect(() => {
    const htmlNode = document.getElementById('html');
    if (shareFormActive) {
      htmlNode.style.overflowY = 'hidden';
    } else {
      htmlNode.style.overflowY = 'auto';
    }
  }, [shareFormActive]);

  return (
    <Context.Provider
      value={{
        ...props,
        navigationState,
        photoViewerState,
        activePhoto,
        filterActive,
        shareFormActive,
        shareDrawerActive,
        sharedListings,
        filters,
        availabilityData,
        pressData,
        emailSubmitted,
        setEmailSubmitted,
        emailFormMessageVisable,
        setEmailFormMessageVisable,
        toggleNavigationState: () => {
          setNavigationState(!navigationState);
        },

        togglePhotoViewerState: (photoIdx) => {
          setActivePhoto(photoIdx);
          setPhotoViewerState(!photoViewerState);
        },

        toggleFilterActive: () => {
          setFilterActive(!filterActive);
        },

        toggleShareDrawerActive: () => {
          setShareDrawerActive(!shareDrawerActive);
        },

        toggleShareFormActive: () => {
          setShareFormActive(!shareFormActive);
        },

        closeOverlays: () => {
          setNavigationState(false);
          setPhotoViewerState(false);
          setFilterActive(false);
        },

        clearFilters: () => {
          setFilters({
            beds: new Set([]),
            price: new Set([]),
            exposures: new Set([]),
            listings: new Set([]),
          });
        },

        updateFilters: (key, value) => {
          const filterCopy = Object.assign(filters, {});
          if (filterCopy[key].has(value)) {
            filterCopy[key].delete(value);
          } else {
            filterCopy[key].add(value);
          }
          setFilters(filterCopy);
        },

        updateSharedListings: (listing) => {
          const sharedListingsCopy = new Set(sharedListings);
          if (sharedListingsCopy.has(listing)) {
            sharedListingsCopy.delete(listing);
          } else if (sharedListingsCopy.size < 4) {
            sharedListingsCopy.add(listing);
          }
          setSharedListings(sharedListingsCopy);
        },

        clearSharedListings: () => {
          setSharedListings(new Set());
          setShareDrawerActive(false);
        },

        fetchAvailabilityData: async () => {
          console.log('fetching availability data');
          const availabilityRes = await fetch(
            'https://forms-submissions.web.app/api/availability/madisonhouse'
          )
            // eslint-disable-next-line arrow-parens
            .then((res) => res.json())
            .catch((error) => {
              console.log('Error Fetching Availability Data - ', error);
              setAvailabilityData('ERROR');
            });
          const fetchedData = {};
          availabilityRes.data.inventory.forEach((listing) => {
            fetchedData[listing.id] = listing;
          });
          console.log(fetchedData);
          setAvailabilityData(fetchedData);
        },

        fetchPressData: async () => {
          console.log('fetching press data');
          let fetchedData = [];
          const contactsRes = await fetch(
            'https://cms.dbox.com/wp-json/wp/v2/mh_press?per_page=100'
          ).catch((error) => {
            console.log('Error Fetching Press Data - ', error);
            setPressData('ERROR');
          });
          const pages = contactsRes.headers.get('x-wp-totalpages');
          for (let i = 1; i <= pages; i += 1) {
            fetchedData.push(
              fetch(`https://cms.dbox.com/wp-json/wp/v2/mh_press?page=${i}`).then((res) =>
                res.json()
              )
            );
          }
          fetchedData = await Promise.all(fetchedData).then((result) => result.flat());
          fetchedData = fetchedData.sort(
            (a, b) => new Date(b.acf.publication_date) - new Date(a.acf.publication_date)
          );
          setPressData(parsePressData(fetchedData));
        },
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ContextProvider;
