const viewsData = {
  '16A': [
    { src: '/images/availability/views/DBOX_MH_view_16_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_16_W', alt: 'WEST' },
  ],
  '16B': [
    { src: '/images/availability/views/DBOX_MH_view_16_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_16_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_16_W', alt: 'WEST' },
  ],
  '16C': [
    { src: '/images/availability/views/DBOX_MH_view_16_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_16_N', alt: 'NORTH' },
  ],
  '16D': [
    { src: '/images/availability/views/DBOX_MH_view_16_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_16_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_16_W', alt: 'WEST' },
  ],
  '16E': [
    { src: '/images/availability/views/DBOX_MH_view_16_W', alt: 'WEST' },
    { src: '/images/availability/views/DBOX_MH_view_16_S', alt: 'SOUTH' },
  ],
  '17A': [
    { src: '/images/availability/views/DBOX_MH_view_16_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_16_W', alt: 'WEST' },
  ],
  '17B': [
    { src: '/images/availability/views/DBOX_MH_view_16_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_16_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_16_W', alt: 'WEST' },
  ],
  '17C': [
    { src: '/images/availability/views/DBOX_MH_view_16_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_16_N', alt: 'NORTH' },
  ],
  '17D': [
    { src: '/images/availability/views/DBOX_MH_view_16_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_16_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_16_W', alt: 'WEST' },
  ],
  '17E': [
    { src: '/images/availability/views/DBOX_MH_view_16_W', alt: 'WEST' },
    { src: '/images/availability/views/DBOX_MH_view_16_S', alt: 'SOUTH' },
  ],
  '18A': [
    { src: '/images/availability/views/DBOX_MH_view_16_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_16_W', alt: 'WEST' },
  ],
  '18B': [
    { src: '/images/availability/views/DBOX_MH_view_16_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_16_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_16_W', alt: 'WEST' },
  ],
  '18C': [
    { src: '/images/availability/views/DBOX_MH_view_16_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_16_N', alt: 'NORTH' },
  ],
  '18D': [
    { src: '/images/availability/views/DBOX_MH_view_16_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_16_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_16_W', alt: 'WEST' },
  ],
  '18E': [
    { src: '/images/availability/views/DBOX_MH_view_16_W', alt: 'WEST' },
    { src: '/images/availability/views/DBOX_MH_view_16_S', alt: 'SOUTH' },
  ],
  '19A': [
    { src: '/images/availability/views/DBOX_MH_view_19_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_19_W', alt: 'WEST' },
  ],
  '19B': [
    { src: '/images/availability/views/DBOX_MH_view_19_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_19_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_19_W', alt: 'WEST' },
  ],
  '19C': [
    { src: '/images/availability/views/DBOX_MH_view_19_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_19_N', alt: 'NORTH' },
  ],
  '19D': [
    { src: '/images/availability/views/DBOX_MH_view_19_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_19_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_19_W', alt: 'WEST' },
  ],
  '19E': [
    { src: '/images/availability/views/DBOX_MH_view_19_W', alt: 'WEST' },
    { src: '/images/availability/views/DBOX_MH_view_19_S', alt: 'SOUTH' },
  ],
  '20A': [
    { src: '/images/availability/views/DBOX_MH_view_19_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_19_W', alt: 'WEST' },
  ],
  '20B': [
    { src: '/images/availability/views/DBOX_MH_view_19_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_19_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_19_W', alt: 'WEST' },
  ],
  '20C': [
    { src: '/images/availability/views/DBOX_MH_view_19_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_19_N', alt: 'NORTH' },
  ],
  '20D': [
    { src: '/images/availability/views/DBOX_MH_view_19_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_19_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_19_W', alt: 'WEST' },
  ],
  '20E': [
    { src: '/images/availability/views/DBOX_MH_view_19_W', alt: 'WEST' },
    { src: '/images/availability/views/DBOX_MH_view_19_S', alt: 'SOUTH' },
  ],
  '21A': [
    { src: '/images/availability/views/DBOX_MH_view_19_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_19_W', alt: 'WEST' },
  ],
  '21B': [
    { src: '/images/availability/views/DBOX_MH_view_19_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_19_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_19_W', alt: 'WEST' },
  ],
  '21C': [
    { src: '/images/availability/views/DBOX_MH_view_19_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_19_N', alt: 'NORTH' },
  ],
  '21D': [
    { src: '/images/availability/views/DBOX_MH_view_19_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_19_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_19_W', alt: 'WEST' },
  ],
  '21E': [
    { src: '/images/availability/views/DBOX_MH_view_19_W', alt: 'WEST' },
    { src: '/images/availability/views/DBOX_MH_view_19_S', alt: 'SOUTH' },
  ],
  '22A': [
    { src: '/images/availability/views/DBOX_MH_view_22_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_22_W', alt: 'WEST' },
  ],
  '22B': [
    { src: '/images/availability/views/DBOX_MH_view_22_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_22_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_22_W', alt: 'WEST' },
  ],
  '22C': [
    { src: '/images/availability/views/DBOX_MH_view_22_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_22_N', alt: 'NORTH' },
  ],
  '22D': [
    { src: '/images/availability/views/DBOX_MH_view_22_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_22_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_22_W', alt: 'WEST' },
  ],
  '22E': [
    { src: '/images/availability/views/DBOX_MH_view_22_W', alt: 'WEST' },
    { src: '/images/availability/views/DBOX_MH_view_22_S', alt: 'SOUTH' },
  ],
  '23A': [
    { src: '/images/availability/views/DBOX_MH_view_22_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_22_W', alt: 'WEST' },
  ],
  '23B': [
    { src: '/images/availability/views/DBOX_MH_view_22_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_22_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_22_W', alt: 'WEST' },
  ],
  '23C': [
    { src: '/images/availability/views/DBOX_MH_view_22_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_22_N', alt: 'NORTH' },
  ],
  '23D': [
    { src: '/images/availability/views/DBOX_MH_view_22_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_22_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_22_W', alt: 'WEST' },
  ],
  '23E': [
    { src: '/images/availability/views/DBOX_MH_view_22_W', alt: 'WEST' },
    { src: '/images/availability/views/DBOX_MH_view_22_S', alt: 'SOUTH' },
  ],
  '24A': [
    { src: '/images/availability/views/DBOX_MH_view_24_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_24_W', alt: 'WEST' },
  ],
  '24B': [
    { src: '/images/availability/views/DBOX_MH_view_24_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_24_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_24_W', alt: 'WEST' },
  ],
  '24C': [
    { src: '/images/availability/views/DBOX_MH_view_24_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_24_N', alt: 'NORTH' },
  ],
  '24D': [
    { src: '/images/availability/views/DBOX_MH_view_24_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_24_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_24_W', alt: 'WEST' },
  ],
  '24E': [
    { src: '/images/availability/views/DBOX_MH_view_24_W', alt: 'WEST' },
    { src: '/images/availability/views/DBOX_MH_view_24_S', alt: 'SOUTH' },
  ],
  '25A': [
    { src: '/images/availability/views/DBOX_MH_view_24_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_24_W', alt: 'WEST' },
  ],
  '25B': [
    { src: '/images/availability/views/DBOX_MH_view_24_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_24_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_24_W', alt: 'WEST' },
  ],
  '25C': [
    { src: '/images/availability/views/DBOX_MH_view_24_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_24_N', alt: 'NORTH' },
  ],
  '25D': [
    { src: '/images/availability/views/DBOX_MH_view_24_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_24_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_24_W', alt: 'WEST' },
  ],
  '25E': [
    { src: '/images/availability/views/DBOX_MH_view_24_W', alt: 'WEST' },
    { src: '/images/availability/views/DBOX_MH_view_24_S', alt: 'SOUTH' },
  ],
  '26A': [
    { src: '/images/availability/views/DBOX_MH_view_24_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_24_W', alt: 'WEST' },
  ],
  '26B': [
    { src: '/images/availability/views/DBOX_MH_view_24_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_24_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_24_W', alt: 'WEST' },
  ],
  '26C': [
    { src: '/images/availability/views/DBOX_MH_view_24_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_24_N', alt: 'NORTH' },
  ],
  '26D': [
    { src: '/images/availability/views/DBOX_MH_view_24_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_24_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_24_W', alt: 'WEST' },
  ],
  '26E': [
    { src: '/images/availability/views/DBOX_MH_view_24_W', alt: 'WEST' },
    { src: '/images/availability/views/DBOX_MH_view_24_S', alt: 'SOUTH' },
  ],
  '27A': [
    { src: '/images/availability/views/DBOX_MH_view_27_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_27_W', alt: 'WEST' },
  ],
  '27B': [
    { src: '/images/availability/views/DBOX_MH_view_27_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_27_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_27_W', alt: 'WEST' },
  ],
  '27C': [
    { src: '/images/availability/views/DBOX_MH_view_27_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_27_N', alt: 'NORTH' },
  ],
  '27D': [
    { src: '/images/availability/views/DBOX_MH_view_27_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_27_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_27_W', alt: 'WEST' },
  ],
  '27E': [
    { src: '/images/availability/views/DBOX_MH_view_27_W', alt: 'WEST' },
    { src: '/images/availability/views/DBOX_MH_view_27_S', alt: 'SOUTH' },
  ],
  '28A': [
    { src: '/images/availability/views/DBOX_MH_view_27_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_27_W', alt: 'WEST' },
  ],
  '28B': [
    { src: '/images/availability/views/DBOX_MH_view_27_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_27_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_27_W', alt: 'WEST' },
  ],
  '28C': [
    { src: '/images/availability/views/DBOX_MH_view_27_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_27_N', alt: 'NORTH' },
  ],
  '28D': [
    { src: '/images/availability/views/DBOX_MH_view_27_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_27_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_27_W', alt: 'WEST' },
  ],
  '28E': [
    { src: '/images/availability/views/DBOX_MH_view_27_W', alt: 'WEST' },
    { src: '/images/availability/views/DBOX_MH_view_27_S', alt: 'SOUTH' },
  ],
  '29A': [
    { src: '/images/availability/views/DBOX_MH_view_27_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_27_W', alt: 'WEST' },
  ],
  '29B': [
    { src: '/images/availability/views/DBOX_MH_view_27_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_27_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_27_W', alt: 'WEST' },
  ],
  '29C': [
    { src: '/images/availability/views/DBOX_MH_view_27_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_27_N', alt: 'NORTH' },
  ],
  '29D': [
    { src: '/images/availability/views/DBOX_MH_view_27_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_27_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_27_W', alt: 'WEST' },
  ],
  '29E': [
    { src: '/images/availability/views/DBOX_MH_view_27_W', alt: 'WEST' },
    { src: '/images/availability/views/DBOX_MH_view_27_S', alt: 'SOUTH' },
  ],
  '30A': [
    { src: '/images/availability/views/DBOX_MH_view_30_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_30_W', alt: 'WEST' },
  ],
  '30B': [
    { src: '/images/availability/views/DBOX_MH_view_30_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_30_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_30_W', alt: 'WEST' },
  ],
  '30C': [
    { src: '/images/availability/views/DBOX_MH_view_30_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_30_N', alt: 'NORTH' },
  ],
  '30D': [
    { src: '/images/availability/views/DBOX_MH_view_30_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_30_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_30_W', alt: 'WEST' },
  ],
  '30E': [
    { src: '/images/availability/views/DBOX_MH_view_30_W', alt: 'WEST' },
    { src: '/images/availability/views/DBOX_MH_view_30_S', alt: 'SOUTH' },
  ],
  '31A': [
    { src: '/images/availability/views/DBOX_MH_view_30_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_30_W', alt: 'WEST' },
  ],
  '31B': [
    { src: '/images/availability/views/DBOX_MH_view_30_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_30_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_30_W', alt: 'WEST' },
  ],
  '31C': [
    { src: '/images/availability/views/DBOX_MH_view_30_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_30_N', alt: 'NORTH' },
  ],
  '31D': [
    { src: '/images/availability/views/DBOX_MH_view_30_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_30_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_30_W', alt: 'WEST' },
  ],
  '31E': [
    { src: '/images/availability/views/DBOX_MH_view_30_W', alt: 'WEST' },
    { src: '/images/availability/views/DBOX_MH_view_30_S', alt: 'SOUTH' },
  ],
  '32A': [
    { src: '/images/availability/views/DBOX_MH_view_32_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_32_W', alt: 'WEST' },
  ],
  '32B': [
    { src: '/images/availability/views/DBOX_MH_view_32_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_32_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_32_W', alt: 'WEST' },
  ],
  '32C': [
    { src: '/images/availability/views/DBOX_MH_view_32_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_32_E', alt: 'EAST' },
  ],
  '32D': [
    { src: '/images/availability/views/DBOX_MH_view_32_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_32_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_32_W', alt: 'WEST' },
  ],
  '32E': [
    { src: '/images/availability/views/DBOX_MH_view_32_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_32_W', alt: 'WEST' },
  ],
  '33A': [
    { src: '/images/availability/views/DBOX_MH_view_32_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_32_W', alt: 'WEST' },
  ],
  '33B': [
    { src: '/images/availability/views/DBOX_MH_view_32_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_32_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_32_W', alt: 'WEST' },
  ],
  '33C': [
    { src: '/images/availability/views/DBOX_MH_view_32_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_32_E', alt: 'EAST' },
  ],
  '33D': [
    { src: '/images/availability/views/DBOX_MH_view_32_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_32_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_32_W', alt: 'WEST' },
  ],
  '33E': [
    { src: '/images/availability/views/DBOX_MH_view_32_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_32_W', alt: 'WEST' },
  ],
  '34A': [
    { src: '/images/availability/views/DBOX_MH_view_32_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_32_W', alt: 'WEST' },
  ],
  '34B': [
    { src: '/images/availability/views/DBOX_MH_view_32_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_32_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_32_W', alt: 'WEST' },
  ],
  '34C': [
    { src: '/images/availability/views/DBOX_MH_view_32_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_32_E', alt: 'EAST' },
  ],
  '34D': [
    { src: '/images/availability/views/DBOX_MH_view_32_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_32_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_32_W', alt: 'WEST' },
  ],
  '34E': [
    { src: '/images/availability/views/DBOX_MH_view_32_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_32_W', alt: 'WEST' },
  ],
  '35A': [
    { src: '/images/availability/views/DBOX_MH_view_35_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_35_W', alt: 'WEST' },
  ],
  '35B': [
    { src: '/images/availability/views/DBOX_MH_view_35_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_35_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_35_W', alt: 'WEST' },
  ],
  '35C': [
    { src: '/images/availability/views/DBOX_MH_view_35_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_35_E', alt: 'EAST' },
  ],
  '35D': [
    { src: '/images/availability/views/DBOX_MH_view_35_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_35_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_35_W', alt: 'WEST' },
  ],
  '35E': [
    { src: '/images/availability/views/DBOX_MH_view_35_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_35_W', alt: 'WEST' },
  ],
  '36A': [
    { src: '/images/availability/views/DBOX_MH_view_35_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_35_W', alt: 'WEST' },
  ],
  '36B': [
    { src: '/images/availability/views/DBOX_MH_view_35_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_35_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_35_W', alt: 'WEST' },
  ],
  '36C': [
    { src: '/images/availability/views/DBOX_MH_view_35_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_35_E', alt: 'EAST' },
  ],
  '36D': [
    { src: '/images/availability/views/DBOX_MH_view_35_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_35_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_35_W', alt: 'WEST' },
  ],
  '36E': [
    { src: '/images/availability/views/DBOX_MH_view_35_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_35_W', alt: 'WEST' },
  ],
  '37A': [
    { src: '/images/availability/views/DBOX_MH_view_35_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_35_W', alt: 'WEST' },
  ],
  '37B': [
    { src: '/images/availability/views/DBOX_MH_view_35_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_35_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_35_W', alt: 'WEST' },
  ],
  '37C': [
    { src: '/images/availability/views/DBOX_MH_view_35_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_35_E', alt: 'EAST' },
  ],
  '37D': [
    { src: '/images/availability/views/DBOX_MH_view_35_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_35_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_35_W', alt: 'WEST' },
  ],
  '37E': [
    { src: '/images/availability/views/DBOX_MH_view_35_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_35_W', alt: 'WEST' },
  ],
  '38A': [
    { src: '/images/availability/views/DBOX_MH_view_38_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_38_W', alt: 'WEST' },
  ],
  '38B': [
    { src: '/images/availability/views/DBOX_MH_view_38_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_38_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_38_W', alt: 'WEST' },
  ],
  '38C': [
    { src: '/images/availability/views/DBOX_MH_view_38_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_38_E', alt: 'EAST' },
  ],
  '38D': [
    { src: '/images/availability/views/DBOX_MH_view_38_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_38_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_38_W', alt: 'WEST' },
  ],
  '38E': [
    { src: '/images/availability/views/DBOX_MH_view_38_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_38_W', alt: 'WEST' },
  ],
  '39A': [
    { src: '/images/availability/views/DBOX_MH_view_38_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_38_W', alt: 'WEST' },
  ],
  '39B': [
    { src: '/images/availability/views/DBOX_MH_view_38_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_38_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_38_W', alt: 'WEST' },
  ],
  '39C': [
    { src: '/images/availability/views/DBOX_MH_view_38_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_38_E', alt: 'EAST' },
  ],
  '39D': [
    { src: '/images/availability/views/DBOX_MH_view_38_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_38_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_38_W', alt: 'WEST' },
  ],
  '39E': [
    { src: '/images/availability/views/DBOX_MH_view_38_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_38_W', alt: 'WEST' },
  ],
  '40A': [
    { src: '/images/availability/views/DBOX_MH_view_38_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_38_W', alt: 'WEST' },
  ],
  '40B': [
    { src: '/images/availability/views/DBOX_MH_view_38_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_38_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_38_W', alt: 'WEST' },
  ],
  '40C': [
    { src: '/images/availability/views/DBOX_MH_view_38_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_38_E', alt: 'EAST' },
  ],
  '40D': [
    { src: '/images/availability/views/DBOX_MH_view_38_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_38_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_38_W', alt: 'WEST' },
  ],
  '40E': [
    { src: '/images/availability/views/DBOX_MH_view_38_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_38_W', alt: 'WEST' },
  ],
  '41A': [
    { src: '/images/availability/views/DBOX_MH_view_41_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_41_W', alt: 'WEST' },
  ],
  '41B': [
    { src: '/images/availability/views/DBOX_MH_view_41_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_41_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_41_W', alt: 'WEST' },
  ],
  '41C': [
    { src: '/images/availability/views/DBOX_MH_view_41_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_41_E', alt: 'EAST' },
  ],
  '41D': [
    { src: '/images/availability/views/DBOX_MH_view_41_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_41_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_41_W', alt: 'WEST' },
  ],
  '41E': [
    { src: '/images/availability/views/DBOX_MH_view_41_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_41_W', alt: 'WEST' },
  ],
  '42A': [
    { src: '/images/availability/views/DBOX_MH_view_41_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_41_W', alt: 'WEST' },
  ],
  '42B': [
    { src: '/images/availability/views/DBOX_MH_view_41_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_41_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_41_W', alt: 'WEST' },
  ],
  '42C': [
    { src: '/images/availability/views/DBOX_MH_view_41_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_41_E', alt: 'EAST' },
  ],
  '42D': [
    { src: '/images/availability/views/DBOX_MH_view_41_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_41_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_41_W', alt: 'WEST' },
  ],
  '42E': [
    { src: '/images/availability/views/DBOX_MH_view_41_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_41_W', alt: 'WEST' },
  ],
  '43A': [
    { src: '/images/availability/views/DBOX_MH_view_41_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_41_W', alt: 'WEST' },
  ],
  '43B': [
    { src: '/images/availability/views/DBOX_MH_view_41_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_41_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_41_W', alt: 'WEST' },
  ],
  '43C': [
    { src: '/images/availability/views/DBOX_MH_view_41_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_41_E', alt: 'EAST' },
  ],
  '43D': [
    { src: '/images/availability/views/DBOX_MH_view_41_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_41_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_41_W', alt: 'WEST' },
  ],
  '43E': [
    { src: '/images/availability/views/DBOX_MH_view_41_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_41_W', alt: 'WEST' },
  ],
  '44A': [
    { src: '/images/availability/views/DBOX_MH_view_44_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_44_W', alt: 'WEST' },
  ],
  '44B': [
    { src: '/images/availability/views/DBOX_MH_view_44_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_44_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_44_W', alt: 'WEST' },
  ],
  '44C': [
    { src: '/images/availability/views/DBOX_MH_view_44_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_44_E', alt: 'EAST' },
  ],
  '44D': [
    { src: '/images/availability/views/DBOX_MH_view_44_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_44_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_44_W', alt: 'WEST' },
  ],
  '44E': [
    { src: '/images/availability/views/DBOX_MH_view_44_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_44_W', alt: 'WEST' },
  ],
  '45A': [
    { src: '/images/availability/views/DBOX_MH_view_44_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_44_W', alt: 'WEST' },
  ],
  '45B': [
    { src: '/images/availability/views/DBOX_MH_view_44_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_44_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_44_W', alt: 'WEST' },
  ],
  '45C': [
    { src: '/images/availability/views/DBOX_MH_view_44_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_44_E', alt: 'EAST' },
  ],
  '45D': [
    { src: '/images/availability/views/DBOX_MH_view_44_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_44_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_44_W', alt: 'WEST' },
  ],
  '45E': [
    { src: '/images/availability/views/DBOX_MH_view_44_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_44_W', alt: 'WEST' },
  ],
  '46A': [
    { src: '/images/availability/views/DBOX_MH_view_44_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_44_W', alt: 'WEST' },
  ],
  '46B': [
    { src: '/images/availability/views/DBOX_MH_view_44_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_44_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_44_W', alt: 'WEST' },
  ],
  '46C': [
    { src: '/images/availability/views/DBOX_MH_view_44_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_44_E', alt: 'EAST' },
  ],
  '46D': [
    { src: '/images/availability/views/DBOX_MH_view_44_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_44_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_44_W', alt: 'WEST' },
  ],
  '46E': [
    { src: '/images/availability/views/DBOX_MH_view_44_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_44_W', alt: 'WEST' },
  ],
  '47A': [
    { src: '/images/availability/views/DBOX_MH_view_47_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_47_W', alt: 'WEST' },
  ],
  '47B': [
    { src: '/images/availability/views/DBOX_MH_view_47_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_47_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_47_W', alt: 'WEST' },
  ],
  '47C': [
    { src: '/images/availability/views/DBOX_MH_view_47_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_47_S', alt: 'SOUTH' },
  ],
  '47D': [
    { src: '/images/availability/views/DBOX_MH_view_47_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_47_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_47_W', alt: 'WEST' },
  ],
  '48A': [
    { src: '/images/availability/views/DBOX_MH_view_47_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_47_W', alt: 'WEST' },
  ],
  '48B': [
    { src: '/images/availability/views/DBOX_MH_view_47_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_47_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_47_W', alt: 'WEST' },
  ],
  '48C': [
    { src: '/images/availability/views/DBOX_MH_view_47_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_47_S', alt: 'SOUTH' },
  ],
  '48D': [
    { src: '/images/availability/views/DBOX_MH_view_47_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_47_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_47_W', alt: 'WEST' },
  ],
  '49A': [
    { src: '/images/availability/views/DBOX_MH_view_47_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_47_W', alt: 'WEST' },
  ],
  '49B': [
    { src: '/images/availability/views/DBOX_MH_view_47_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_47_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_47_W', alt: 'WEST' },
  ],
  '49C': [
    { src: '/images/availability/views/DBOX_MH_view_47_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_47_S', alt: 'SOUTH' },
  ],
  '49D': [
    { src: '/images/availability/views/DBOX_MH_view_47_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_47_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_47_W', alt: 'WEST' },
  ],
  '50A': [
    { src: '/images/availability/views/DBOX_MH_view_50_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_50_W', alt: 'WEST' },
  ],
  '50B': [
    { src: '/images/availability/views/DBOX_MH_view_50_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_50_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_50_W', alt: 'WEST' },
  ],
  '50C': [
    { src: '/images/availability/views/DBOX_MH_view_50_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_50_S', alt: 'SOUTH' },
  ],
  '50D': [
    { src: '/images/availability/views/DBOX_MH_view_50_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_50_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_50_W', alt: 'WEST' },
  ],
  '51A': [
    { src: '/images/availability/views/DBOX_MH_view_50_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_50_W', alt: 'WEST' },
  ],
  '51B': [
    { src: '/images/availability/views/DBOX_MH_view_50_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_50_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_50_W', alt: 'WEST' },
  ],
  '51C': [
    { src: '/images/availability/views/DBOX_MH_view_50_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_50_S', alt: 'SOUTH' },
  ],
  '51D': [
    { src: '/images/availability/views/DBOX_MH_view_50_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_50_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_50_W', alt: 'WEST' },
  ],
  '52A': [
    { src: '/images/availability/views/DBOX_MH_view_50_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_50_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_50_W', alt: 'WEST' },
  ],
  '52B': [
    { src: '/images/availability/views/DBOX_MH_view_50_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_50_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_50_W', alt: 'WEST' },
  ],
  '52C': [
    { src: '/images/availability/views/DBOX_MH_view_50_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_50_W', alt: 'WEST' },
  ],
  '53A': [
    { src: '/images/availability/views/DBOX_MH_view_53_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_53_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_53_W', alt: 'WEST' },
  ],
  '53B': [
    { src: '/images/availability/views/DBOX_MH_view_53_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_53_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_53_W', alt: 'WEST' },
  ],
  '53C': [
    { src: '/images/availability/views/DBOX_MH_view_53_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_53_W', alt: 'WEST' },
  ],
  '54A': [
    { src: '/images/availability/views/DBOX_MH_view_53_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_53_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_53_W', alt: 'WEST' },
  ],
  '54B': [
    { src: '/images/availability/views/DBOX_MH_view_53_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_53_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_53_W', alt: 'WEST' },
  ],
  '54C': [
    { src: '/images/availability/views/DBOX_MH_view_53_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_53_W', alt: 'WEST' },
  ],
  '55A': [
    { src: '/images/availability/views/DBOX_MH_view_53_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_53_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_53_W', alt: 'WEST' },
  ],
  '55B': [
    { src: '/images/availability/views/DBOX_MH_view_53_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_53_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_53_W', alt: 'WEST' },
  ],
  '55C': [
    { src: '/images/availability/views/DBOX_MH_view_53_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_53_W', alt: 'WEST' },
  ],
  '56A': [
    { src: '/images/availability/views/DBOX_MH_view_56_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_56_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_56_W', alt: 'WEST' },
  ],
  '56B': [
    { src: '/images/availability/views/DBOX_MH_view_56_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_56_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_56_W', alt: 'WEST' },
  ],
  '56C': [
    { src: '/images/availability/views/DBOX_MH_view_56_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_56_W', alt: 'WEST' },
  ],
  '57A': [
    { src: '/images/availability/views/DBOX_MH_view_56_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_56_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_56_W', alt: 'WEST' },
  ],
  '57B': [
    { src: '/images/availability/views/DBOX_MH_view_56_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_56_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_56_W', alt: 'WEST' },
  ],
  '58A': [
    { src: '/images/availability/views/DBOX_MH_view_56_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_56_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_56_W', alt: 'WEST' },
  ],
  '58B': [
    { src: '/images/availability/views/DBOX_MH_view_56_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_56_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_56_W', alt: 'WEST' },
  ],
  '59A': [
    { src: '/images/availability/views/DBOX_MH_view_59_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_59_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_59_W', alt: 'WEST' },
  ],
  '59B': [
    { src: '/images/availability/views/DBOX_MH_view_59_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_59_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_59_W', alt: 'WEST' },
  ],
  PH60A: [
    { src: '/images/availability/views/DBOX_MH_view_PH_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_PH_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_PH_W', alt: 'WEST' },
  ],
  PH60B: [
    { src: '/images/availability/views/DBOX_MH_view_PH_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_PH_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_PH_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_PH_W', alt: 'WEST' },
  ],
  PH62: [
    { src: '/images/availability/views/DBOX_MH_view_PH_N', alt: 'NORTH' },
    { src: '/images/availability/views/DBOX_MH_view_PH_E', alt: 'EAST' },
    { src: '/images/availability/views/DBOX_MH_view_PH_S', alt: 'SOUTH' },
    { src: '/images/availability/views/DBOX_MH_view_PH_W', alt: 'WEST' },
  ],
};

export default viewsData;
