import React, { useContext } from 'react';
import Fade from 'react-reveal/Fade';

import Context from '../../config/Context';
import {
  MobileCol,
  MainRow,
  RightColumn,
  ContentRow,
  TextColumn,
  ImageColumn,
} from '../utils/desktop-columns';
import {
  MobileHeaderCol,
  HeaderLogo,
  GrayBackground,
  HeroImage,
  MobilePageTitle,
  MobileImageColumn,
} from '../utils/page-header';
import ResponsiveImage from '../utils/responsive-image';
import { architectureCopy } from '../../data/architecture';

const ArchitectureMobile = () => {
  const context = useContext(Context);
  const { togglePhotoViewerState } = context;
  return (
    <MobileCol>
      <MobileHeaderCol>
        <HeaderLogo />
        <MobilePageTitle>ARCHITECTURE</MobilePageTitle>
        <MobileImageColumn>
          <HeroImage>
            <ResponsiveImage
              onClickProp={() => {
                togglePhotoViewerState(0);
              }}
              srcPath="/images/architecture/architecture_1"
            />
          </HeroImage>
          <h2>{architectureCopy.mobile.heading}</h2>
        </MobileImageColumn>
        <GrayBackground />
      </MobileHeaderCol>
      <MainRow>
        <RightColumn>
          <Fade>
            <ContentRow>
              <TextColumn>
                <p className="sub-heading">{architectureCopy.mobile.subHeading}</p>
              </TextColumn>
            </ContentRow>
          </Fade>

          <Fade>
            <ContentRow>
              <TextColumn>
                <p>{architectureCopy.mobile.sectionOneText}</p>
              </TextColumn>
              <ImageColumn>
                <ResponsiveImage
                  onClickProp={() => {
                    togglePhotoViewerState(1);
                  }}
                  srcPath="/images/architecture/architecture_2"
                />
              </ImageColumn>
            </ContentRow>
          </Fade>

          <Fade>
            <ContentRow>
              <TextColumn>
                <h3>{architectureCopy.mobile.sectionTwoTitle}</h3>
              </TextColumn>
              <ImageColumn>
                <ResponsiveImage
                  onClickProp={() => {
                    togglePhotoViewerState(2);
                  }}
                  srcPath="/images/architecture/architecture_3"
                />
              </ImageColumn>
              <TextColumn>
                <p>{architectureCopy.mobile.sectionTwoText}</p>
              </TextColumn>
            </ContentRow>
          </Fade>

          <Fade>
            <ContentRow>
              <ImageColumn portrait>
                <ResponsiveImage
                  onClickProp={() => {
                    togglePhotoViewerState(3);
                  }}
                  srcPath="/images/architecture/architecture_4"
                />
              </ImageColumn>
            </ContentRow>
          </Fade>
        </RightColumn>
      </MainRow>
    </MobileCol>
  );
};

export default ArchitectureMobile;
